import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedSensor} from "../../../actions/sensor";
import FeatherIcon from "feather-icons-react";
import {TOGGLE} from "../../../utils/Enum";
import {
  batteryReading,
  formatDisplayValue,
  getBatteryIcon,
  getIcon,
  getWifiIcon,
  isDeviceOnline
} from "../../../utils/Utils";
import DeviceBatteryReport from "../plot/sensor-item-battery-report";
import SensorInput from "./sensor-input";
import * as _ from "underscore";
import axios from "axios";
import {getDisplayName, isEmpty, toBrowserTimeZone} from "../../../shared/utils/utils";

const SensorItem = (props) => {
  const [batteryLevel, setBatteryLevel] = useState(0);
  const dispatch = useDispatch();
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isAddSensorData, setIsAddSensorData] = useState(false);
  const [device, setDevice] = useState(null);
  const [filteredSensors, setFilteredSensors] = useState([]);
  const [powerSupply, setPowerSupply] = useState([0]);
  const [signalStrength, setSignalStrength] = useState([0]);
  const [timeString, setTimeString] = useState("");

  const selectedSensor = useSelector(state => {
    return state.sensor.selectedSensor;
  });
  const selectedKit = useSelector(state => {
    return state.plotList.selectedKit;
  });

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  const isDeviceOnlineValue = isDeviceOnline(toBrowserTimeZone(timeString), props.kit.interval);

  function isOnline(time) {
    if (isDeviceOnline(time, props.kit.interval)) {
      return (<div className="heading">
        <div className="status-circle-green"/>
        Online
      </div>);
    } else {
      return (<div className="heading">
        <div className="status-circle-black"/>
        Offline
      </div>);
    }

  }

  function showGraphReport() {
    setSelectedDevice(true);
  }

  function showSensorDataPopup() {
    setIsAddSensorData(true);
  }

  function hasAlert(sensor) {
    return sensor.alert && !isEmpty(selectedKit.alerts);
    // return true;
  }

  function sensorClick(index) {
    if (toggle === TOGGLE.MAP) {
      dispatch(getSelectedSensor(index));
    }
  }

  useEffect(() => {
    if (!device || !device.sensors) {
      return;
    }

    let bSensor = device.sensors.find(e => e.code === "B");
    let psSensor = device.sensors.find(e => e.code === "PS");
    let ssSensor = device.sensors.find(e => e.code === "SS");
    if (bSensor && device.battery) {
      setBatteryLevel(batteryReading(+bSensor.value, device.battery.low, device.battery.high));
    }
    if (psSensor) {
      setPowerSupply(+psSensor.value)
    }
    if (ssSensor) {
      setSignalStrength(+ssSensor.value)
    }
  }, [device]);

  useEffect(() => {
    if (!props.cluster && props.kit) {

      if (props.kit.properties.length !== 0) {
        setTimeString(props.kit.properties[0].timeString)
        let properties = _.filter(props.kit.properties, function (item) {
          return item.code !== "WI" && item.code !== "IT" && item.code !== "SS";
        });
        setFilteredSensors(properties);
        return
      }

      setTimeString(props.kit.timeString)
      let codes = _.filter(props.kit.model.properties, function (item) {
        return item !== "WI" && item !== "IT" && item !== "SS";
      });

      axios.get(process.env.REACT_APP_HOST + `/core/meta-data`)
      .then(function (response) {
        let sensorNameList = response.data.content.sensors
        let sensors = codes.map((code, index) => {
          let displayNameObj = sensorNameList.find(item => item.code === code);
          return {
            code: code,
            number: index,
            displayName: displayNameObj ? displayNameObj.name : 'NA',
            unit:  displayNameObj?.unit ? displayNameObj.unit : null,
          };
        });
        setFilteredSensors(sensors);
      })

      return;
    }

    if (!props.cluster && !props.kit) {
      return;
    }

    axios.get(process.env.REACT_APP_HOST + `/core/device/` + props.cluster[0].deviceId)
      .then(res => {
        setDevice(res.data.content);
      });

    let sensors = _.filter(props.cluster, function (item) {
      return item.code !== "WI" && item.code !== "IT" && item.code !== "SS";
    });
    setFilteredSensors(sensors);
    setTimeString(props.cluster[0].timeString)
  }, [props.cluster, props.kit]);

  return (
    <div className={window.innerWidth > 620 && props.sensorInputEnabled ? "sensor-add-width" : window.innerWidth > 620 ? "sensor-width" : "mo-plot-list-card"}
         onClick={() => sensorClick(props.index)}>
      <div className="single-card">
        <div
          className={"sensor-kit-main single-card-header " + (props.index === selectedSensor ? 'card-top-border' : '')}>
          <div className="row sa-table-flex">
            <div className="card-header-text-2">
              <div
                className="heading  limit-chara-actuator-sensor">{props.cluster && props.cluster[0].clusterName ? props.cluster[0].clusterName : 'Sensor ' + (props.index + 1)}</div>
            </div>
            { (props.kit?.properties?.length !== 0) && <div className={`text-center ${props.sensorInputEnabled ? "sensor-kit-add" : "sensor-kit"}`}>
              <div className="card-header-status">
                {isOnline(timeString)}
                <div className="sub-text">{toBrowserTimeZone(timeString)}</div>
              </div>
            </div> }
            <div className="m-r-6">
            {props.kit.battery && !isEmpty(props.kit.battery) && (
              <div
                onClick={showGraphReport}
                className={`card-header-actions pull-right ${
                  isDeviceOnlineValue ? "" : "opacity-30"
                }`}
              >
                {getBatteryIcon(batteryLevel)} {batteryLevel}%
              </div>
            )}
            {props.sensorInputEnabled && 
              <div
                onClick={showSensorDataPopup}
                className={`card-header-actions pull-right`}
                style={{ marginRight: '10px', cursor: 'pointer'}}
              >
                <FeatherIcon icon="plus-circle" />
              </div>
            }
              <div
                className={`card-header-actions pull-right`}
                style={{ marginRight: '10px', cursor: 'auto', opacity: isDeviceOnlineValue ? 1 : 0.3 }}
              >
                {getWifiIcon(signalStrength)}
              </div>
            {powerSupply === 1 && (
              <div
                className={`card-header-actions pull-right`}
                style={{ marginRight: '10px', cursor: 'auto', opacity: isDeviceOnlineValue ? 1 : 0.3 }}
              >
                <FeatherIcon icon="battery-charging" />
              </div>
            )}
          </div>

          </div>
        </div>
        <div className="single-card-content">
          <div className="row p-1">
            {filteredSensors.map((sensor, index) => (
              <div className="col-6" key={'sensor' + index}>
                <div className={hasAlert(sensor) ? "sensor-details warning" : "sensor-details"}>
                  {getIcon(sensor.code)}
                  <div className="sensor-data sensor-data-p">
                    <div
                      className={isDeviceOnline(toBrowserTimeZone(timeString), props.kit.interval) ? "sensor-value limit-sensorName-sa" : "sensor-value limit-sensorName-sa opacity-30"}
                      title={formatDisplayValue(sensor.displayValue)}
                    >
                      {formatDisplayValue(sensor.displayValue)}
                    </div>
                    <div className="sensor-Name limit-sensorName-sa"
                         title={getDisplayName(sensor, props.kit.metaData)}>{getDisplayName(sensor, props.kit.metaData)}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedDevice &&
        <DeviceBatteryReport device={props} onClose={() => setSelectedDevice(null)}/>
      }
      {isAddSensorData &&
        <SensorInput kit={props.kit} sensors={filteredSensors} onClose={() => setIsAddSensorData(false)}/>
      }
    </div>
  );
};


export default SensorItem
