import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import {hasPermission, isKeellsAccount} from "../../utils/Authentication";
import {TOGGLE, VIEW} from "../../utils/Enum";
import {
  changeView,
  resetConfirmationDialog,
  toggleConfirmationDialog,
  toggleLoader
} from "../../shared/actions/setting";
import Col from "react-bootstrap/Col";
import axios from "axios";
import CorporateItem from "./corporate-item";
import FeatherIcon from 'feather-icons-react';
import FormHandler from "../../shared/utils/FormHandler";
import {corporateAdd} from "../../utils/FormValidationRules";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {getFilterCorporateList, getAccountList, corporateList} from "../../actions/module";
import FarmerLocationAdd from "../xindicate/participant/farmer-location-add";


const CorporateList = (props) => {
  const [showFadeEffect, setShowFadeEffect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [formErr, setFormErr] = useState(null);
  const [corporateData, setCorporateData] = useState([]);
  const [accountIdList, setAccountIdList] = useState([]);
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState("");
  const {t, i18n} = useTranslation();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    initForm,
  } = FormHandler(corporate, corporateAdd);
  
  function corporate() {
    setIsLoading(true)
  }

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  
  useEffect(() => {
    dispatch(changeView(VIEW.CORPORATE_LIST));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate`)
      .then(res => {
        setCorporateData(res.data.content)
        dispatch(corporateList(res.data.content))
        dispatch(getFilterCorporateList(res.data.content))
      }).catch(function (error) {
      if (error.res && error.res.status === 422) {
      }
    }).finally(() => {
      dispatch(toggleLoader(false));
    });
  }, [])

  const corporateDataList = useSelector(state => {
    return state.modules.corporateList
  });
  const corporateListFilter = useSelector(state => {
    return state.modules.corporateListFilter
  });


  useEffect(() => {
    setCorporateData(corporateListFilter)
  }, [corporateListFilter])

  function resetForm() {
    initForm({});
    setIsUpdateAvailable(false)
  }

  function onEdit(corporate) {
    initForm(corporate)
    setIsModalVisible(true)
    setIsUpdateAvailable(true)
    return corporateData.content
  }

  function onDelete(id) {
    dispatch(toggleConfirmationDialog({
      isVisible: true,
      confirmationHeading: t("confirmation_msg.ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_CORPORATE"),
      confirmationDescription: t("confirmation_msg.THE_DELETE_ACTION_WILL_REMOVE_THE_CORPORATE")
    }));
    setDeleteId(id.id);
  }


  useEffect(() => {
    if (!isLoading) {
      return
    }
    if (!isUpdateAvailable) {
      values.host = window.location.protocol + '//' + window.location.host + '/sign-up';
      if (!values.isTable) {
        delete values.username;
        delete values.password;
        delete values.confirmPassword;
      }
      axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate`, delete values.isTable && values)
        .then(res => {
          setIsModalVisible(false);
          // setCorporateData(oldArray => [...oldArray, res.data.content]);
          dispatch(corporateList([...corporateDataList, res.data.content]))
          if (corporateDataList.length == corporateListFilter.length) {
            dispatch(getFilterCorporateList([...corporateDataList, res.data.content]))
          }
          toast.success("Corporate created successfully");
          resetForm()
        }).catch(function (error) {
        if (error.res && error.res.status == 422) {
          setFormErr(error.message)
        }
      }).finally(
        () => {
          setIsLoading(false)
        }
      )
    } else {
      dispatch(toggleLoader(true));
      axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + values.id, values)
        .then(res => {
          // let index = corporateData.findIndex(id => id.id === values.id)
          // corporateData[index] = res.data.content
          let indexList = corporateDataList.findIndex(id => id.id === values.id)
          corporateDataList[indexList] = res.data.content
          dispatch(corporateList(corporateDataList))
          let indexListFiler = corporateListFilter.findIndex(id => id.id === values.id)
          console.log(indexListFiler)
          if (indexListFiler !== -1) {
            corporateListFilter[indexListFiler] = res.data.content
            dispatch(getFilterCorporateList(corporateListFilter))
          }
          setIsModalVisible(false);
          toast.success("Corporate updated successfully");

        }).catch(error => {
        if (error.response && error.response.status === 422) {
          setFormErr(error.response.data.message);
        }
      })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
        });
    }
  }, [isLoading])


  const confirmationDialog = useSelector(state => {
    return state.setting.confirmationDialog
  });


  useEffect(() => {
    if (!confirmationDialog || !confirmationDialog.onSuccess) {
      return;
    }
    dispatch(resetConfirmationDialog());
    dispatch(toggleLoader(true));
    axios.delete(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/corporate/` + deleteId)
      .then(res => {
        setCorporateData(corporateData.filter(item => item.id !== deleteId));
        dispatch(corporateList(corporateDataList.filter(item => item.id !== deleteId)))
        let indexListFiler = corporateListFilter.findIndex(id => id.id === deleteId)
        if (indexListFiler !== -1) {
          dispatch(getFilterCorporateList(corporateListFilter.filter(item => item.id !== deleteId)))
        }
        toast.success("Corporate deleted successfully");
        dispatch(toggleLoader(false));
      })
  }, [confirmationDialog]);


  useEffect(() => {
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/account`)
      .then(res => {
        setAccountIdList(res.data.content)
        dispatch(getAccountList(res.data.content))
      }).catch(function (error) {

      })
  }, [])

  const corporateGeoLocation = (location) => {
    values.coordinates = location;
  }

  return (
    <div className="smart-farm">
      {window.innerWidth > 620 ?
        <div id="listView" className={'list-view ' + (toggle === TOGGLE.LIST ? 'full-list-view' : 'half-list-view')}>
          <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list" : ""}/>
          <div className={"half-list-container"}>
            <Container className={toggle === TOGGLE.LIST ? "" : "m-t-8"}>
              <Row id={"m-rl-m-16-2"}>
                {corporateData.map((corporate) => (
                  <Col xl={4} lg={6} className={'m-b-4'} key={corporate.id}>
                    <CorporateItem onEdit={onEdit} onDelete={onDelete} corporate={corporate} />
                  </Col>
                ))}
              </Row>
              {hasPermission("CORPORATE_CREATE") &&
                <div className={"fixed-add-btn"}><FeatherIcon onClick={() => {
                  resetForm();
                  setIsModalVisible(true)
                }} className={"icon-add-btn"} icon="plus-circle"/></div>
              }
            </Container>
          </div>
        </div> :
        <div id="listView"
             className={(toggle === TOGGLE.LIST ? 'list-view full-list-view ma-res-lis' : 'mo-list-view')}>
          {corporateData.map((corporate) => (
            <CorporateItem key={corporate.id} corporate={corporate} />
          ))}
          <div className={"mo-list-view-last"}/>
        </div>}

      {corporateData && corporateData.length === 0 && (
        <div className={"empty-results position-relative"}>
          <FeatherIcon icon="info"/>
          <div className={"empty-results-text"}>No corporate available, please add</div>
        </div>)
      }

      {/**************************** corporateAddEditModelStart ********************************/}
      {isModelVisible &&
        <div>
          <div className="sa-popup-bg ">
            <div className="sa-popup">
              <form
                onSubmit={handleSubmit}
                className={'sa-modal-box-style'}>
                <div className="sa-popup-header">
                                        <span className={'sa-model-heading'}>
                                          {!isUpdateAvailable ? t("title.ADD_CORPORATE") : t("title.EDIT_CORPORATE")} </span>
                  <div className="sa-popup-close-icon"
                       onClick={() => {
                         setIsModalVisible(false);
                         setIsUpdateAvailable(false)
                       }}
                  >
                    <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
                  </div>
                </div>

                <div className="sa-popup-content">
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.NAME")}</label>
                      <input list="browsers"
                             onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.name || ''}
                             className={`form-control ${errors.name && "warning-input"}`}
                             name="name"
                             placeholder={t("placeHolder.ENTER_NAME")}
                      />
                      {errors.name && (<p className="warning-input-msg ">{errors.name}</p>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.CONTACT_NAME")}</label>
                      <input list="browsers"
                             onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.contactName || ''}
                             className={`form-control ${errors.contactName && "warning-input"}`}
                             name="contactName"
                             placeholder={t("placeHolder.ENTER_CONTACT_NAME")}
                      />
                      {errors.contactName && (<p className="warning-input-msg ">{errors.contactName}</p>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.CONTACT_MAIL")}</label>
                      <input list="browsers"
                             onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.contactEmail || ''}
                             className={`form-control ${errors.contactEmail && "warning-input"}`}
                             name="contactEmail"
                             placeholder={t("placeHolder.ENTER_CONTACT_EMAIL")}
                      />
                      {errors.contactEmail && (<p className="warning-input-msg ">{errors.contactEmail}</p>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.CONTACT_PHONE_NO")}</label>
                      <input list="browsers"
                             onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.contactPhoneNumber || ''}
                             className={`form-control ${errors.contactPhoneNumber && "warning-input"}`}
                             name="contactPhoneNumber"
                             type="number"
                             placeholder={t("placeHolder.ENTER_CONTACT_PHONE_NUMBER")}
                      />
                      {errors.contactPhoneNumber && (<p className="warning-input-msg ">{errors.contactPhoneNumber}</p>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.ACCOUNT")}</label>
                      <select onBlur={handleOnBlur} onChange={handleChange}
                              value={values.accountId || ''}
                              className={`form-control ${errors.accountId && "warning-input"}`}
                              name="accountId">
                        <option hidden value={""}>{t("title.SELECT_ACCOUNT")}</option>
                        {accountIdList.map((corporateName) => (
                          <option value={corporateName.id} key={corporateName.id}>{corporateName.name}</option>
                        ))}
                      </select>
                      {errors.accountId && (<p className="warning-input-msg ">{errors.accountId}</p>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.CONTACT_ADDRESS")}</label>
                      <input list="browsers"
                             onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.contactAddress || ''}
                             className={`form-control ${errors.contactAddress && "warning-input"}`}
                             name="contactAddress"
                             placeholder={t("placeHolder.ENTER_CONTACT_ADDRESS")}
                      />
                      {errors.contactAddress && (<p className="warning-input-msg ">{errors.contactAddress}</p>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.STATUS")}</label>
                      <select onBlur={handleOnBlur} onChange={handleChange}
                              value={values.status || ''}
                              className={`form-control ${errors.status && "warning-input"}`}
                              name="status">
                        <option hidden value={""}>{t("title.SELECT_STATUS")}</option>
                        <option value={"ACTIVE"}>{t("title.ACTIVE")}</option>
                        <option value={"SUSPENDED"}>{t("title.SUSPENDED")}</option>
                      </select>
                      {errors.status && (<p className="warning-input-msg ">{errors.status}</p>)}
                    </div>
                  </div>


                  {isKeellsAccount(values.accountId) && <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.VENDER_CODE")}</label>
                      <input onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.vendorCode || ''}
                             className={`form-control ${errors.vendorCode && "warning-input"}`}
                             name="vendorCode"
                             placeholder={t("placeHolder.ENTER_VENDER_CODE")}
                      />
                      {errors.vendorCode && (<p className="warning-input-msg ">{errors.vendorCode}</p>)}
                    </div>
                  </div>}

                  {isKeellsAccount(values.accountId) && <div className="col-md-6">
                    <div className="form-group m-b-16">
                      <label htmlFor="email">{t("title.PLANT_CODE")}</label>
                      <input list="browsers"
                             onBlur={handleOnBlur}
                             onChange={handleChange}
                             value={values.plantCode || ''}
                             className={`form-control ${errors.plantCode && "warning-input"}`}
                             name="plantCode"
                             placeholder={t("placeHolder.ENTER_PLANT_CODE")}
                      />
                      {errors.plantCode && (<p className="warning-input-msg ">{errors.plantCode}</p>)}
                    </div>
                  </div>}
                  <div className="col-md-12 mb-3">
                  <label>{t("title.CORPORATE_LOCATION")}</label>
                  <FarmerLocationAdd onChange={corporateGeoLocation}
                    location={values?.coordinates} />
                </div>
                </div>
                <div className="sa-popup-btn">
                  <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                          onClick={() => {
                            setIsModalVisible(false);
                            setIsUpdateAvailable(false)
                          }}
                  >{t("button.CANCEL")}
                  </button>
                  <button id={'taskAddOrUpdate'} className="sa-popup-secondary-btn-style" type="submit">
                    {!isUpdateAvailable ? t("button.ADD") : t("button.UPDATE")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>}
      {/**************************** corporateAddEditModelEnd ********************************/}

    </div>
  )
};


export default CorporateList
