export const getSelectedUserModule = (moduleList) => {
  return {
    type: "USER_MODULES",
    payload: moduleList
  };
};
export const getSelectedFarmModule = (farmModules) => {
  return {
    type: "FARM_MODULES",
    payload: farmModules
  };
};
export const getSelectedCorporate = (corporate) => {
  return {
    type: "SELECTED_CORPORATE",
    payload: corporate
  };
};
export const getFarmUser = (farmUser) => {
  return {
    type: "FARM_USER",
    payload: farmUser
  };
};
export const corporateChange = (corporateId) => {
  return {
    type: "CORPORATE_CHANGE",
    payload: corporateId
  };
};
export const corporateList = (corporateList) => {
  return {
    type: "CORPORATE_LIST",
    payload: corporateList
  };
};
export const getFilterCorporateList = (filterCorporateList) => {
  return {
    type: "FILTER_CORPORATE_LIST",
    payload: filterCorporateList
  };
};
export const getSelectedBuyer = (buyer) => {
  return {
    type: "SELECTED_BUYER",
    payload: buyer
  };
};
export const getSelectedAccount = (account) => {
  return {
    type: "SELECTED_ACCOUNT",
    payload: account
  };
};
export const getFilterAccountList = (filterAccountList) => {
  return {
    type: "FILTER_ACCOUNT_LIST",
    payload: filterAccountList
  };
};
export const getAccountList = (getAccountList) => {
  return {
    type: "GET_ACCOUNT_LIST",
    payload: getAccountList
  };
};
export const getBuyerList = (getBuyerList) => {
  return {
    type: "GET_BUYER_LIST",
    payload: getBuyerList
  };
}
export const getFilterBuyerList = (filterBuyerList) => {
  return {
    type: "FILTER_BUYER_LIST",
    payload: filterBuyerList
  };
}
export const getCcrList = (getCcrList) => {
  return {
    type: "GET_CCR_LIST",
    payload: getCcrList
  };
}