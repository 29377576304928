import React, { useState } from "react";
import user from "../../../images/BasicDetails.svg";
import ToggleLayout from "../../toggle-layout";
import { useTranslation } from "react-i18next";
import FarmImages from "../../farm/farm-images";

export default function FertilizerRecommendation(props) {
  const [visibleToggleIndex, setVisibleToggleIndex] = useState(1);
  const { t } = useTranslation();
  const fertilizerZones = props.crop.fertilizerApplicationMap
    ? Object.entries(props.crop.fertilizerApplicationMap) 
    : [];

  return (
    <ToggleLayout
      image={user}
      title={t("title.FERTILIZER_RECOMMENDATIONS_DETAILS")}
      dropDown={false}
      visibleToggleIndex={visibleToggleIndex}
      toggleIndex={1}
      onVisibleToggleIndexChange={(index) =>
        setVisibleToggleIndex(index === visibleToggleIndex ? 0 : index)
      }
    >
      <div className="row">
        <div className="col-12">
          <div className="row justify-content-between gap-mb-24">
            <div className="col-md-4 w-150">
              <div className="view-label">{t("title.CROP_NAME")}</div>
              <div className="view-value">
                {props.crop.cropName ? props.crop.cropName : "NA"}
              </div>
            </div>
            <div className="col-md-4 w-150">
              <div className="view-label">{t("title.CROP_VARIETY")}</div>
              <div className="view-value">
                {props.crop.category ? props.crop.category : "NA"}
              </div>
            </div>
            <div className="col-md-4 w-150">
              <div className="view-label">{t("title.CROP_IMAGE")}</div>
              {props.crop.image ? (
                <div className="">
                  <FarmImages imgs={[props.crop.image]} width={"106px"} />
                </div>
              ) : (
                <div className="view-value">NA</div>
              )}
            </div>
            
          </div>

          {fertilizerZones.length > 0 ? (
            fertilizerZones.map(([zoneName, applications], zoneIndex) => (
              <div key={`application-${zoneIndex}`} className="zone-section mt-5">
                <h3 className="zone-title">{zoneName}</h3>
                {applications.map((item, detailIndex) => (
                  <div key = {`details-${detailIndex}`} className="row justify-content-between gap-mb-24 m-b-30">
                    <div style={{marginBottom:"10px", display:"flex", gap:"60px"}}>
                  <div className="col-md-4 w-150">
                    <div className="view-label">{t("title.PLANTING_SCALE")}</div>
                    <div className="view-value">
                      {item.plantSpacing ? item.plantSpacing : "NA"}
                    </div>
                  </div>
                  <div className="col-md-4 w-150">
                    <div className="view-label">{t("title.UNIT_OF_INORGANIC_FERTILIZER")}</div>
                    <div className="view-value">
                      {item.unitOfInorganicFertilizer ? item.unitOfInorganicFertilizer : "NA"}
                    </div>
                  </div>
                  <div className="col-md-4 w-150">
                    <div className="view-label">{t("title.PLANTING_TYPE")}</div>
                    <div className="view-value">
                    {item.plantingType ? item.plantingType : "NA"}
                    </div>
                  </div>
                  <div className="col-md-4 w-150">
                    <div className="view-label">{t("title.PLANTING_STATE")}</div>
                    <div className="view-value">
                    {item.state ? item.state : "NA"}
                    </div>
                  </div>
                  </div>
                    <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th rowSpan="2">Time of Application</th>
                        <th colSpan="2">Liming</th>
                        <th colSpan="3">Organic</th>
                        <th colSpan="7">Inorganic Fertilizers</th>
                      </tr>
                      <tr>
                        <th>Lime</th>
                        <th>Dolamite</th>
                        <th>Compost</th>
                        <th>CM</th>
                        <th>PM</th>
                        <th>N</th>
                        <th>Urea</th>
                        <th>P (P2O5)</th>
                        <th>TSP</th>
                        <th>ROP</th>
                        <th>K (K2O)</th>
                        <th>MOP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.fertilizerApplication.map((application, appIndex) => (
                        <tr key={`fertilizerApplication-${appIndex}`}>
                          <td>{application.applicationTime}</td>
                          {/* <td>
                            {application.fertilizerCategory === "ORGANIC"
                              ? application.fertilizers
                                  .map((fertilizer) => fertilizer.quantity)
                                  .join(", ")
                              : "-"}                              
                          </td> */}
                          {["Lime", "Dolamite"].map(
                                (nutrient, idx) => {
                                  const fert = application.fertilizers.find(
                                    (f) => f.name === nutrient
                                  );
                                  return <td key={`liming-${application.applicationTime}-${nutrient}`}>{fert ? fert.quantity : "-"}</td>;
                                }
                              )}
                          {["Compost","PM", "CM"].map(
                                (nutrient, idx) => {
                                  const fert = application.fertilizers.find(
                                    (f) => f.name === nutrient
                                  );
                                  return <td key={`organic-${application.applicationTime}-${nutrient}`}>{fert ? fert.quantity : "-"}</td>;
                                }
                              )}
                          {["N", "Urea", "P2O5", "TSP", "ROP", "K2O", "MOP"].map(
                            (nutrient, idx) => {
                              const fert = application.fertilizers.find(
                                (f) => f.name === nutrient
                              );
                              return <td key={`nutrient-${application.applicationTime}-${nutrient}`}>{fert ? fert.quantity : "-"}</td>;
                            }
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                ))}               
                
              </div>
            ))
          ) : (
            <p>No fertilizer data available</p>
          )}
        </div>
      </div>
    </ToggleLayout>
  );
}



