import React, {useState, useEffect} from 'react';
import Joyride from 'react-joyride';
import {useDispatch, useSelector} from "react-redux";
import { KEY_LOCATION_MAP, USER_GUIDE_INFO } from './Enum';
import { useLocation } from 'react-router-dom';
import { updateJoyRideKeyByLocation } from '../shared/actions/setting';
import axios from "axios";
import { getUserId } from '../shared/utils/SharedAuthentication';
import { toast } from 'react-toastify';

const UserGuide = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [runTour, setRunTour] = useState(false);
  const [tourSteps, setTourSteps] = useState([]);
  const [tourKeys, setTourKeys] = useState([]);
  const [visitedPageMap, setVisitedPageMap] = useState({});

  const joyRideKey = useSelector((state) => {
    return state.setting.joyRideKey;
  });

  const joyRideSteps = useSelector((state) => {
    return state.setting.joyRideSteps;
  });

  useEffect(() => {
    setRunTour(false);
    setTourSteps([]);

    const matchingKeys = KEY_LOCATION_MAP
            .filter(item => location.pathname.includes(item.location))
            .map(item => item.key);
    const uniqueKeys = [...new Set(matchingKeys)];
    dispatch(updateJoyRideKeyByLocation(uniqueKeys));
  }, [location]);

  useEffect(() => {
    if (!joyRideSteps || joyRideSteps.length === 0 || !joyRideKey || joyRideKey.length === 0) return;
  
    const visitedPages = localStorage.getItem('userGuidePages');

    if (!visitedPages) return;

    let parsedPages = { visitedPageMap: {} };
    if (visitedPages) {
      parsedPages = JSON.parse(visitedPages);
    }
  
    const notVisitedKeys = joyRideKey.filter(key => !parsedPages.visitedPageMap[key]);
    const notVisitedSteps = joyRideSteps.filter(step => notVisitedKeys.includes(step.key));
  
    if (notVisitedKeys.length > 0) {
      const steps = notVisitedSteps.map((data, index) => ({
        target: `#nav-item-${data.title}`, 
        content: USER_GUIDE_INFO[data.title], 
        disableBeacon: index === 0 ? true : false,
      }));
        
      setTourSteps(steps)
      setTourKeys(notVisitedKeys)
      setTimeout(() => {
        setRunTour(true);
      }, 500); 
    }

  }, [joyRideSteps, joyRideKey]);

  const handleJoyrideCallback = () => {
    return (data) => {
      const { status } = data;
      if (status === 'finished' || status === 'skipped') {
        const visitedPages = localStorage.getItem('userGuidePages');
        let parsedPages = { visitedPageMap: {} };
        if (visitedPages) {
          parsedPages = JSON.parse(visitedPages);
        }
  
        const updatedVisitedPageMap = { ...parsedPages.visitedPageMap };
        tourKeys.forEach(key => {
          updatedVisitedPageMap[key] = true;
        });
  
        localStorage.setItem('userGuidePages', JSON.stringify({ visitedPageMap: updatedVisitedPageMap }));
        setVisitedPageMap(updatedVisitedPageMap);
        setRunTour(false);
      }
    };
  };

  useEffect(() => {
    if (Object.keys(visitedPageMap).length > 0) {
      updateGuideUser(visitedPageMap);
    }
  }, [visitedPageMap]);

  function updateGuideUser(visitedPageMap) {
    if (getUserId() === null) return
    axios.put(process.env.REACT_APP_HOST + `/ums/${getUserId()}/update-guide`, { visitedPageMap })
      .then(function (response) {
        if (response.data.content) {
        }
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
      });
  }

  return (
    <Joyride
      run={runTour}
      steps={tourSteps}
      continuous
      showSkipButton
      showProgress
      disableScrolling
      scrollToFirstStep={false}
      callback={handleJoyrideCallback()}
      styles={{
        options: {
          zIndex: 10000,
          backgroundColor: '#fff',
          textColor: 'black',
          primaryColor: "var(--primary)",
        },
        buttonNext: {
          backgroundColor: 'var(--primary)',
          color: '#fff',
        },
        buttonBack: {
          color: 'var(--primary)',
        },
        beacon: {
          inner: {
            backgroundColor: 'var(--primary)',
          },
          outer: {
            borderColor: 'var(--primary)',
            backgroundColor: 'var(--primary)',
          },
        },
      }}
      locale={{
        next: 'Got it', 
        back: 'Previous', 
        skip: 'Skip', 
        last: 'Done', 
      }}
    />
  );
};

export default UserGuide;
