import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getUserId, isAdminUser } from "../../../shared/utils/SharedAuthentication";
import axios from "axios";
import { toast } from "react-toastify";
import CropRecommendation from "./add-fertilizer-recommendation";
import FarmerViewPage from "./farmer-view-fertilizer-recommendation";
import FeatherIcon from "feather-icons-react";


const FertilizerRecommendationPage = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("irrigation");
  const [recommendations, setRecommendations] = useState({ irrigation: [], rainfed: [] });
  const [lastSavedRecommendations, setLastSavedRecommendations] = useState(() => ({ ...recommendations }));
  const [isFarmerView, setIsFarmerView] = useState(!isAdminUser());
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, index: null, tab: null });
  

const fetchFertilizerRecommendations = async () => {
    try {
        const userId = getUserId();
        const irrigationMethod = activeTab === "irrigation" ? "under-manual-irrigation" : "under-rainfed";
        const response = await axios.get(
            `${process.env.REACT_APP_HOST}/user/${userId}/irrigationMethod/${irrigationMethod}/fertilizer`
        );

        console.log("API Response:", response.data);
        const savedDetails = response.data?.content || [];

        if (response.data.statusCode === "S1000" && savedDetails.length > 0) {
            console.log("Valid fertilizer recommendations found.");

            const fetchedData = savedDetails
                .filter(cropData => cropData.fertilizerApplicationMap?.[irrigationMethod]) 
                .map((cropData, index) => {
                    const { cropName, scientificName, image, fertilizerApplicationMap } = cropData;
                    const applicationData = fertilizerApplicationMap[irrigationMethod];

                    if (!applicationData || !applicationData.fertiliserRecommendations) {
                        return null; 
                    }

                    const organicEntries = [];
                    const inorganicEntriesMap = {}; 
          
                    applicationData.fertiliserRecommendations.forEach((recommendation) => {
                      recommendation.fertilizerMap.ORGANIC.forEach((org) => {
                        if (!organicEntries.some((entry) => entry.types.includes(org.name))) {
                          organicEntries.push({ types: [org.name] });
                        }
                      });
          
                      recommendation.fertilizerMap.INORGANIC.forEach((inorg) => {
                        if (!inorganicEntriesMap[inorg.nutrient_content]) {
                          inorganicEntriesMap[inorg.nutrient_content] = [];
                        }
                        if (!inorganicEntriesMap[inorg.nutrient_content].includes(inorg.name)) {
                          inorganicEntriesMap[inorg.nutrient_content].push(inorg.name);
                        }
                      });
                    });
          
                    const inorganicEntries = Object.entries(inorganicEntriesMap).map(([nutrient, types]) => ({
                      name: nutrient,
                      types,
                    }));

                    return (
                        
                        <CropRecommendation
                        key={index + activeTab}
                        index={index}
                        tab={activeTab}
                        formDataState={{
                            cropName,
                            scientificName,
                            image,
                            plantSpacing: applicationData.plantSpacing || "",
                            spacingUnit: applicationData.plantSpacingUnit || "",
                            organicUnit: applicationData.organicUnit || "",
                            inorganicUnit: applicationData.inorganicUnit || "",
                        }}
                        rowsState={applicationData.fertiliserRecommendations.map((recommendation, idx) => ({
                            id: idx + 1,
                            time: recommendation?.timeUnit ? `${recommendation.duration} ${recommendation.timeUnit}` : "",
                            organicSources: recommendation?.fertilizerMap?.ORGANIC.map((org) => ({
                            type: org?.name || "",
                            value: org?.quantity || "",
                            })),
                            inorganicSources: recommendation?.fertilizerMap?.INORGANIC.map((inorg) => ({
                            type: inorg?.name || "",
                            value: inorg?.quantity || "",
                            nutrient_content: inorg?.nutrient_content || "",
                            })),
                        }))}
                        inorganicEntriesState={inorganicEntries}
                        organicEntriesState={organicEntries}
                        onUpdate={handleUpdateCropRecommendation}
                        />
                    );
                })
                .filter(Boolean); 

            if (fetchedData.length > 0) {
                setRecommendations(prev => ({
                    ...prev,
                    [activeTab]: fetchedData,
                }));
                setLastSavedRecommendations((prev) => ({ ...prev, [activeTab]: fetchedData }));
            } else {
                
                setRecommendations(prev => ({
                    ...prev,
                    [activeTab]: [
                        <CropRecommendation
                            key={`empty-${activeTab}`}
                            index={0}
                            tab={activeTab}
                            formDataState={{
                                cropName: "",
                                scientificName: "",
                                plantSpacing: "",
                                spacingUnit: "",
                                organicUnit: "",
                                inorganicUnit: ""
                            }}
                            rowsState={[]}
                            inorganicEntriesState={[]}
                            organicEntriesState={[]}
                            onUpdate={handleUpdateCropRecommendation}
                            
                        />
                    ],
                }));
                setLastSavedRecommendations((prev) => ({ ...prev, [activeTab]: fetchedData }));
            }
        } else {
            
            setRecommendations(prev => ({
                ...prev,
                [activeTab]: [
                    <CropRecommendation
                        key={`empty-${activeTab}`}
                        index={0}
                        tab={activeTab}
                        formDataState={{
                            cropName: "",
                            scientificName: "",
                            plantSpacing: "",
                            spacingUnit: "",
                            organicUnit: "",
                            inorganicUnit: ""
                        }}
                        rowsState={[]}
                        inorganicEntriesState={[]}
                        organicEntriesState={[]}
                        onUpdate={handleUpdateCropRecommendation}
                        
                    />
                ],
            }));
            
        }
    } catch (error) {
        console.error("Error fetching fertilizer recommendations:", error);
        toast.error(`Failed to fetch data: ${error.response?.data?.message || error.message}`);

        
        setRecommendations(prev => ({
            ...prev,
            [activeTab]: [
                <CropRecommendation
                    key={`empty-${activeTab}`}
                    index={0}
                    tab={activeTab}
                    formDataState={{
                        cropName: "",
                        scientificName: "",
                        plantSpacing: "",
                        spacingUnit: "",
                        organicUnit: "",
                        inorganicUnit: ""
                    }}
                    rowsState={[]}
                    inorganicEntriesState={[]}
                    organicEntriesState={[]}
                    onUpdate={handleUpdateCropRecommendation}
                    
                />
            ],
        }));
        setLastSavedRecommendations((prev) => ({ ...prev, [activeTab]: [] }));
    }
};

  



    useEffect(() => {
        fetchFertilizerRecommendations();
    }, [activeTab]);

  const handleUpdateCropRecommendation = (updatedData, index, tab) => {
    setRecommendations((prev) => ({
      ...prev,
      [tab]: prev[tab].map((recommendation, i) =>
        i === index ? (
          <CropRecommendation
            key={i}
            index={i}
            tab={tab}
            formDataState={updatedData.formData || {}}
            rowsState={updatedData.rows || []}
            inorganicEntriesState={updatedData.inorganicEntries || []}
            organicEntriesState={updatedData.organicEntries && updatedData.organicEntries.length > 0 
                ? updatedData.organicEntries 
                : recommendation.props.organicEntriesState} 
            onUpdate={handleUpdateCropRecommendation}
            
          />
        ) : (
          recommendation
        )
      ),
    }));
  };



  const addSoilTestingRecommendation = () => {
    const newIndex = recommendations[activeTab].length;
    setRecommendations((prev) => ({
      ...prev,
      [activeTab]: [
        ...prev[activeTab],
        <CropRecommendation
          key={newIndex}
          index={newIndex}
          tab={activeTab}
          onUpdate={handleUpdateCropRecommendation}
        />,
      ],
    }));

    setTimeout(() => {
      window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" });
  }, 500);
  };
    

const handleSave = async () => {
    try {
        const userId = getUserId();
        const currentIrrigationMethod = activeTab === "irrigation" ? "under-manual-irrigation" : "under-rainfed";

        const formattedData = recommendations[activeTab].map(recommendation => {
            const cropDetails = recommendation.props?.formDataState || {};
            const rows = recommendation.props?.rowsState || [];

            return {
                cropName: cropDetails.cropName || "",
                scientificName: cropDetails.scientificName || "",
                fertilizerApplicationMap: {
                    [currentIrrigationMethod]: {
                        plantSpacingUnit: cropDetails.spacingUnit || "",
                        plantSpacing: cropDetails.plantSpacing || "",
                        organicUnit: cropDetails.organicUnit || "",
                        inorganicUnit: cropDetails.inorganicUnit || "",
                        fertiliserRecommendations: rows
                            .filter(row => row.time) 
                            .map(row => {
                                const timeParts = row.time.split(" ");
                                const isNumber = /^\d/.test(timeParts[0]);
                                return {                                
                                duration: isNumber ? timeParts[0] : "",
                                timeUnit: isNumber ? timeParts.slice(1).join(" ") : row.time,
                                fertilizerMap: {
                                    ORGANIC: row.organicSources.map(source => ({
                                        name: source.type,
                                        nutrient_content:
                                            recommendation.props?.organicEntriesState?.find(entry =>
                                                entry.types.includes(source.type)
                                            )?.name || "",
                                        quantity: source.value,
                                    })),
                                    INORGANIC: row.inorganicSources.map(source => ({
                                        name: source.type,
                                        nutrient_content:
                                            recommendation.props?.inorganicEntriesState?.find(entry =>
                                                entry.types.includes(source.type)
                                            )?.name || "",
                                        quantity: source.value,
                                    })),
                                },
                            }}),
                    },
                },
            };
        }).filter(Boolean); 

        if (formattedData.length === 0) {
            toast.error("No valid data to save!");
            return;
        }

        const response = await axios.patch(
            `${process.env.REACT_APP_HOST}/user/${userId}/irrigationMethod/${currentIrrigationMethod}/fertilizer`,
            formattedData
        );

        setLastSavedRecommendations(recommendations);
        toast.success("Fertilizer recommendations updated successfully!");
        console.log("Updated Fertilizer Data:", response.data);
    } catch (error) {
        console.error("Error saving fertilizer recommendation:", error);
        toast.error(`Failed to save: ${error.response?.data?.message || error.message}`);
    }
};


const handleDeleteCropRecommendation = async () => {
  try {
      if (deleteConfirmation.index === null || deleteConfirmation.tab === null) return;

      const userId = getUserId();
      const irrigationMethod = deleteConfirmation.tab === "irrigation" ? "under-manual-irrigation" : "under-rainfed";
      const cropAgronomyId = recommendations[deleteConfirmation.tab][deleteConfirmation.index].props.formDataState.cropName;

      await axios.delete(
          `${process.env.REACT_APP_HOST}/user/${userId}/cropAgronomy/${cropAgronomyId}/irrigationMethod/${irrigationMethod}/fertilizer`
      );

      setRecommendations(prev => ({
          ...prev,
          [deleteConfirmation.tab]: prev[deleteConfirmation.tab].filter((_, i) => i !== deleteConfirmation.index),
      }));

      toast.success("Fertilizer recommendation deleted successfully!");
  } catch (error) {
      console.error("Error deleting fertilizer recommendation:", error);
      toast.error(`Failed to delete: ${error.response?.data?.message || error.message}`);
  } finally {
      setDeleteConfirmation({ show: false, index: null, tab: null });
  }
};


  const handleCancel = () => {
    setRecommendations((prev) => {
        const revertedRecommendations = lastSavedRecommendations[activeTab].map((recommendation, index) =>
            React.cloneElement(recommendation, { key: `reverted-${index}` }) // New key forces re-render
        );
        return {
            ...prev,
            [activeTab]: revertedRecommendations
        };
    });

    toast.success("Changes reverted to last saved state!");
  };


  return (
    <>
    {isAdminUser() && !isFarmerView && <div className="p-4">
      <div className="flex justify-content-between gap-mb-24" style={{ display: "flex", alignItems: "center" }}>
        <span className="fertilizer-title">{t("title.FERTILIZER_RECOMMENDATION")}</span>
        <div style={{ display: "flex", alignItems: "center" }}>       
          <div className="pull-right">
            <span className={"sa-table-icon-flex"}>              
                <button
                    className="sa-table-btn-secondary sa-table-float-right text-green bg-none"
                    onClick={() => setIsFarmerView(true)}
                    style={{ width:"150px", border: "1px solid #02C170" }}
                >
                  <FeatherIcon icon={"eye"} className={"sa-table-icon-size"}/>{" "}
                  <span className={"sa-table-icon"}>{t("button.FARMER_VIEW")}</span>
                </button>                
            </span>
          </div>

          
          <div className="pull-right">
            <span className={"sa-table-icon-flex"}>              
                <button
                    className="sa-table-btn-secondary sa-table-float-right"
                    onClick={addSoilTestingRecommendation}
                    style={{ width: "300px", fontSize: "14px" }}
                >
                  <FeatherIcon icon={"plus"} className={"sa-table-icon-size"}/>{" "}
                  <span className={"sa-table-icon"}>{t("button.ADD_SOIL_TESTING_RECOMMENDATION")}</span>
                </button>                
            </span>
          </div>
        </div>
      </div>

      <div className="flex border-gray mt-4">
        <button
          className={`bg-none ${activeTab === "irrigation" ? "text-green border-bottom-green" : "text-gray"}`}
          onClick={() => setActiveTab("irrigation")}
        >
          {t("button.UNDER_MANUAL_IRRIGATION")}
        </button>
        <button
          className={`bg-none ${activeTab === "rainfed" ? "text-green border-bottom-green" : "text-gray"}`}
          onClick={() => setActiveTab("rainfed")}
        >
          {t("button.UNDER_RAINFED")}
        </button>
      </div>

      <div className="mt-4">
        {recommendations[activeTab].map((recommendation, index) => (
          React.cloneElement(recommendation, {
            key: index + activeTab,
            index,
            tab: activeTab,
            onDelete: () => setDeleteConfirmation({ show: true, index, tab: activeTab })
          })
        ))}
    </div>

      <div className="sa-popup-btn" style={{ margin: "16px 0px" }}>
        <button
          type="button"
          className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
          style={{ marginRight: "10px", border: "1px solid #aaa" }}
          onClick={handleCancel}
        >
          {t("button.CANCEL")}
        </button>

        <button type="submit" className="sa-popup-secondary-btn-style" onClick={handleSave}>
          {t("button.SAVE")}
        </button>
      </div>
    </div> }
    {isFarmerView && (
  <FarmerViewPage
    recommendations={recommendations}
    activeTab={activeTab}
    setActiveTab={setActiveTab}
    setIsFarmerView={setIsFarmerView}
  />
)}

{deleteConfirmation.show && (
    <div className="sa-popup-bg false">
        <div className="sa-popup">
          <form className="sa-modal-box-style">
            <div className="sa-popup-header">
                <span className="sa-model-heading">{t("title.DELETE_CONFIRMATION")}</span>
                <div className="sa-popup-close-icon" onClick={() => setDeleteConfirmation({ show: false, index: null, tab: null })}>
                    <FeatherIcon className="sa-modal-close-icon" icon="x" />
                </div>
            </div>

            <div className="sa-popup-content warning-heading text-red">
                <p>{t("title.ARE_YOU_SURE_YOU_WANT_TO_DELETE_FR")}</p>
            </div>

            <div className="sa-popup-btn">
                <button
                    type="button"
                    className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                    onClick={() => setDeleteConfirmation({ show: false, index: null, tab: null })}
                >
                    {t("button.CANCEL")}
                </button>
                <button
                    type="button"
                    className="sa-popup-secondary-btn-style btn-sa-danger"
                    onClick={handleDeleteCropRecommendation}
                >
                    {t("button.DELETE")}
                </button>
            </div>
            </form>
        </div>
    </div>
)}

    </>
  );
};

export default FertilizerRecommendationPage;
