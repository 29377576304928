import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Container from "react-bootstrap/Container";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import { VIEW } from "../../utils/Enum";
import {changeView} from "../../shared/actions/setting";
import { getFarmList } from "../../actions/farm";
import {getCcrList, getBuyerList} from "../../actions/module";
import axios from "axios";
import { getUserId } from "../../shared/utils/SharedAuthentication";
import { useSelector } from "react-redux";
import {toggleLoader} from "../../shared/actions/setting";

export default function CCRHeader() {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();

  function onPaths(paths) {
    return (match, location) => {
      return location.pathname.match(paths);
    };
  }

  const farmList = useSelector(state => {
    return state.farmList.farmList
  });
  const ccrList = useSelector(state => {
    return state.modules.ccrList
  })
  const buyerList = useSelector(state => {
    return state.modules.buyerList
  })
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });

  useEffect(() => {
    const promises = [];
    let dataFetchArray = [];

    if (farmList.length === 0) {
      promises.push(axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm`+ (selectedCorporate ? `?corporate=` + selectedCorporate : "")))
      dataFetchArray.push("getFarmers");
    }
    if (ccrList.length === 0) {
      promises.push(axios.get(process.env.REACT_APP_HOST + "/user/" + getUserId() + "/ccr-users"))
      dataFetchArray.push("getCCRs");
    }
    if (buyerList.length === 0) {
      promises.push(axios.get(process.env.REACT_APP_HOST + "/user/" + getUserId() + "/buyer"))
      dataFetchArray.push("getBuyers");
    }

    if (promises.length > 0) {
      dispatch(toggleLoader(true));
    Promise.all(promises)
    .then((responses) => {

      responses.forEach((response, index) => {
        if (dataFetchArray[index] === "getFarmers") {
          dispatch(getFarmList(response.data.content));
        } else if (dataFetchArray[index] === "getCCRs") {
          dispatch(getCcrList(response.data.content));
        } else if (dataFetchArray[index] === "getBuyers") {
          dispatch(getBuyerList(response.data.content));
        }
      })
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      dispatch(toggleLoader(false));
    });
  }
  }, []);



  useEffect(() => {
    dispatch(changeView(VIEW.CCR_PROFILE));
  }, []);

  return (
    <Container>
      <div className="single-card m-rl-m-8">
        <div className={`d-flex text-center tab-container-ccr m-rl-m-16`}>
            <NavLink to={'/ccr/ccr-supply'} isActive={onPaths("/ccr-supply")}
                    activeClassName="plot-tog-active">
              <div id={'tabSetting'} className="farm-header-toggles">
                <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="package"/><span
                className="farm-tab-yield"> {t("title.SUPPLIES")}</span></div>
            </NavLink>
            <NavLink to={'/ccr/ccr-demand'} isActive={onPaths("/ccr-demand")}
                    activeClassName="plot-tog-active">
              <div id={'tabSetting'} className="farm-header-toggles">
                <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="inbox"/><span
                className="farm-tab-yield"> {t("title.DEMANDS")}</span></div>
            </NavLink>
            <NavLink to={'/ccr/deals'} isActive={onPaths("/deals")}
                    activeClassName="plot-tog-active">
                <div id={'tabSetting'} className="farm-header-toggles">
                <FeatherIcon className="icon-in-tab farm-tab-icon-color" icon="shopping-cart"/><span
                className="farm-tab-yield"> {t("title.DEALS")}</span></div>
            </NavLink> 
        </div>
      </div>
    </Container>
  )
}
