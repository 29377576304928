import React, {useEffect, useState} from "react";
import {hasPermission} from "../../utils/Authentication";
import {formatDisplayEnumValue, toCapsFirst, InformativeMSG, camelToReadable} from "../../utils/Utils";
import Container from "react-bootstrap/Container";
import FormHandler from "../../shared/utils/FormHandler";
import {validateCCRUpdate} from "../../utils/FormValidationRules";
import FeatherIcon from "feather-icons-react";
import {toggleLoader} from "../../shared/actions/setting";
import {useDispatch} from "react-redux";
import axios from 'axios';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {DateRangePicker} from "rsuite";
import * as dateFns from "date-fns";
import FilterHandler from "../../shared/utils/CombinedFilterHandler";
import {dateFormat} from "../../shared/utils/utils";
import { vi } from "date-fns/locale";
import DemandCallHistory from "./demand-call-history";
import DemandRequestCall from "./demand-request-call";
import SupplyCallHistory from "./supply-call-history";
import asc from "../../images/icons/asc-sort.svg";
import dsc from "../../images/icons/dsc-sort.svg";
import sort from "../../images/icons/sort-option.svg";
import DemandCreateOrderDetail from "./demandCreate-order-detail";
import {useSelector} from "react-redux";
import {Typeahead} from "react-bootstrap-typeahead";

const DemandRequests = () => {
  const [demandList, setDemandList] = useState([]);
  const [ccrList, setCcrList] = useState([]);
  const [demandLogList, setDemandLogList] = useState([]);
  const [demandListUnFiltered, setDemandListUnFiltered] = useState([]);
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLogVisible, setIsLogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formErr, setFormErr] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [requestData, setRequestData] = useState([]);
  const [isViewVisible, setIsViewVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [isRequestViewVisible, setIsRequestViewVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isCallVisible, setIsCallVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [createdOrderData, setCreatedOrderData] = useState([]);
  const [orderCreated, setOrderCreated] = useState(false); 
  const [selectedSupplierData, setSelectedSupplierData] = useState(null);
  const {t, i18n} = useTranslation();

  const dispatch = useDispatch();
  const [farmId, setFarmId] = useState(null);
  const [buyerId, setBuyerId] = useState(null);
  const [demandId, setDemandId] = useState(null);
  const [filteredCcrNames, setFilteredCcrNames] = useState([]);
  const [selectedDemand, setSelectedDemand] = useState(null);
  const [ccrValue, setCCRValue] = useState("");
  const [viewData, setViewData] = useState(null);
  const [matchData, setMatchData] = useState([]);
  const [isRequestCall, setIsRequestCall] = useState(false);
  const [isMatchCall, setIsMatchCall] = useState(false);
  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');
  const [col, setCol] = useState('');
  const [order, setOrder] = useState(0);
  const [isInterestOrderVisible, setIsInterestOrderVisible] = useState(false);
  const [isMatchOrderVisible, setIsMatchOrderVisible] = useState(false);
  const [buyer, setBuyer] = useState(null);

  const [selectedBuyer, setSelectedBuyer] = useState([]);
  const [selectedDemandStatus, setSelectedDemandStatus] = useState([]);
  const [selectedCCR, setSelectedCCR] = useState([]);
  
  const buyerList = useSelector(state => {
    return state.modules.buyerList;
  })

  const demandStatus = [
    "DEMAND_CONFIRMED",
    "DEMAND_DECLINED"
  ]

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm,
  } = FormHandler(demandRequest, validateCCRUpdate);


  const [dateRange, setDateRange] = useState({
    fromDate: dateFormat(new Date(Date.now() - 604800000)),
    toDate: dateFormat(new Date())
  });

  const onCheck = (e) => {
    const dates = {
      fromDate: dateFormat(new Date(e[0])),
      toDate: dateFormat(new Date(e[1]))
    };
    setDateRange(dates);
  };
  
  const filteredRequests = requestData ? requestData.filter(request => request.requestStatus === "INTERESTED") : [];

  function demandRequest() {
    setIsLoading(true);
  }

  const fetchDemands = () => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/demands`, {
      params: {
        from: dateRange.fromDate ? dateRange.fromDate : null,
        to: dateRange.toDate ? dateRange.toDate : null,
        buyerId : selectedBuyer[0] ? selectedBuyer[0].id : null,
        ccr : selectedCCR[0] ? selectedCCR[0].lastName : null,
        demandStatus : selectedDemandStatus[0] ? selectedDemandStatus[0] : null,
      }
    })
      .then(res => {
        setDemandList(res.data.content.content);
        setDemandListUnFiltered(res.data.content.content)
        dispatch(toggleLoader(false));
      })
      .catch(error => {
        console.error('Error fetching supplies:', error);
        dispatch(toggleLoader(false));
      });
  };

  useEffect(() => {
    if (!buyerId) return;

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/buyer/` + buyerId)
      .then(res => {
        setBuyer(res.data.content);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [buyerId]);

  useEffect(() => {
    fetchDemands();
  }, [selectedBuyer, selectedDemandStatus, selectedCCR, dateRange]);

  const fetchInterestRequest = () => {
    if (!viewData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/demand/` + viewData.id + `/request`) 
      .then(res => {
        setRequestData(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  };

  useEffect(() => {
    fetchInterestRequest();
  }, [viewData]);

  useEffect(() => {

    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/ccr-users`) 
      .then(res => {
        setCcrList(res.data.content);
        const filtered = res.data.content.map(role => role.lastName);
        setFilteredCcrNames(filtered);
      }).catch(error => {
        console.error("Error fetching users", error);
      }).finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    if (!farmId) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId)
      .then(res => {
        setSelectedSupplierData(res.data.content);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  }, [farmId]);

  useEffect(() => {
    if (!selectedData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/activity-log` + '?objectId=' + selectedData.id + `&order=DESC&pageSize=10&pageIndex=0`) 
      .then(res => {
        setDemandLogList(res.data.content.content);
      }).catch(error => {
        console.error("Error fetching logs", error);
      }).finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [selectedData]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }

      dispatch(toggleLoader(true));
      axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/demand/` + values.demandId +'?ccr='+ values.ccr)
        .then(res => {
          toast.success("CCR assigned successfully");
          setDemandList(demandList.map(demand => {
            if (demand.id === values.demandId) {
              return {...demand, ccr: values.ccr};
            }
            return demand;
          }));
          resetFormData()
        })
        .catch(error => {
          if (error.response.status === 422) {
            toast.error(error.response.data.message)
          } 
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(toggleLoader(false));
        });

  }, [isLoading]);

  const fetchMatchData = () => {
    if (!viewData) {
      return;
    }
      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/matched-supplies?demandId=` + viewData.id)
        .then(res => {
          setMatchData(res.data.content);
          dispatch(toggleLoader(false));
        })
        .catch(err => {
          dispatch(toggleLoader(false));
          console.error("Error fetching data", err);
        });
  };

  useEffect(() => {
    fetchMatchData();
  }, [viewData]);

  useEffect(() => {
    if (!viewData) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/buyer/` + viewData.buyerId + `/demand/` + viewData.id + `/orders`)
      .then(res => {
        setCreatedOrderData(res.data.content);
        setOrderCreated(false);
        dispatch(toggleLoader(false));
      })
      .catch(err => {
        dispatch(toggleLoader(false));
        console.error("Error fetching data", err);
      });
  }, [viewData, orderCreated]);

  const handleCreateOrder = (requestId) => {
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/request/' + requestId)
    .then(res => {
      toast.success("Order created successfully");
      setRequestData(prevData => prevData.filter(request => request.id !== requestId));
      setOrderCreated(true);
      setViewData({...viewData, availableStock: viewData.availableStock - requestData.find(request => request.id === requestId).quantity});
    })
    .catch(error => {
      if (error.response.status === 422) {
        toast.error(error.response.data.message)
      } 
    })
    .finally(() => {
        dispatch(toggleLoader(false));
        fetchDemands();
     });
  };

  const handleCreateMatchOrder = (supply) => {
    dispatch(toggleLoader(true));
    axios.post(process.env.REACT_APP_HOST + `/user/` + getUserId() + '/supply/' + supply.supplyId + '/demand/' + viewData.demandId + '/create-order', {
      quantity: supply.availableStock, price: supply.price, interestDeadline: viewData.neededDeadline, qualityParameters: supply.qualityParameters})
      .then(res => {
        toast.success("Order created successfully");
        setMatchData(prevData => prevData.filter(supply => supply.supplyId !== supply.supplyId));
        setOrderCreated(true);
        setViewData({...viewData, availableStock: viewData.availableStock - supply.availableStock});
      })
      .catch(error => {
        if (error.response.status === 422) {
          toast.error(error.response.data.message)
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
        fetchDemands();
      });
  };

  function resetFormData() {
    setIsModalVisible(false);
    setFormErr("");
    initForm({})
    Object.keys(errors).forEach(key => delete errors[key]);
  }

  const fieldsToFilter = ['item'];

  const {
    handleSearch,
    filteredList,
    // handleFilter,
    filters
  } = FilterHandler(setFilteredList, demandListUnFiltered, fieldsToFilter);

  function setFilteredList() {
    setDemandList(filteredList);
    setDemandListUnFiltered(demandListUnFiltered);
  }

  function handleView(log) {
    setSelectedItem(JSON.parse(log.object));
  }

  function getValue(key) {
    if (typeof selectedItem[key] === 'object') {
      return "OBJECT";
    }
    return formatDisplayEnumValue(selectedItem[key]);
  }

  const handleClose = () => {
    if (isLogVisible) {
      setIsLogVisible(false);
    }
    if (isInfoVisible) {
      setIsInfoVisible(false);
    }
    if (isRequestViewVisible) {
      setIsRequestViewVisible(false);
    }
    if (isCallVisible) {
      setIsCallVisible(false);
    }
    if (isRequestCall) {
      setIsRequestCall(false);
    }
  };

  const handleSort = (selectedValue) => {
    const order = sortField === selectedValue ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(selectedValue);
    setSortOrder(order);
    setCol(selectedValue);
    setOrder(sortField === selectedValue ? (sortOrder === 'asc' ? 2 : 1) : 1);

    const sortedList = [...requestData];
    const sortedMatchList = [...matchData];
    if (selectedValue === 'quantity') {
      sortedList.sort((a, b) => order === 'asc' ? a.quantity - b.quantity : b.quantity - a.quantity);
    } else if (selectedValue === 'price') {
      sortedList.sort((a, b) => order === 'asc' ? a.price - b.price : b.price - a.price);
    } else if (selectedValue === 'supplyQuantity') {
      sortedMatchList.sort((a, b) => order === 'asc' ? a.availableStock - b.availableStock : b.availableStock - a.availableStock);
    } else if (selectedValue === 'supplyPrice') {
      sortedMatchList.sort((a, b) => order === 'asc' ? a.price - b.price : b.price - a.price);
    }

    setRequestData(sortedList);
    setMatchData(sortedMatchList);
  };

  return (
    <div>
      <Container>
      <div className="single-card overflow-visible m-rl-m-8  p-a-16">
      <div className={'sa-table-flex sa-table-position'} style={{marginBottom: 8}}>
        <span hidden={isViewVisible} className={'sa-table-icon-flex gap-8 filter-wrapper sa-table-filter-position member-row'}>
          <span className="sa-table-btn-mute sa-search-align sa-table-float-left m-r-8">
                <input
                  className={'sa-table-search sa-table-search-point font-14'}
                  onChange={handleSearch}
                  autoComplete={"off"}
                  type="text"
                  placeholder={t("placeHolder.SEARCH")}
                  name="search"
                />
                <FeatherIcon className={"sa-search-icon"} icon={"search"} width={'16px'}/>
            </span>
            <span className={"sa-table-daterange-hide sa-table-float-left m-r-8"} style={{marginLeft:'5px'}}>
              <DateRangePicker disabledDate={date => date > new Date()} disabled={false}
                               onChange={(e) => onCheck(e)}
                               oneTap={false} onOk={(e) => onCheck(e)}
                               showWeekNumbers={true} appearance={"default"}
                               placeholder={t("title.LAST_WEEK")}
                               ranges={[{
                                label: 'Today',
                                value: [new Date(), new Date()]
                               }, {
                                label: 'Yesterday',
                                value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
                               }, {
                                label: 'Last 7 days',
                                value: [dateFns.subDays(new Date(), 6), new Date()]
                               }, {
                                label: 'Last 30 days',
                                value: [dateFns.subDays(new Date(), 30), new Date()]
                               }]}
              />
            </span>

            <span className={"sa-table-btn-mute sa-table-float-left m-r-8  custom-typeahead"}>
              <Typeahead
                    id="basic-typeahead-single"
                    labelKey={option => `${option.companyName}`}
                    onChange={(selected) => {
                      if (selected.length === 0) {
                        setSelectedBuyer([])
                        return
                      }
                      setSelectedBuyer(selected)
                    }}
                    options={buyerList}
                    placeholder={t("title.SELECT_BUYER")}
                    selected={selectedBuyer.length > 0 ? selectedBuyer : []}/>
            </span>

            <span className={"sa-table-btn-mute sa-table-float-left m-r-8  custom-typeahead"}>
                <Typeahead
                    id="basic-typeahead-single"
                    labelKey={option => `${formatDisplayEnumValue(option)}`}
                    onChange={(selected) => {
                      if (selected.length === 0) {
                        setSelectedDemandStatus([])
                        return
                      }
                      setSelectedDemandStatus(selected)
                    }}
                    options={demandStatus}
                    placeholder={t("title.SELECT_STATUS")}
                    selected={selectedDemandStatus.length > 0 ? selectedDemandStatus : []}/>
            </span>

            <span className={"sa-table-btn-mute sa-table-float-left m-r-8  custom-typeahead"}>
                <Typeahead
                    id="basic-typeahead-single"
                    labelKey={option => `${option.lastName}`}
                    onChange={(selected) => {
                      if (selected.length === 0) {
                        setSelectedCCR([])
                        return
                      }
                      setSelectedCCR(selected)
                    }}
                    options={[{ lastName: "None" }, ...ccrList]}
                    placeholder={t("title.SELECT_CCR")}
                    selected={selectedCCR.length > 0 ? selectedCCR : []}/>
            </span>
        </span>
         </div>

          <div className="sa-table-container ccr-user-calc-height" hidden={isViewVisible}>
            <table className="table table-borderless sa-table-width">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>{t("title.DEMAND_ID")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.PRODUCT")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.QUANTITY")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.PRICE")}</th>
                <th className={"sa-table-head-sticky"}>{t("title.NEEDED_DEADLINE")}</th>
                   <th className="sa-table-item-align sa-table-head-sticky"></th>
              </tr>
              </thead>
              <tbody>
              {demandList.length > 0 && (
                demandList.map((demand, index) => (
                <tr key={index}>
                  <td className={'sa-table-data'}> 
                    <FeatherIcon icon={demand.demandStatus === "DEMAND_CONFIRMED" ? "check" : demand.demandStatus === "DEMAND_DECLINED" ? "x" : "" }
                    className={`sa-table-arrow ${demand.demandStatus === "DEMAND_CONFIRMED" ? "text-green" : (demand.demandStatus === "DEMAND_DECLINED" ? "text-red" : null) }`}/>{demand.demandId}</td>
                  <td className={'sa-table-data'}>{toCapsFirst(demand.item)}</td>
                  <td className={'sa-table-data'}>{(demand.unit && demand.unit !== "None") ? demand.availableStock + ` ` + demand.unit : demand.availableStock}</td>
                  <td className={'sa-table-data'}>Rs. {demand.price}</td>
                  <td className={'sa-table-data'}>{demand.neededDeadline}</td>
                    <td className="sa-table-item-align">
                      <div className={'sa-table-action-flex'}>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.PHONE_CALL")}
                            onClick={() => {
                              setIsCallVisible(true);
                              Object.keys(errors).forEach(k => errors[k] = "");
                              values.demandID = demand.id;
                              setFormErr("");
                              setSelectedData(demand);
                            }}><FeatherIcon className={"table-action"}
                                           icon={"phone-call"}/></div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={demand.ccr ? t("button.ASSIGNED") : t("button.ASSIGN")}>
                            <FeatherIcon
                                onClick={() => {
                                setIsModalVisible(true);
                                Object.keys(errors).forEach(k => errors[k] = "");
                                values.demandId = demand.id;
                                values.ccr = demand.ccr;
                                values.demandID = demand.demandId;
                                setCCRValue(values.ccr);
                                setFormErr("");
                                }}
                                className={`table-action ${demand.ccr ? "text-green" : null}`} icon={"user-check"}/></div>
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.SUPPLY_AVAILABILITY")}
                             onClick={() => {
                                setViewData(demand);
                                setIsRequestViewVisible(true);
                             }}><div className="icon-wrapper">
                             <span className={`view-dot ${demand.requests ? "visible" : ''}`}>{demand.requests > 0 ? demand.requests : ''}</span>
                             <FeatherIcon className={"table-action"}
                                            icon={"user"}/>
                            </div></div> 
                      <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.INFO")}
                             onClick={() => {
                                setSelectedDemand(demand);
                                setIsInfoVisible(true);
                             }}><FeatherIcon className={"table-action"}
                                             icon={"info"}/></div>
                        <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.LOGS")}
                             onClick={() => {
                                setSelectedData(demand);
                                setIsLogVisible(true);
                             }}><FeatherIcon className={"table-action"}
                                             icon={"airplay"}/></div>
                      </div>
                    </td>
                </tr>
              )))}
              </tbody>
            </table>
            {demandList && demandList.length === 0 && (
              <div className={"empty-results"}>
                <FeatherIcon icon="info"/>
                <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_DEMANDS")}</div>
              </div>)
            }
          </div>
          {isViewVisible && <div>
          <div className={'d-flex align-items-center pdf-hide'}>
            <div className={"mb-3 pdf-hide"}>
              <FeatherIcon icon={"arrow-left"} onClick={() => {
                setIsViewVisible(false)
              }}
                  className={"sa-cursor"}/> {t("title.ORDERS")}
            </div>
          </div>
        </div>}
</div>
      </Container>

      <div className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">
          <form onSubmit={handleSubmit} noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.ASSIGN_CCR")}</span>
              <div className="sa-popup-close-icon" onClick={resetFormData}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className={"col-11 informative-msg"}>
              <FeatherIcon className={"informative-msg-icon"} icon="alert-circle"/>
              <span className={"agronomy-practices-text"}>{t("info.CCR_DEMAND_DETAILS")}<span style={{color:'#22C687'}}>{values.demandID}</span> {t("info.CCR_DETAILS")}</span>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("title.CCR_USERNAME")}</label>
                  {!ccrValue ? (
                  <select 
                    onBlur={handleOnBlur} onChange={handleChange} 
                    value={values.ccr || ''} type="text"
                    className={`form-control mb-3 ${errors.ccr && "warning-input"}`}
                    name="ccr"
                    placeholder={t("placeHolder.SELECT_CCR_USERNAME")}
                  >
                    <option value={""} disabled>{t("placeHolder.SELECT_CCR_USERNAME")}</option>
                    {filteredCcrNames.map((username, index) => (
                      <option key={index} value={username}>{username}</option>
                    ))}
                  </select>
                  )
                  : (<input
                    value={values.ccr || ''} type="text"
                    className={`form-control ${errors.ccr && "warning-input"}`}
                    name="ccr" disabled
                  />)
                  }
                  {errors.ccr && (<p className="warning-input-msg m-0">{errors.ccr}</p>)}
                </div>
              </div>
            </div>

            {formErr && (<div className={"login-warning-msg"}>{formErr}</div>)}
            <div className="sa-popup-btn d-flex ml-3 justify-content-end">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" style={{border: '1px solid #aaa', marginRight: '10px'}}
                 onClick={resetFormData}>{t("button.CANCEL")}
              </button>
              <button className={`sa-popup-secondary-btn-style ${ccrValue ? 'disable-btn' : ''}`} disabled={ccrValue}>
                {t("button.SAVE")}</button>
            </div>
          </form>
        </div>
      </div>
      
      <div className={"sa-popup-bg mobile-view-popup " + ((isLogVisible ? !isLogVisible : !isRequestViewVisible) && 'hide')}>
        <div className="sa-popup view-scroll">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header" style={{overflow:'visible'}}>
              {isRequestViewVisible && (<span className={'sa-model-heading'}>{t("title.SUPPLY_AVAILABILITY")}</span>)}
              {isLogVisible && (<span className={'sa-model-heading'}>{t("title.ACTIVITY_LOG")}</span>)}
              <div className="sa-popup-close-icon" onClick={handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            {isRequestViewVisible && InformativeMSG(t("info.INTERESTED_IN_SUPPLY_AVAILABILITY"))}
            <div className="sa-popup-content m-b-16">
                {isLogVisible && (
                  <>
                  <div style={{paddingLeft: '10px'}}>{t("title.CCR")} : {(selectedData.ccr ? toCapsFirst(selectedData.ccr) : "NA")}</div>
                  <table className="table table-borderless sa-table-width">
                  <thead>
                  <tr>
                    <th className={"sa-table-head-sticky"}>{t("title.TIME")}</th>
                    <th className={"sa-table-head-sticky"}>{t("title.TYPE")}</th>
                    <th className={"sa-table-head-sticky"}>{t("title.ACTION_BY")}</th>
                    <th className="sa-table-head-sticky">{t("title.DESCRIPTION")}</th>
                    <th className="sa-table-item-align sa-table-head-sticky">{t("title.ACTION")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {demandLogList && demandLogList.length > 0 && demandLogList.map((log, index) => (
                      <tr key={index}>
                      <td className={'sa-table-data'}>{log.time}</td>
                      <td className={'sa-table-data'}>{log.type}</td>
                      <td className={'sa-table-data'}>{log.userInfo.lastName}</td>
                      <td className={'sa-table-data'}>{log.description}</td>
                      <td className="sa-table-item-align">
                        <div className={'sa-table-action-flex'}>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title="View">
                            <FeatherIcon
                              onClick={() => {
                                handleView(log);
                              }}
                              className="table-action" icon={"eye"}/></div>
                        </div>
                      </td>
                      </tr>
                  ))}
                  </tbody>
                  </table>
                  {demandLogList && demandLogList.length === 0 && (<div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_ACTIVITIES")}</div>
                  </div>)}
                  </>
                )}

                {isRequestViewVisible && (
                  <>
                  <div className="list-container">
                  <div className="single-card mb-0">
                  <FeatherIcon icon={"archive"} className={"sa-icon"}/>
                    <div className="card-header-text">
                      <div>
                        <label className="sub-text">{t("title.PRODUCT")}</label>
                        <div className="heading">{viewData.item}</div>
                      </div>
                    </div> 
                  </div>
                  <div className="single-card mb-0">
                  <FeatherIcon icon={"calendar"} className={"sa-icon"}/>
                    <div className="card-header-text">
                      <div>
                        <label className="sub-text">{t("title.NEEDED_DEADLINE")}</label>
                        <div className="heading">{viewData.neededDeadline}</div>
                      </div>
                    </div>
                  </div>
                  <div className="single-card mb-0">
                  <FeatherIcon icon={"user"} className={"sa-icon"}/>
                    <div className="card-header-text">
                      <div>
                        <label className="sub-text">{t("title.DEMAND_ID")}</label>
                        <div className="heading">{viewData.demandId}</div>
                      </div>
                    </div>
                  </div>
                  <div className="single-card mb-0">
                  <FeatherIcon icon={"package"} className={"sa-icon"}/>
                    <div className="card-header-text">
                      <div>
                        <label className="sub-text">{t("title.QUANTITY")}</label>
                        <div className="heading">{viewData.availableStock}</div>
                      </div>
                    </div>
                  </div>
                  <div className="single-card mb-0">
                  <FeatherIcon icon={"dollar-sign"} className={"sa-icon"}/>
                    <div className="card-header-text">
                      <div>
                        <label className="sub-text">{t("title.PRICE")}</label>
                        <div className="heading">{viewData.price}</div>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="table-whole">
                  <p>{t("title.INTERESTED")}<span className="interest-line"></span></p>
                  <table className="table table-borderless sa-table-width">
                  <thead>
                  <tr>
                      <th className={"sa-table-head-sticky"}>{t("title.INTEREST_STATUS")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.NAME")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.EMAIL")}</th>
                      <th className={"sa-table-head-sticky"}>{t("title.PHONE_NO")}</th>
                      <th className="sa-table-head-sticky" onClick={() => handleSort('price')}>{t("title.PRICE")} (per kg)
                        <span>
                          {(order === 0 || col !== 'price') &&
                            <img className="short-option-style" src={sort} alt="Sort"/>}
                          {order === 1 && col === 'price' &&
                            <img className="short-option-style" src={asc} alt="Ascending"/>}
                          {order === 2 && col === 'price' &&
                            <img className="short-option-style" src={dsc} alt="Descending"/>}
                        </span>
                      </th>
                      <th className={"sa-table-head-sticky"} onClick={(e) => handleSort('quantity')}>{t("title.AVAILABLE_QUANTITY")}
                        <span>
                          {(order === 0 || col !== 'quantity') &&
                              <img className="short-option-style" src={sort} alt="Sort"/>}
                          {order === 1 && col === 'quantity' &&
                            <img className="short-option-style" src={asc} alt="Ascending"/>}
                          {order === 2 && col === 'quantity' &&
                            <img className="short-option-style" src={dsc} alt="Descending"/>}
                        </span>
                      </th>
                      <th className={"sa-table-head-sticky"}>{t("title.EXPECTED_HARVESTING_DATE")}</th>
                        <th className="sa-table-item-align sa-table-head-sticky"></th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredRequests.length > 0 ? (
                    filteredRequests.map((request, index) => (
                      <tr key={index}>
                      <td className={'sa-table-data'}>{toCapsFirst(request.requestStatus)}</td>
                      <td className={'sa-table-data'}>{toCapsFirst(request.supplierInfo.lastName)}</td>
                      <td className={'sa-table-data'}>{request.email ? request.supplierInfo.email : 'NA'}</td>
                      <td className={'sa-table-data'}>{request.phoneNo ? request.supplierInfo.phoneNo : 'NA'}</td>
                      <td className={'sa-table-data'}>Rs. {request.price}</td>
                      <td className={'sa-table-data'}>{(request.unit && request.unit !== "None") ? request.quantity + ` ` + request.unit : request.quantity}</td>
                      <td className={'sa-table-data'}>{request.interestDeadline}</td>
                      <td className="sa-table-item-align">
                        <div className={'sa-table-action-flex'}>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.PHONE_CALL")}
                             onClick={() => {
                              setIsRequestCall(true);
                              Object.keys(errors).forEach(k => errors[k] = "");
                              values.id = request.id;
                              setIsRequestViewVisible(false);
                              setFormErr("");
                              setSelectedData(request);
                             }}><FeatherIcon className={`table-action ${request.supplyStatus === "SUPPLY_CONFIRMED" ? "text-green" : (request.supplyStatus === "SUPPLY_DECLINED" ? "text-red" : null)}`}
                                             icon={"phone-call"}/></div>
                          <button className={`sa-popup-secondary-btn-style ${viewData.availableStock < request.quantity ? 'disable-btn' : ''} ${viewData.demandStatus === "DEMAND_CONFIRMED" && request.supplyStatus === "SUPPLY_CONFIRMED" ? '' : 'disable-btn'} `}
                            style={{width:"120px"}}
                            type="button"
                            onClick={() => {setIsInterestOrderVisible(true);
                              setIsRequestViewVisible(false);
                              setSelectedData(request);
                              setFarmId(request.supplierInfo.id);
                              setBuyerId(request.buyerInfo.id);
                            }}
                            disabled={viewData.availableStock === 0 || viewData.availableStock < request.quantity || request.supplyStatus !== "SUPPLY_CONFIRMED" || viewData.demandStatus !== "DEMAND_CONFIRMED"}>{t("button.CREATE_ORDER")}</button>
                        </div>
                      </td>
                      </tr>
                  ))
                  ) : (
                    <tr>
                    <td colSpan="8">
                      <div className={"empty-results"} style={{padding:'5px'}}>
                        <FeatherIcon icon="info" />
                        <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_INTERESTED_REQUESTS")}</div>
                      </div>
                    </td>
                  </tr>
                  )}
                  </tbody>
                  </table>
                  </div>

                  <div className="table-whole">
                    <p style={{color:'red'}}>{t("title.POSSIBLE_MATCHES")}<span className="not-interest-line"></span></p>
                    <table className="table table-borderless sa-table-width">
                      <thead>
                        <tr>
                          <th className={"sa-table-head-sticky"}>{t("title.SUPPLY_ID")}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.SUPPLY_STATUS")}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.EMAIL")}</th>
                          <th className={"sa-table-head-sticky"}>{t("title.PHONE_NO")}</th>
                          <th className={"sa-table-head-sticky"} onClick={(e) => handleSort('supplyPrice')}>{t("title.PRICE")}{`(per kg)`}
                            <span>
                              {(order === 0 || col !== 'supplyPrice') &&
                                <img className="short-option-style" src={sort} alt="Sort"/>}
                              {order === 1 && col === 'supplyPrice' &&
                                <img className="short-option-style" src={asc} alt="Ascending"/>}
                              {order === 2 && col === 'supplyPrice' &&
                                <img className="short-option-style" src={dsc} alt="Descending"/>}
                            </span>
                          </th>
                          <th className={"sa-table-head-sticky"} onClick={(e) => handleSort('supplyQuantity')}>{t("title.AVAILABLE_QUANTITY")}
                            <span>
                              {(order === 0 || col !== 'supplyQuantity') &&
                                <img className="short-option-style" src={sort} alt="Sort"/>}
                              {order === 1 && col === 'supplyQuantity' &&
                                <img className="short-option-style" src={asc} alt="Ascending"/>}
                              {order === 2 && col === 'supplyQuantity' &&
                                <img className="short-option-style" src={dsc} alt="Descending"/>}
                            </span>
                          </th>
                          <th className={"sa-table-head-sticky"}>{t("title.EXPECTED_HARVESTING_DATE")}</th>
                            <th className="sa-table-item-align sa-table-head-sticky"></th>
                        </tr>
                      </thead>
                    <tbody>
                      {matchData && matchData.length > 0 && matchData.map((supply, index) => (
                        <tr key={index}>
                        <td className={'sa-table-data'}>{supply.supplyId}</td>
                        <td className={'sa-table-data'}>{formatDisplayEnumValue(supply.supplyStatus)}</td>
                        <td className={'sa-table-data'}>{supply.email ? supply.buyerInfo.email : 'NA'}</td>
                        <td className={'sa-table-data'}>{supply.phoneNo ? supply.buyerInfo.phoneNo : 'NA'}</td>
                        <td className={'sa-table-data'}>Rs. {supply.price}</td>
                        <td className={'sa-table-data'}>{(supply.unit && supply.unit !== "None") ? supply.availableStock + ` ` + supply.unit : supply.availableStock}</td>
                        <td className={'sa-table-data'}>{supply.expectedHarvestingDate}</td>
                        <td className="sa-table-item-align">
                        <div className={'sa-table-action-flex'}>
                          <div className="sa-table-actions sa-action-m" data-toggle="tooltip" title={t("button.PHONE_CALL")}
                             onClick={() => {
                              setIsMatchCall(true);
                              Object.keys(errors).forEach(k => errors[k] = "");
                              values.id = supply.id;
                              setIsRequestViewVisible(false);
                              setFormErr("");
                              setSelectedData(supply);
                             }}><FeatherIcon className={`table-action ${supply.supplyStatus === "SUPPLY_CONFIRMED" ? "text-green" : null}`}
                                             icon={"phone-call"}/></div>
                          <button className={`sa-popup-secondary-btn-style ${viewData.demandStatus === "DEMAND_CONFIRMED" && supply.supplyStatus === "SUPPLY_CONFIRMED" ? '' : 'disable-btn'}`}
                            style={{width:"120px"}}
                            type="button"
                            onClick={() => {setIsMatchOrderVisible(true);
                              setIsRequestViewVisible(false);
                              setSelectedData(supply);
                              setFarmId(supply.farmId);
                              setBuyerId(viewData.buyerId);
                            }} disabled={supply.supplyStatus !== "SUPPLY_CONFIRMED" || viewData.demandStatus !== "DEMAND_CONFIRMED"}
                            >{t("button.CREATE_ORDER")}</button>
                          </div>
                        </td>
                        </tr>
                      ))}
                      {matchData && matchData.length === 0 && (
                        <tr>
                        <td colSpan="8">
                          <div className={"empty-results"} style={{padding:'5px'}}>
                            <FeatherIcon icon="info" />
                            <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_MATCH_MAKING")}</div>
                          </div>
                        </td>
                      </tr>)}
                    </tbody>
                    </table>
                  </div>

                  <div className="table-whole">
                    <p style={{color: 'red'}}>{t("title.CREATED_ORDERS")}<span className="not-interest-line"></span></p>
                    <table className="table table-borderless sa-table-width">
                      <thead>
                      <tr>
                        <th className={"sa-table-head-sticky"}>{t("title.ORDER_ID")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.ORDER_STATUS")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.SUPPLIER")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.PRICE")}{`(per kg)`}</th>
                        <th className="sa-table-head-sticky">{t("title.QUANTITY")}</th>
                        <th className={"sa-table-head-sticky"}>{t("title.EXPECTED_HARVESTING_DATE")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {createdOrderData && createdOrderData.length > 0 && createdOrderData.map((order, index) => (
                        <tr key={index}>
                          <td className={'sa-table-data'}>{order.orderId}</td>
                          <td className={'sa-table-data'}>{formatDisplayEnumValue(order.requestStatus)}</td>
                          <td className={'sa-table-data'}>{order.supplierInfo.lastName}</td>
                          <td className={'sa-table-data'}>Rs. {order.price}</td>
                          <td className={'sa-table-data'}>{(order.unit && order.unit !== "None") ? order.quantity + ` ` + order.unit : order.quantity}</td>
                          <td className={'sa-table-data'}>{order.interestDeadline}</td>
                        </tr>
                      ))}
                      {createdOrderData && createdOrderData.length === 0 && (
                        <tr>
                          <td colSpan="8">
                            <div className={"empty-results"} style={{padding: '5px'}}>
                              <FeatherIcon icon="info"/>
                              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_CREATED_ORDER")}</div>
                            </div>
                          </td>
                        </tr>)}
                      </tbody>
                    </table>
                  </div>
                  </>
                )}
            </div>
            {isRequestViewVisible && (
             <div className="sa-popup-btn">
                <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" style={{border: '1px solid #aaa'}}
                  onClick={() => setIsRequestViewVisible(false)}>{t("button.CANCEL")}
                </button>
             </div>
            )}
          </form>
        </div>
      </div>

      <div className={"sa-popup-bg " + (!isInfoVisible && 'hide')}>
        <div className="sa-popup">
          <form className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.DEMAND_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={handleClose}>
                <FeatherIcon
                  className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            {selectedDemand && (
              <div className="sa-popup-content">
                <div className="col-md-6">
                  <div className="content-view">
                  <label>{t("title.NEEDED_DEADLINE")}</label>
                  <div className="view-data">{selectedDemand.neededDeadline}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="content-view">
                  <label>{t("title.PRICE")}</label>
                  <div className="view-data">Rs. {selectedDemand.price}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="content-view">
                  <label>{t("title.QUALITY_PARAMETERS")}</label>
                  <div className="view-data">{(selectedDemand.qualityParameters ? selectedDemand.qualityParameters : "NA")}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="content-view">
                  <label>{t("title.DELIVERY_PREFERENCES")}</label>
                  <div className="view-data">{(selectedDemand.deliveryPreferences ? formatDisplayEnumValue(selectedDemand.deliveryPreferences) : "NA")}</div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="content-view">
                  <label>{t("title.ADD_NOTES")}</label>
                  <textarea  className="view-data" style={{minHeight:'100px', width:'100%'}}>{(selectedDemand.addNote ? selectedDemand.addNote : "NA")}</textarea>
                  </div>
                </div>
              </div>
            )}

            <div className="sa-popup-btn">
              <button type={"button"} className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" style={{border: '1px solid #aaa'}}
                onClick={() => setIsInfoVisible(false)}>{t("button.CANCEL")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/*-----------------------------------------------------------------View AuditLogPopup Details---------------------------------------------------            */}
      {selectedItem && <div className="sa-popup-bg ">
        <div className="sa-popup">
          <form noValidate className={'sa-modal-box-style'}>
            <div className="sa-popup-header">
              <span
                className={'sa-model-heading'}>{t("title.VIEW_AUDIT_LOG_DETAILS")}</span>
              <div className="sa-popup-close-icon" onClick={() => {
                setSelectedItem(null)
              }}><FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <div className="sa-popup-content">
              {Object.entries(selectedItem).map(([key, value]) =>
                (<div className="col-md-6" key={key + "archive"}>
                  <div className="form-group m-b-16" style={{"display":"grid"}}>
                    <label className={"labelSize"}>{camelToReadable(key)} </label>
                    <label className="lable-design">
                      <label className="labelSize1 limit-charater-xindicateViewLabel"
                             title={getValue(key)}>{getValue(key)}</label></label>
                  </div>
                </div>)
              )}
            </div>
          </form>
        </div>
      </div>}

      {isCallVisible && (
          <DemandCallHistory isCallVisible={isCallVisible}
            handleClose={handleClose}
            demandID={values.demandID}
            reUpdate={fetchDemands}
            selectedData={selectedData}
            />
        )}
        {isRequestCall && (
          <DemandRequestCall isRequestCall={isRequestCall}
          handleClose={() => {
            setIsRequestCall(false);
            setIsRequestViewVisible(true);
          }}
            requestId={values.id}
            viewData={viewData}
            reUpdate={fetchInterestRequest}
            selectedData={selectedData}
          />
      )}
      {isMatchCall && (
          <SupplyCallHistory isCallVisible={isMatchCall}
          handleClose={() => {
            setIsMatchCall(false);
            setIsRequestViewVisible(true);
          }}
            supplyID={values.id}
            reUpdate={fetchMatchData}
            selectedData={selectedData}
          />
      )}
      {isInterestOrderVisible && (
          <DemandCreateOrderDetail isInterestOrderVisible={isInterestOrderVisible}
          handleClose={() => {
            setIsInterestOrderVisible(false);
            setIsRequestViewVisible(true);
          }}
            viewData={viewData}
            handleCreateOrder={handleCreateOrder}
            selectedData={selectedData}
            selectedSupplierData={selectedSupplierData}
            buyerData={buyer}
          />
      )}
      {isMatchOrderVisible && (
          <DemandCreateOrderDetail isMatchOrderVisible={isMatchOrderVisible}
          handleClose={() => {
            setIsMatchOrderVisible(false);
            setIsRequestViewVisible(true);
          }}
            viewData={viewData}
            handleCreateOrder={handleCreateMatchOrder}
            selectedData={selectedData}
            selectedSupplierData={selectedSupplierData}
            buyerData={buyer}
          />
      )}
    </div>
  )
};
export default DemandRequests
