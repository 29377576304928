import React from "react";
import { useTranslation } from "react-i18next";
import cropimage from "../../../../src/images/image.png";
import FeatherIcon from "feather-icons-react";
import { isAdminUser } from "../../../shared/utils/SharedAuthentication";

const FarmerViewPage = ({ recommendations = { irrigation: [], rainfed: [] }, activeTab = "irrigation", setActiveTab, setIsFarmerView }) => {
  const { t } = useTranslation(); 
  const tableStyle = {
    width: "100%",
    borderCollapse: "separate",
    textAlign: "center",
    fontSize: "16px",
    padding:"0px",
    border:"none"    
  };
  

  return (
    <div className="mt-4 p-4">
      {isAdminUser() ? <span className="fertilizer-title">
        <button type="button" className="bg-none" onClick={() => setIsFarmerView(false)}>
         <FeatherIcon icon = {'arrow-left'} style={{ width: "24px", height: "24px" }}/> 
        </button>
        {t("title.FARMER_VIEW_FERTILIZER_RECOMMENDATION")}
      </span> : <span className="fertilizer-title">{t("title.FERTILIZER_RECOMMENDATION")}</span>}

      
      <div className="flex border-gray mt-4">
        <button
          className={`bg-none ${activeTab === "irrigation" ? "text-green border-bottom-green" : "text-gray"}`}
          onClick={() => setActiveTab("irrigation")}
        >
          {t("button.UNDER_MANUAL_IRRIGATION")}
        </button>
        <button
          className={`bg-none ${activeTab === "rainfed" ? "text-green border-bottom-green" : "text-gray"}`}
          onClick={() => setActiveTab("rainfed")}
        >
          {t("button.UNDER_RAINFED")}
        </button>
      </div>

      
      {recommendations[activeTab]?.filter(crop => crop.props?.formDataState?.cropName)?.length > 0  ? (        
        recommendations[activeTab].map((crop, index) => (            
          <div key={index} className="mt-4 p-4" style={{background:"#F7F7F7", border:"none"}}>
            <div className="flex justify-content-between gap-mb-24 mb-4">
                {console.log("recommendation in farmer view",crop)}
                <div style={{"fontWeight":"700", "fontSize":"20px"}}>
                    <span>
                        <img src={crop.props.formDataState.image? crop.props.formDataState.image: cropimage} style= {{"width":'48px', "marginRight":"12px"}}/>
                        {`${crop.props.formDataState.cropName}  `}
                    </span>
                    <i>{crop.props.formDataState.scientificName ? ` - ${crop.props.formDataState.scientificName}`:""}</i>
                </div>
                <div style={{"fontWeight":"600", "fontSize":"16px", "color":"#626D76"}}>
                <p>{crop.props.formDataState.plantSpacing &&`${t("title.PLANT_SPACING")}: ${crop.props.formDataState.plantSpacing} ${crop.props.formDataState.spacingUnit}`}</p>
                </div>            
            </div>
            <div className="sa-table-container bg-none" style={tableStyle}> 
            <table className="table table-borderless" style={{background:"white"}}>
              <thead>
                <tr className="bg-none">
                  <th className="p-2 border" rowSpan={2}>{t("title.TIME_OF_APPLICATION")}</th>
                  <th className="p-2 border" colSpan={2}>{t("title.INORGANIC_SOURCE")}</th>
                  <th className="p-2 border" colSpan={2}>{t("title.ORGANIC_SOURCE")}</th>
                  
                </tr>
                <tr className="bg-none">
                  <th className="p-2 border">{t("title.NUTRIENT_SOURCES")}</th>
                  <th className="p-2 border">{t("title.RECOMMENDATION")} ({crop.props.formDataState.inorganicUnit})</th>
                  <th className="p-2 border">{t("title.NUTRIENT_SOURCES")}</th>
                  <th className="p-2 border">{t("title.RECOMMENDATION")} ({crop.props.formDataState.organicUnit})</th>
                </tr>
              </thead>              
              <tbody className="bg-none">
                {crop.props.rowsState.map((row, rowIndex) => {
                    let formattedInorganicSources = [];
                    let SourceValue = null;

                    row.inorganicSources.forEach(source => {
                    if (source.type === source.nutrient_content) {
                        SourceValue = source.value;
                    } else {
                        let bracketValue = SourceValue ? `(${SourceValue})` : "";
                        let entry = {
                        type: source.type,
                        nutrient_content: source.nutrient_content,
                        value: source.value,
                        bracketValue: bracketValue,
                        };

                        formattedInorganicSources.push(entry);
                        SourceValue = null; 
                    }
                    });

                    const maxRows = Math.max(formattedInorganicSources.length, row.organicSources.length);

                    return Array.from({ length: maxRows }).map((_, i) => (
                    <tr key={`${rowIndex}-${i}`} className="border bg-none">
                        {i === 0 && <td className="p-2 border sa-table-data" style={{"fontSize":"16px"}} rowSpan={maxRows}>{row.time.replace(/DAP/g, "Days After Planting (DAP)")
                        .replace(/WAP/g, "Weeks After Planting (WAP)")
                        .replace(/DBP/g, "Days Before Planting (DBP)")}</td>}
                        
                        
                        <td className="p-2 border sa-table-data" style={{"fontSize":"16px"}}>
                        {formattedInorganicSources[i] ? `${formattedInorganicSources[i].type} (${formattedInorganicSources[i].nutrient_content})` : "-"}
                        </td>
                        
                        
                        <td className="p-2 border sa-table-data" style={{"fontSize":"16px"}}>
                        {formattedInorganicSources[i] ? `${formattedInorganicSources[i].value} ${formattedInorganicSources[i].bracketValue}` : "-"}
                        </td>

                        
                        <td className="p-2 border sa-table-data" style={{"fontSize":"16px"}}>{row.organicSources[i]?.type || ""}</td>
                        <td className="p-2 border sa-table-data" style={{"fontSize":"16px"}}>{row.organicSources[i]?.value || ""}</td>
                    </tr>
                    ));
                })}
                </tbody>

            </table>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center mt-4">
        <FeatherIcon icon={"info"} className="text-gray" />
        <p style={{"fontWeight":"700px"}}>            
        {t("emptyMsg.THERE_ARE_NO_FERTILIZER_RECOMMENDATION")}
        </p>
        </div>        
      )}
    </div>
  );
};

export default FarmerViewPage;



