import React, {useEffect, useState} from 'react';
import {compose, withProps} from "recompose"
import {GoogleMap, InfoWindow, Marker, OverlayView, Polygon, withGoogleMap, withScriptjs} from "react-google-maps"
import {useDispatch, useSelector} from "react-redux";
import mapStyle from "./mapStyle";
import {useHistory} from "react-router-dom";
import {getSelectedPlot} from "../actions/plot";
import {MODULE, TOGGLE, VIEW} from "../utils/Enum";
import {toCapsFirst} from "../utils/Utils";
import CustomOverlayView from "../utils/CustomOverlayView";
import {getSelectedFarm} from "../actions/farm";
import {getFindLocation, getSelectedCoordinate, getSelectedSensor} from "../actions/sensor";
import pestImg from "../images/icons/pest.svg";
import hammerImg from "../images/icons/hammer.svg";
import valveIcon from '../images/valve_with_bg.svg';
import selectedValveIcon from '../images/valve_with_blue_bg.svg';
import selectImg from '../images/select_sensor.svg';
import deviceImg from '../images/device_white_bg.svg';
import dot from '../images/icons/dot.svg'
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import {isEmpty} from "../shared/utils/utils";

const MapComponent = compose(withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyA9uKFEIqgW63abTdvYTqb3gS6r-60FRpI&v=3.exp&libraries=geometry,drawing,places,visualization",
    loadingElement: <div style={{height: `100%`}}/>,
    google: window.google
  }),
  withScriptjs,
  withGoogleMap,
)((props) => {
  const dispatch = useDispatch();
  // const [farmSources, setFarmSources] = useState({});
  let history = useHistory();
  const [map, setMap] = useState(null);
  const slBound = [{"lat": 5.928470, "lng": 80.561743},
    {"lat": 9.833120, "lng": 80.242329}];

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  const farmList = useSelector(state => {
    return state.farmList.farmList
  });

  const farmActuators = useSelector(state => {
    return state.farmList.farmActuators
  });

  const selectedActuator = useSelector(state => {
    return state.farmList.selectedActuator
  });

  const selectedSensor = useSelector((state) => {
    return state.sensor.selectedSensor;
  });

  const view = useSelector(state => {
    return state.setting.view
  });

  const plotList = useSelector(state => {
    return state.plotList.plotList
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  const selectedPlot = useSelector(state => {
    return state.plotList.selectedPlot;
  });

  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  const clusters = useSelector(state => {
    return state.sensor.clusters;
  });

  const findDeviceLocation = useSelector(state => {
    return state.sensor.findLocation;
  });

  const selectedFarmScout = useSelector((state) => {
    return state.farmList.selectedFarmScout;
  });

  const farmModules = useSelector(state => {
    return state.modules.farmModules;
  });

  useEffect(() => {
    if (!map || !selectedFarm.id) {
      return;
    }
    fitBounds(selectedFarm.coordinates);
  }, [selectedFarm, map]);

  useEffect(() => {
    if (!map || view !== VIEW.FARM_LIST) {
      return;
    }
    fitBounds(slBound);
  }, [map, view]);

  useEffect(() => {
    if (!map || view !== VIEW.SENSOR_LIST || !selectedPlot.id) {
      return;
    }
    fitBounds(selectedPlot.coordinates);
  }, [map, view, selectedPlot]);

  function goToSensor(plot, e) {
    if (!findDeviceLocation) {
      if (selectedPlot.id === plot.id) {
        history.push('/' + selectedFarm.id + '/plot/' + plot.id + '/sensor');
      }
      dispatch(getSelectedPlot(plot));
    } else {
      dispatch(getSelectedCoordinate(e));
      dispatch(getFindLocation(false));
    }
  }

  function getCoordinate(e) {
    if (findDeviceLocation) {
      dispatch(getSelectedCoordinate(e));
      dispatch(getFindLocation(false));
    }
  }

  function farmClick(farm, e) {
    if (findDeviceLocation) {
      dispatch(getSelectedCoordinate(e));
      dispatch(getFindLocation(false));
    }
    if (view !== VIEW.FARM_LIST) {
      return;
    }
    if (selectedFarm.id === farm.id) {
      history.push(farm.id + "/plot");
    }
    dispatch(getSelectedFarm(farm));
  }

  function fitBounds(coordinates) {
    if (!coordinates) {
      return;
    }
    const google = window.google;
    const bounds = new google.maps.LatLngBounds();

    coordinates.map((hotel, i) => {
      const position = new google.maps.LatLng(hotel.lat, hotel.lng);
      bounds.extend(position);
    });

    map.fitBounds(bounds);
  }

  function findingTopOfPolygon(coordinates) {
    if (!coordinates) {
      return {
        lat: 6.885047,
        lng: 79.855808
      };
    }
    let max = -180;
    let labelCoordinate = {};
    coordinates.forEach(coordinate => {
      if (coordinate.lat > max) {
        max = coordinate.lat;
        labelCoordinate = coordinate;
      }
    });

    return labelCoordinate;
  }


  function getCenterPoint(coordinates) {
    if (!coordinates) {
      return {
        lat: 6.885047,
        lng: 79.855808
      };
    }
    let x = 0;
    let y = 0;
    let j = coordinates.length - 1;
    let f;
    let area = 0;
    for (let i = 0; i < coordinates.length; j = i, i++) {
      const point1 = coordinates[i];
      const point2 = coordinates[j];
      f = point1.lat * point2.lng - point2.lat * point1.lng;
      x += (point1.lat + point2.lat) * f;
      y += (point1.lng + point2.lng) * f;
      area += point1.lat * point2.lng;
      area -= point1.lng * point2.lat;
    }
    area /= 2;
    f = area * 6;
    return {
      lat: x / f,
      lng: y / f
    };
  }

  // function goToSensor(plot) {
  //     if ( selectedPlot.id === plot.id) {
  //         history.push('/' + selectedFarm.id + '/plot/' + plot.id + '/sensor');
  //     }
  //     dispatch(getSelectedPlot(plot));
  // }

  function moduleFilter(farm) {
    if (isEmpty(farmModules) || !farmModules[farm.id]) {
      return false
    }
    return farmModules[farm.id][MODULE.SMART_FARM];
  }

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  function sensorClick(index) {
    dispatch(getSelectedSensor(index));
  }

  useEffect(() => {
    if (!window.location.href) {
      return;
    }
    dispatch(getSelectedSensor(-1));
    dispatch(getFindLocation(false));
  }, [window.location.href]);

  function zoomChanged() {

  }

  return (

    <GoogleMap
      ref={mapRef => {
        setMap(mapRef);
      }}
      defaultCenter={{lat: -34.397, lng: 150.644}}
      onZoomChanged={zoomChanged}
      // center={plotList ? (selectedFarm ? getCenterPoint(selectedFarm.coordinates) : farmCoordinate) :
      //   (selectedPlot ? (getCenterPoint(selectedPlot.coordinates)) : (getCenterPoint(selectedFarm.coordinates)))}
      defaultOptions={{styles: mapStyle, mapTypeControl: false, fullscreenControl: false}}>
      <MarkerClusterer

        averageCenter
        enableRetinaIcons
        gridSize={60}
      >
        {farmList && (farmList.filter(farm => moduleFilter(farm)).map((farm, index) =>
          <Marker
            key={index}
            opacity={0}
            icon={dot}
            title={farm.name}
            position={findingTopOfPolygon(farm.coordinates)}
          >
            <InfoWindow
              position={findingTopOfPolygon(farm.coordinates)}
              options={{width: '15px'}}>
              <div style={{fontSize: "14px"}} className="farm-label-box sa-cursor"
                   onClick={() => farmClick(farm)}>{toCapsFirst(farm.name)}</div>
            </InfoWindow>
            <Polygon
              onClick={(e) => farmClick(farm, e)}
              path={farm.coordinates}
              key={index}
              editable={false}
              options={{
                fillColor: (selectedFarm.id === farm.id && plotList.length === 0) ? "#22C6877B" : "#A3A3A37A",
                fillOpacity: plotList.length === 0 ? 1 : 0.5,
                strokeColor: (selectedFarm.id === farm.id && plotList.length === 0) ? "#22C6877B" : "#A3A3A37A",
                strokeOpacity: plotList.length === 0 ? 1 : 0.5,
                strokeWeight: 1,
              }}
            />
          </Marker>
        ))}
      </MarkerClusterer>

      <div className={toggle === TOGGLE.LIST ? "empty-top-map" : ""}></div>


      {selectedFarm.id && farmList.length === 0 && (
        <div style={{background: "green"}} key={'farm' + selectedFarm.id}>
          <InfoWindow position={findingTopOfPolygon(selectedFarm.coordinates)} options={{
            width: '15px',
          }}>
            <div className="farm-label-box"><b
              style={{fontSize: "16px"}}>{selectedFarm.name}</b></div>
          </InfoWindow>
          <Polygon
            onClick={(e) => getCoordinate(e)}
            path={selectedFarm.coordinates}
            key={"pg" + selectedFarm.id}
            editable={false}
            options={{
              fillColor: (plotList.length === 0) ? "#22C6877B" : "#A3A3A37A",
              fillOpacity: plotList.length === 0 ? 1 : 0.5,
              strokeColor: (plotList.length === 0) ? "#22C6877B" : "#A3A3A37A",
              strokeOpacity: plotList.length === 0 ? 1 : 0.5,
              strokeWeight: 1,
            }}
          />
        </div>
      )
      }

      {selectedFarm.id && farmList.length === 0 && (
        <div style={{background: "green"}} key={'farm' + selectedFarm.id}>
          <InfoWindow position={findingTopOfPolygon(selectedFarm.coordinates)} options={{
            width: '15px',
          }}>
            <div className="farm-label-box"><b
              style={{fontSize: "16px"}}>{selectedFarm.name}</b></div>
          </InfoWindow>
          <Polygon
            onClick={(e) => getCoordinate(e)}
            path={selectedFarm.coordinates}
            key={"pg" + selectedFarm.id}
            editable={false}
            options={{
              fillColor: (selectedPlot.length === 0) ? "#22C6877B" : "#A3A3A37A",
              fillOpacity: (selectedPlot.length === 0) ? 1 : 0.5,
              strokeColor: (selectedPlot.length === 0) ? "#22C6877B" : "#A3A3A37A",
              strokeOpacity: (selectedPlot.length === 0) ? 1 : 0.5,
              strokeWeight: 1,
            }}
          />
        </div>
      )
      }
      {plotList.length > 0 && (plotList.filter(plot => plot.coordinates).map((plot, index) =>
        <div key={'plot' + index}>
          <CustomOverlayView
            position={getCenterPoint(plot.coordinates)}
            /*
             * An alternative to specifying position is specifying bounds.
             * bounds can either be an instance of google.maps.LatLngBounds
             * or an object in the following format:
             * bounds={{
             *    ne: { lat: 62.400471, lng: -150.005608 },
             *    sw: { lat: 62.281819, lng: -150.287132 }
             * }}
             */
            /*
             * 1. Specify the pane the OverlayView will be rendered to. For
             *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
             *    Defaults to `OverlayView.OVERLAY_LAYER`.
             */
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            /*
             * 2. Tweak the OverlayView's pixel position. In this case, we're
             *    centering the content.
             */
            getPixelPositionOffset={getPixelPositionOffset}
            /*
             * 3. Create OverlayView content using standard React components.
             */
          >
            <div className={"plot-label"}>
              {plot.name}
            </div>
          </CustomOverlayView>
          <Polygon
            path={plot.coordinates}
            key={index}
            editable={false}
            options={{
              geodesic: true,
              fillColor: selectedPlot.id === plot.id ? "#22C6877A" : "#A3A3A37A",
              fillOpacity: 1,
              strokeColor: selectedPlot.id === plot.id ? "#22C687" : "#50565FA3",
              strokeOpacity: 1,
              strokeWeight: 1,
              zIndex: 1000,
            }}
            onClick={(e) => goToSensor(plot, e)}
          />
        </div>
      ))
      }

      {view === VIEW.SENSOR_LIST && clusters.length > 0 && clusters.map((cluster, index) =>
        <Marker key={index} onClick={() => {
          sensorClick(index)
        }}
                position={cluster[0].coordinate} title={"Sensor " + (index + 1)}
                icon={selectedSensor === index ? selectImg : deviceImg}
        />
      )}

      {view === VIEW.PLOT_LIST && selectedActuator && farmActuators.length > 0 && farmActuators.filter((item) => item.id !== selectedActuator.id).map((item, index) =>
        <Marker key={index}
                position={item.coordinate}
                title={item.kitId + " [" + item.actuatorNumber + "]"}
                icon={valveIcon}
        />
      )}

      {view === VIEW.PLOT_LIST && selectedActuator && (
        <Marker 
                position={{lat: selectedActuator.lat, lng: selectedActuator.lng}}
                title={selectedActuator.kitId + " [" + selectedActuator.actuatorNumber + "]"}
                icon={selectedValveIcon}
        />
      )}

      {
        view === VIEW.PLOT_LIST &&
        selectedFarmScout.length > 0 &&
        selectedFarmScout.map((item, index) => (
          <Marker
            key={index}
            position={item.coordinate}
            title={item.note}
            icon={((item.category) === "PEST") ? pestImg : hammerImg}
          />
        ))}


    </GoogleMap>
  )
});
export default MapComponent;
