import React, { useState, useEffect  } from "react";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import cropimage from "../../../../src/images/image.png";
import FormHandler from "../../../shared/utils/FormHandler";
import { getUserId} from "../../../shared/utils/SharedAuthentication";
import axios from "axios";

const CropRecommendation = ({ onUpdate, index, tab, formDataState, rowsState, inorganicEntriesState, organicEntriesState, onDelete}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [organicModalOpen, setOrganicModalOpen] = useState(false);
  const [inorganicModalOpen, setInorganicModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const [cropAdded, setCropAdded] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [organicErrors, setOrganicErrors] = useState({});
  const [inorganicErrors, setInorganicErrors] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState(formDataState || {
    cropName: "",
    scientificName: "",
    plantSpacing: "",
    spacingUnit: "",
    organicUnit: "",
    inorganicUnit: "",
    image:""
  });
  const resetForm = () => {
    initForm({
      cropName: "",
      scientificName: "",
      plantSpacing: "",
      spacingUnit: "",
      organicUnit: "",
      inorganicUnit: "",
      image: ""
    });
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0",
    fontSize: "16px",
    padding:"0px",
    border:"none"

  };
  
  const cellStyle = {
    minWidth: "100px",
    textAlign: "center",
    padding: "6px",
  };

  const modalInFront = {
    zIndex: 1020, 
};

  const [organicTypes, setOrganicTypes] = useState([{ type: "", unit: "" }]);
  const [inorganicTypes, setInorganicTypes] = useState([{ type: "", unit: "" }]);
  const [inorganicEntries, setInorganicEntries] = useState(inorganicEntriesState || []);
  const [inorganicData, setInorganicData] = useState({ name: "", types: [""] });
  const [timeModalOpen, setTimeModalOpen] = useState(false);
  const [timeData, setTimeData] = useState({ dates: "", unit: "" });
  const [selectedTimeRowId, setSelectedTimeRowId] = useState(null);
  const [organicEntries, setOrganicEntries] = useState(organicEntriesState || []);
  const [isEditing, setIsEditing] = useState({});
  

  const handleEditRow = (index) => {
    setIsEditing((prev) => ({ ...prev, [index]: true }));
  };
  const handleSaveEdit = (index) => {
    setIsEditing((prev) => ({ ...prev, [index]: false })); 
  };

  useEffect(() => {
    if (formDataState) {
      initForm(formDataState);  
    }
  }, [formDataState]);
  

    const handleCropSave = async () => {
        const validationErrors = validateCropForm(values); // Validate fields before submitting

        if (Object.keys(validationErrors).length > 0) {
            toast.error("Please fill all required fields.");
            return;
        }

        setFormData({ ...values });
        try{
            const userId = getUserId();
            const response = await axios.get(`${process.env.REACT_APP_HOST}/user/${userId}/cropAgronomy/${formData.cropName}`);
            if(response.data){
                const imageUrl = response.data.content.image;
                if(imageUrl){
                    setFormData({ ...values, image: imageUrl });
                }
            }
        }catch(error){
            console.log(error);
        }

        toast.success("Crop details updated successfully");
        setModalOpen(false);
        setCropAdded(true);
    };


  const validateCropForm = (values) => {
    let errors = {};
    if (!values.cropName) {
      errors.cropName = "Crop name is required";
    }
    if (!values.plantSpacing) {
      errors.plantSpacing = "Plant spacing is required";
    }
    if (!values.spacingUnit) {
      errors.spacingUnit = "Spacing unit is required";
    }
    return errors;
  };
  
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleOnBlur,
    setValue,
    initForm,
} = FormHandler(handleCropSave, validateCropForm);  
  
    const [rows, setRows] = useState(
        Array.isArray(rowsState) && rowsState.length > 0
        ? rowsState.map(row => ({
            id: row.id || 1,
            time: row.time || "",
            organicSources: Array.isArray(row.organicSources) ? row.organicSources : [],
            inorganicSources: Array.isArray(row.inorganicSources) ? row.inorganicSources : [],
            }))
        : [{ id: 1, time: "", organicSources: [], inorganicSources: [] }]
    );

useEffect(() => {
    if (formDataState && formDataState.cropName) {
        setCropAdded(true);
    }
}, [formDataState]);



useEffect(() => {
    if (organicEntriesState && organicEntriesState.length > 0) {
        setOrganicEntries(organicEntriesState);
    }
}, [organicEntriesState]);

  

useEffect(() => {
    if (onUpdate) {
        onUpdate({ 
            formData, 
            rows, 
            inorganicEntries, 
            organicEntries 
        }, index, tab);
    }
}, [formData, rows, inorganicEntries, organicEntries]); 



  const handleAddOrganicType = () => {
    setOrganicTypes([...organicTypes, ""]);
  };


  const handleAddInorganicType = () => {
    if (inorganicTypes.length < 2) {
        setInorganicTypes([...inorganicTypes, ""]);
    }
  };

    
  const handleAddInorganicData = () => {
    setInorganicData([...inorganicData, ""]);
  };

  const handleOrganicTypeChange = (index, value) => {
    const newTypes = [...organicTypes];
    newTypes[index] = value;
    setOrganicTypes(newTypes);
    if (value.trim() !== "") {
        setOrganicErrors((prevErrors) => ({ ...prevErrors, types: "" }));
    }
  };

  const handleInorganicNameChange = (e) => {
    const { value } = e.target;
    setInorganicData({ ...inorganicData, name: value });

    // Clear error when valid data is entered
    if (value.trim() !== "") {
        setInorganicErrors((prevErrors) => ({ ...prevErrors, name: "" }));
    }
};


  const handleInorganicTypeChange = (index, value) => {
    const newTypes = [...inorganicTypes];
    newTypes[index] = value;
    setInorganicTypes(newTypes);

    if (value.trim() !== "") {
        setInorganicErrors((prevErrors) => ({ ...prevErrors, types: "" }));
    }
  };
  
  const handleRemoveOrganicType = (index) => {
    const updatedTypes = organicTypes.filter((_, i) => i !== index);
    setOrganicTypes(updatedTypes);

    
    setRows(rows.map(row => ({
        ...row,
        organicSources: row.organicSources.filter(source =>
            source.type !== organicTypes[index]
        )
    })));
};

const handleRemoveInorganicType = (index) => {
    const updatedTypes = inorganicTypes.filter((_, i) => i !== index);
    setInorganicTypes(updatedTypes);    
    setRows(rows.map(row => ({
        ...row,
        inorganicSources: row.inorganicSources.filter(source =>
            source.type !== inorganicTypes[index]
        )
    })));
};

    const handleRemoveInorganicData = (index) => {
        setInorganicData(inorganicData.filter((_, i) => i !== index));
    }; 

    const handleSaveAndContinue = () => {
        if (!inorganicData.name.trim()) {
            toast.error("Inorganic name is required.");
            return;
        }
    
        const validTypes = inorganicTypes.filter(type => type.trim() !== "");
    
        if (validTypes.length === 0) {
            toast.error("At least one type of inorganic is required.");
            return;
        }
    
        const newEntry = { name: inorganicData.name, types: validTypes };
    
        setInorganicEntries(prevEntries => [...prevEntries, newEntry]);
    
        setInorganicData({ name: "", types: [""] }); 
        setInorganicTypes([""]); 
    
        setInorganicModalOpen(false);
        setTimeout(() => setInorganicModalOpen(true), 10);
    
        toast.success(`"${newEntry.name}" added! You can add another.`);
    };   
             
    
    const handleSaveOrganic = () => {
        const errors = {};        
        const validTypes = organicTypes.filter(type => typeof type === "string" && type.trim() !== "");
        
        if (validTypes.length === 0) {
            errors.types = "At least one organic type is required.";
            setOrganicErrors(errors);
            return;
        }

        setOrganicErrors({});
    
        const newOrganicEntries = [
            ...organicEntries,
            { types: validTypes }
        ];
        setOrganicEntries(newOrganicEntries);
    
        setRows(rows.map(row => ({
            ...row,
            organicSources: newOrganicEntries.flatMap(entry =>
                entry.types.map(type => ({
                    type,
                    value: ""
                }))
            )
        })));
    
        setOrganicModalOpen(false); 
        setOrganicTypes([""]);

    };
    
      
    
    

    const handleSaveInorganic = () => {
        const errors = {};

        if (!inorganicData.name.trim()) {
            errors.name = "Inorganic name is required.";
            setInorganicErrors(errors);            
            return;
        }
    
        const validTypes = inorganicTypes.filter(type => typeof type === "string" && type.trim() !== "");
    
        if (validTypes.length === 0) {
            errors.types = "At least one inorganic type is required.";
            setInorganicErrors(errors);
            return;
        }      
        setInorganicErrors({});    
        
        const existingValuesMap = {};
        rows.forEach(row => {
            row.inorganicSources.forEach(source => {
                existingValuesMap[`${row.id}-${source.type}`] = source.value; 
            });
        });
    
        
        const newEntry = { name: inorganicData.name, types: validTypes };    
        
        const updatedInorganicEntries = [...inorganicEntries, newEntry];    
        
        const updatedRows = rows.map(row => ({
            ...row,
            inorganicSources: updatedInorganicEntries.flatMap(entry =>
                entry.types.map(type => ({
                    type,
                    value: existingValuesMap[`${row.id}-${type}`] || "" 
                }))
            )
        }));
    
        setInorganicEntries(updatedInorganicEntries);
        setRows(updatedRows);
        
       
        setEditedInorganicEntries(updatedInorganicEntries); 
    
        setInorganicModalOpen(false); 
    };   
    
    const maxOrganicSources = organicEntries.reduce((sum, entry) => sum + entry.types.length, 0) || 1;
    const maxInorganicSources = inorganicEntries.reduce((sum, entry) => sum + entry.types.length, 0) || 1;
    const [editOrganicModalOpen, setEditOrganicModalOpen] = useState(false);
    const [editedOrganicTypes, setEditedOrganicTypes] = useState([]);
    const [editInorganicModalOpen, setEditInorganicModalOpen] = useState(false);
    const [editedInorganicEntries, setEditedInorganicEntries] = useState([]);

    const handleOpenEditOrganic = () => {
        const currentOrganicTypes = rows[0].organicSources.map(source => source.type); 
        setEditedOrganicTypes(currentOrganicTypes);
        setEditOrganicModalOpen(true);
    };   

    const handleDeleteInorganicRow = (index) => {
        const updatedEntries = editedInorganicEntries.filter((_, i) => i !== index);
        setEditedInorganicEntries(updatedEntries);
    
        
        setRows(rows.map(row => ({
            ...row,
            inorganicSources: row.inorganicSources.filter(source =>
                !editedInorganicEntries[index].types.includes(source.type)
            )
        })));
    };
      
    const handleOpenAddInorganic = () => {
        setInorganicData({ name: "", types: [""] }); 
        setInorganicTypes([""]); 
        setTimeout(() => setInorganicModalOpen(true), 100); 
    };
    
    const handleOpenEditInorganic = () => {
        setEditedInorganicEntries([...inorganicEntries]); 
        setEditInorganicModalOpen(true);
    };

    const handleEditInorganicRow = (index) => {
        const newEntries = [...editedInorganicEntries];
        const newName = prompt("Edit Inorganic Name:", newEntries[index].name);
        if (newName) {
            newEntries[index].name = newName;
            setEditedInorganicEntries(newEntries);
        }
    };
    
    
    const handleUpdateOrganic = () => {
        
        const existingValuesMap = {};
        rows.forEach(row => {
            row.organicSources.forEach(source => {
                existingValuesMap[`${row.id}-${source.type}`] = source.value; 
            });
        });
    
        
    const updatedOrganicEntries = editedOrganicTypes.map(type => ({ types: [type] }));

    
    const updatedRows = rows.map(row => ({
        ...row,
        organicSources: editedOrganicTypes.map(type => ({
            type,
            value: existingValuesMap[`${row.id}-${type}`] || "" 
        }))
    }));

    setOrganicEntries(updatedOrganicEntries);
    setRows(updatedRows);
    setEditOrganicModalOpen(false); 
    }; 
    

    const handleUpdateInorganic = () => {
        
        const existingValuesMap = {};
        rows.forEach(row => {
            row.inorganicSources.forEach(source => {
                existingValuesMap[`${row.id}-${source.type}`] = source.value; 
            });
        });
    
        
        const updatedInorganicEntries = editedInorganicEntries.map(entry => ({
            name: entry.name,
            types: entry.types.filter(type => type.trim() !== "") // Remove empty types
        })).filter(entry => entry.types.length > 0);
    
        
        const updatedRows = rows.map(row => ({
            ...row,
            inorganicSources: updatedInorganicEntries.flatMap(entry =>
                entry.types.map(type => ({
                    type,
                    value: existingValuesMap[`${row.id}-${type}`] || "" 
                }))
            )
        }));
    
        setInorganicEntries(updatedInorganicEntries);
        setRows(updatedRows);
        setEditInorganicModalOpen(false); 
    };
    
    

    const handleOrganicValueChange = (rowId, typeIndex, value) => {
        setRows(rows.map(row => {
            if (row.id === rowId) {
                const updatedSources = [...row.organicSources];
                updatedSources[typeIndex] = {
                    ...updatedSources[typeIndex],
                    value: value === "" ? "" : String(Number(value)) 
                };
                return { ...row, organicSources: updatedSources };
            }
            return row;
        }));
    };
    
    const handleInorganicValueChange = (rowId, typeIndex, value) => {
        setRows(rows.map(row => {
            if (row.id === rowId) {
                const updatedSources = [...row.inorganicSources];
                updatedSources[typeIndex] = {
                    ...updatedSources[typeIndex],
                    value: value === "" ? "" : String(Number(value)) 
                };
                return { ...row, inorganicSources: updatedSources };
            }
            return row;
        }));
    };
    
    

    const handleAddNewRow = () => {
        const newRow = {
            id: rows.length + 1,
            time: "",
            organicSources: organicEntries.flatMap(entry =>
                entry.types.map(type => ({
                    type,
                    value: "" 
                }))
            ),
            inorganicSources: inorganicEntries.flatMap(entry =>
                entry.types.map(type => ({
                    type,
                    value: "" 
                }))
            ),
        };
    
        setRows([...rows, newRow]);
    };   
      
    
    const handleTimeSelection = () => {
        const validationErrors = validateTimeForm(timeValues);
    
        if (Object.keys(validationErrors).length > 0) {
            toast.error("Please fill all required fields.");
            return;
        }
    
        setRows((prevRows) => {
            const updatedRows = prevRows.map(row => {
                if (row.id === selectedTimeRowId) {
                    return { ...row, time: `${timeValues.dates} ${timeValues.unit}` };
                }
                return row;
            });
    
            if (selectedTimeRowId === prevRows[prevRows.length - 1].id) {
                updatedRows.push({
                    id: prevRows.length + 1,
                    time: "",
                    organicSources: organicEntries.flatMap(entry =>
                        entry.types.map(type => ({ type, value: "" }))
                    ),
                    inorganicSources: inorganicEntries.flatMap(entry =>
                        entry.types.map(type => ({ type, value: "" }))
                    ),
                });
            }
    
            return updatedRows;
        });
    
        setTimeModalOpen(false);
        initTimeForm({ dates: "", unit: "" }); 
    };

    const validateTimeForm = (values) => {
        let errors = {};
        if (!values.dates.trim()) {
            errors.dates = "Time range is required";
        }
        if (!values.unit.trim()) {
            errors.unit = "Time unit is required";
        }
        return errors;
    };

    const {
        values: timeValues,
        errors: timeErrors,
        handleChange: handleTimeChange,
        handleSubmit: handleTimeSubmit,
        handleOnBlur: handleTimeBlur,
        setValue: setTimeValue,
        initForm: initTimeForm,
    } = FormHandler(handleTimeSelection, validateTimeForm);


    return (
    <div>          
        <div className="mt-4 p-4" style={{background:"#F7F7F7", border:"none"}}>                
        {cropAdded && (
        <div style={{textAlign:"right"}}>
            <button className="text-gray bg-none" onClick={() => { 
                setIsEditMode(true);
                setModalOpen(true);
            }}>
                <FeatherIcon icon="edit" style={{ width: "16px", height: "16px" }}/>
            </button>
            <button
                className="text-red bg-none"                
                onClick={onDelete}                
            >
                <FeatherIcon icon="trash" style={{ width: "16px", height: "16px" }} />
            </button>
            </div>
            
           )}
        <div className="flex justify-content-between gap-mb-24 mb-4">  
            
                {cropAdded ? (
                    <>
                    <div style={{"fontWeight":"700", "fontSize":"20px"}}>                        
                        <span>
                        <img src={formData.image? formData.image: cropimage} style= {{"width":'48px', "marginRight":"12px"}} />
                        {formData.cropName}
                        </span>
                        <span>
                            <i>
                            {formData.scientificName ? ` - ${formData.scientificName}` : ""}
                            </i>
                        </span> 
                    </div>
                    <div>                
                    <span style={{"fontWeight":"600", "fontSize":"16px", "color":"#626D76"}}>                        
                        {formData.plantSpacing && `${t("title.PLANT_SPACING")}: ${formData.plantSpacing} ${formData.spacingUnit}`}
                    </span>
                    </div>                             
                </>
                ) : (
                    <span>
                    <button
                        onClick={() => {
                            setModalOpen(true);
                            setCurrentField("crop");
                        }}
                        className="bg-none text-green"
                        style={{"fontWeight":"700", "fontSize":"20px"}}
                    >
                        + 
                    </button>                    
                    {t("title.ADD_CROP_DETAILS")}
                    </span>
                )}
        </div>
        <div className="sa-table-container bg-none" style={tableStyle}>
        <table className="table table-borderless border border-gray" style={{background:"white"}}>
              <thead>
                <tr>
                    <th rowSpan="4" className="border p-2 text-center" style={cellStyle}>{t("title.TIME_OF_APPLICATION")}</th>
                    <th colSpan={maxOrganicSources + maxInorganicSources} className="border p-2 text-center" style={cellStyle}>{t("title.RECOMMENDATION")}</th>
                </tr>
                <tr>
                <th colSpan={maxOrganicSources} className="border p-2 text-center" style={cellStyle}>
                <div className="justify-center items-center relative">
                <div className="justify-center items-center relative" style={{textAlign:"right"}}>
                    {rows[0].organicSources.length > 0 && (                        
                            <button className="absolute top-1 right-1 text-gray bg-none" onClick={handleOpenEditOrganic}>
                                <FeatherIcon icon="edit" style={{ width: "16px", height: "16px" }} />
                            </button>
                    )}
                </div>
                    <button className="text-green items-center gap-2 bg-none"   onClick={() => { setSelectedRowId(rows[0].id); setOrganicErrors({}); setOrganicModalOpen(true); }}>
                        + 
                    </button>
                    <span>{t("title.ORGANIC_SOURCE")} ({formData.organicUnit || "unit"})</span>                  
                    
                </div>
            </th>
             
             <th colSpan={maxInorganicSources} style={cellStyle}>
                <div>
                    <div style={{textAlign:"right"}}>
                    {inorganicEntries.length > 0 && (
                            <button className="text-gray bg-none"
                            onClick={handleOpenEditInorganic}>
                                <FeatherIcon icon="edit" style={{ width: "16px", height: "16px" }}/>
                            </button>
                    )}
                    </div>
                    <button className="text-green bg-none"
                        onClick={() => { setSelectedRowId(rows[0].id); setInorganicErrors({}); 
                        setInorganicData({ name: "", types: [""] }); 
                        setInorganicTypes([""]);
                        setInorganicModalOpen(true);}}>
                        + 
                    </button>
                    <span>{t("title.INORGANIC_SOURCE")} ({formData.inorganicUnit || "unit"})</span>                   
                </div>
            </th>
            </tr>
            
            <tr>
                <th colSpan={maxOrganicSources} className="border p-2 text-center" style={cellStyle}>
                    {t("title.ORGANIC_TYPES")}
                </th>

                
                {inorganicEntries.length > 0 ? (
                    inorganicEntries.map((entry, index) => (
                        <th key={index} colSpan={entry.types.length} className="border p-2 text-center" style={cellStyle}>
                            {entry.name}
                        </th>
                    ))
                ) : (
                    <th colSpan={maxInorganicSources} className="border p-2 text-center" style={cellStyle}>
                        {t("title.NUTRIENT_SOURCES")}
                    </th>
                )}
            </tr>
            <tr>           
                
            {organicEntries.length > 0 ? (
                organicEntries.flatMap((entry, index) =>
                    entry.types.map((type, typeIndex) => (
                        <th key={`org-${index}-${typeIndex}`} className="border p-2 text-center">
                            {type}
                        </th>
                    ))
                )
            ) : (
                <th colSpan={maxOrganicSources} className="border p-2 text-center">
                    {t("title.ORGANIC_TYPE")}
                </th>
            )}

            {inorganicEntries.length > 0 ? (
                    inorganicEntries.flatMap((entry, entryIndex) =>
                        entry.types.map((type, typeIndex) => (
                            <th key={`inorg-${entryIndex}-${typeIndex}`} className="border p-2 text-center">
                                {type}
                            </th>
                        ))
                    )
                ) : (
                    <th colSpan={maxInorganicSources > 0 ? maxInorganicSources : 1} className="border p-2 text-center">
                        {t("title.INORGANIC_TYPE")}
                    </th>
                )}
           </tr>

            </thead> 
              
              <tbody>
            {rows.map((row) => (
                <tr key={row.id} style={{background:"white"}}>
                
                <td className="border p-2" style={{"textAlign":"center",  "fontSize":"16px"}}>
                    {row.time ? (
                    <div className="justify-center items-center gap-2">
                        
                        <div style={{textAlign:"right"}}>
                        <button
                        className="text-gray ml-2 bg-none"
                        onClick={() => {
                            setSelectedTimeRowId(row.id);
                            if (row.time) {
                                const timeParts = row.time.split(" "); 
                                const dates = timeParts.slice(0, -1).join(" "); 
                                const unit = timeParts[timeParts.length - 1] || ""; 
                                setTimeData({ dates, unit });
                                initTimeForm({ dates, unit }); 
                            } else {
                                setTimeData({ dates: "", unit: "" }); 
                                initTimeForm({ dates: "", unit: "" });
                            }
                            setTimeModalOpen(true);
                        }}
                        >
                        <FeatherIcon icon="edit" style={{ width: "16px", height: "16px" }} />
                        </button>
                        </div>
                        <span>{row.time}</span>
                    </div>
                    ) : (
                        <span>
                    <button
                        className="text-green bg-none"
                        
                        onClick={() => {
                            setSelectedTimeRowId(row.id);
                            if (row.time) {
                                const [dates, unit] = row.time.split(" ");
                                initTimeForm({ dates, unit });
                            } else {
                                initTimeForm({ dates: "", unit: "" });
                            }
                            setTimeModalOpen(true);
                        }}
                        style={{"fontWeight":"600", "fontSize":"18px"}}
                    >
                        + 
                    </button>
                    {t("title.SELECT_THE_TIME_OF_APPLICATION")}
                    </span>
                    )}
                </td>

                {/* Organic Source Values */}
                {row.organicSources.length > 0 ? (
                    row.organicSources.map((source, index) => (
                    <td key={`org-${index}`} className="border p-2 text-center">
                        {source.value !== "" ? (
                        <input
                            type="number"
                            className="rounded text-center"
                            style={{"width":"80px", "border":"none",  "fontSize":"16px"}}
                            value={source.value}
                            onChange={(e) =>
                            handleOrganicValueChange(row.id, index, e.target.value)
                            }
                            autoFocus
                        />
                        ) : (
                            <span>
                        <button
                            className="text-green bg-none"
                            onClick={() => handleOrganicValueChange(row.id, index, 0)}
                        >
                            + 
                        </button>
                        Value
                        </span>
                        )}
                    </td>
                    ))
                ) : (
                    <td colSpan={maxOrganicSources || 1} className="border p-2 text-center" style={{"fontSize":"16px"}}>
                    {t("title.NO_ORGANIC_SOURCES")}
                    </td>
                )}

                {/* Inorganic Source Values */}
                {row.inorganicSources.length > 0 ? (
                    row.inorganicSources.map((source, index) => (
                    <td key={`inorg-${index}`} className="border p-2 text-center" style={{"fontSize":"16px"}}>
                        {source.value !== "" ? (
                        <input
                            type="number"
                            className="rounded text-center"
                            style={{"width":"80px", "border":"none"}}
                            value={source.value}
                            onChange={(e) =>
                            handleInorganicValueChange(row.id, index, e.target.value)
                            }
                            autoFocus
                        />
                        ) : (
                            <span>
                        <button
                            className="text-green bg-none"
                            onClick={() => handleInorganicValueChange(row.id, index, 0)}
                        >
                            + 
                        </button>
                        {t("title.VALUE")}
                        </span>
                        )}
                    </td>
                    ))
                ) : (
                    <td
                    colSpan={maxInorganicSources || 1}
                    className="border p-2 text-center"
                    style={{"fontSize":"16px"}}
                    >
                    {t("title.NO_INORGANIC_SOURCES")}
                    </td>
                )}
                </tr>
            ))}
              </tbody>
        </table>
        </div>    
          
        </div>
      {organicModalOpen && (
        <div className="sa-popup-bg">
          <div className="sa-popup">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className="sa-modal-box-style"
            >
              <div className="sa-popup-header">
                <span className="sa-model-heading">{t("title.ORGANIC_SOURCE")}</span>
                <div
                  className="sa-popup-close-icon"
                  onClick={() => setOrganicModalOpen(false)}
                >
                  <FeatherIcon className="sa-modal-close-icon" icon="x" />
                </div>
              </div>
             
              <div className="sa-popup-content">
                <div className="col-md-12">
                  <div className="form-group m-b-16">                    
                    <label>{t("title.TYPE_OF_ORGANIC")}</label>
                    {organicTypes.map((type, index) => (
                      <div key={index} className="flex gap-2 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter the type of organic"
                          value={type.type}                          
                          onChange={(e) => handleOrganicTypeChange(index, e.target.value)}
                        />
                        
                        {organicTypes.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveOrganicType(index)}
                            className="sa-popup-close-icon bg-none"
                          >
                            ✖
                          </button>
                        )}
                      </div>
                      
                    ))}
                    {organicErrors.types && <p className="warning-input-msg">{organicErrors.types}</p>}
               
                    <button
                      type="button"
                      onClick={handleAddOrganicType}
                      className="sa-popup-secondary-btn-style"
                    >
                      {t("button.ADD_NEW_TYPE")}
                    </button>
                  </div>
                </div>
              </div>

              <div className="sa-popup-btn">
                <button
                  type="button"
                  className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                  style={{ marginRight: "8px", border: "1px solid #aaa"}}
                  onClick={() => setOrganicModalOpen(false)}
                >
                  {t("button.CANCEL")}
                </button>
                <button 
                  type="submit" 
                  className="sa-popup-secondary-btn-style"
                  onClick={handleSaveOrganic}
                >
                  {t("button.ADD")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

{editOrganicModalOpen && (
    <div className="sa-popup-bg">
        <div className="sa-popup">
            <form onSubmit={(e) => e.preventDefault()} className="sa-modal-box-style">
                <div className="sa-popup-header">
                    <span className="sa-model-heading">{t("title.EDIT_ORGANIC")}</span>
                    <div className="sa-popup-close-icon" onClick={() => setEditOrganicModalOpen(false)}>
                        <FeatherIcon className="sa-modal-close-icon" icon="x" />
                    </div>
                </div>

                <div className="sa-popup-content">
                    <div className="col-md-12">
                        <div className="form-group m-b-16">
                            <label>{t("title.ORGANIC_NAMES")}</label>
                            {editedOrganicTypes.length > 0 ? (
                                editedOrganicTypes.map((type, index) => (
                                    <div key={index} className="flex gap-2 mb-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter organic type"
                                            value={type}
                                            onChange={(e) => {
                                                const newTypes = [...editedOrganicTypes];
                                                newTypes[index] = e.target.value;
                                                setEditedOrganicTypes(newTypes);
                                            }}
                                        />
                                        <button
                                            type="button"
                                            onClick={() => setEditedOrganicTypes(editedOrganicTypes.filter((_, i) => i !== index))}
                                            className="sa-popup-close-icon"
                                        >
                                            ✖
                                        </button>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500">{t("title.NO_ORGANIC_TYPES_ADDED_YET")}</p>
                            )}
                            <button
                                type="button"
                                onClick={() => setEditedOrganicTypes([...editedOrganicTypes, ""])}
                                className="sa-popup-secondary-btn-style"
                            >
                                {t("button.ADD_NEW_TYPE")}
                            </button>
                        </div>
                    </div>
                </div>

                <div className="sa-popup-btn">
                    <button
                        type="button"
                        className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                        style={{ marginRight: "8px", border: "1px solid #aaa"}}
                        onClick={() => setEditOrganicModalOpen(false)}
                    >
                        {t("button.CANCEL")}
                    </button>
                    <button
                        type="submit"
                        className="sa-popup-secondary-btn-style"
                        
                        onClick={handleUpdateOrganic}
                    >
                        {t("button.UPDATE")}
                    </button>
                </div>
            </form>
        </div>
    </div>
)}  

{inorganicModalOpen && (
    <div className="sa-popup-bg" style={{...modalInFront }}>
        <div className="sa-popup">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
                className="sa-modal-box-style"
            >
                <div className="sa-popup-header">
                    <span className="sa-model-heading">{t("title.INORGANIC_SOURCE")}</span>
                    <div
                        className="sa-popup-close-icon"
                        onClick={() => setInorganicModalOpen(false)}
                    >
                        <FeatherIcon className="sa-modal-close-icon" icon="x" />
                    </div>
                </div>
                <div className="sa-popup-content">
                    <div className="col-md-12">
                        <div className="form-group m-b-16">
                            <label>{t("title.INORGANIC_NAME")}</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter the inorganic name"
                                value={inorganicData.name}
                                onChange={handleInorganicNameChange}
                            />
                        </div>
                        {inorganicErrors.name && <p className="warning-input-msg">{inorganicErrors.name}</p>}
                        <div className="form-group m-b-16">
                            <label>{t("title.TYPE_OF_INORGANIC")}</label>
                            {inorganicTypes.map((type, index) => (
                                <div key={index} className="flex gap-2 mb-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter the type of inorganic"
                                        value={type.type}
                                        onChange={(e) => handleInorganicTypeChange(index, e.target.value)}
                                    />
                                    {inorganicTypes.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveInorganicType(index)}
                                            className="sa-popup-close-icon"
                                        >
                                            ✖
                                        </button>
                                    )}
                                </div>
                            ))}
                            {inorganicErrors.types && <p className="warning-input-msg">{inorganicErrors.types}</p>}
                            
                            {inorganicTypes.length < 2 && (
                                <button
                                    type="button"
                                    onClick={handleAddInorganicType}
                                    className="sa-popup-secondary-btn-style"
                                >
                                    {t("button.ADD_NEW_TYPE")}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="sa-popup-btn">
                    <button
                        type="button"
                        className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                        style={{ marginRight: "8px", border: "1px solid #aaa"}}
                        onClick={() => setInorganicModalOpen(false)}
                    >
                        {t("button.CANCEL")}
                    </button>
                    
                    <button
                        type="button"
                        className="sa-popup-secondary-btn-style text-green bg-none"
                        style={{ marginRight: "8px" , border: "1px solid #02C170"}}
                        onClick={handleSaveAndContinue}
                    >
                        {t("button.SAVE_AND_CONTINUE")}
                    </button>

                    <button
                        type="submit"
                        className="sa-popup-secondary-btn-style"
                        onClick={handleSaveInorganic}
                    >
                        {t("button.ADD")}
                    </button>
                </div>
            </form>
        </div>
    </div>
)}


{editInorganicModalOpen && (
    <div className="sa-popup-bg" >
        <div className="sa-popup" >
            <form onSubmit={(e) => e.preventDefault()} className="sa-modal-box-style">
                <div className="sa-popup-header">
                    <span className="sa-model-heading">{t("title.EDIT_INORGANIC")}</span>
                    <div className="sa-popup-close-icon" onClick={() => setEditInorganicModalOpen(false)}>
                        <FeatherIcon className="sa-modal-close-icon" icon="x" />
                    </div>
                </div>

                <div className="sa-popup-content sa-popup-btn" style={{"justify-content":"flex-end" }}>
                <button type="button" className="sa-table-btn-secondary sa-table-float-right text-green bg-none" onClick={handleOpenAddInorganic} style={{"width": "180px","border":"1px solid #02C170"}}>
                {t("button.ADD_NEW_INORGANIC")}
                </button>
                </div>

                <div className="sa-popup-content sa-table-container" style={{"border":"none"}}>                 
                    <table className="table">
                    <thead>
                        <tr className="bg-gray">
                        <th className="border p-2 text-center">{t("title.INORGANIC_NAME")}</th>
                        <th className="border p-2 text-center">{t("title.TYPE_1")}</th>
                        <th className="border p-2 text-center">{t("title.TYPE_2")}</th>
                        <th className="border p-2 text-center">{t("title.ACTIONS")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {editedInorganicEntries.map((entry, index) => (
                        <tr key={index} className="bg-gray-100">
                            
                            <td className="border p-2 text-center">
                            {isEditing[index] ? (
                                <input
                                type="text"
                                className="p-1 border rounded text-center"
                                value={entry.name}
                                onChange={(e) => {
                                    const newEntries = [...editedInorganicEntries];
                                    newEntries[index].name = e.target.value;
                                    setEditedInorganicEntries(newEntries);
                                }}
                                />
                            ) : (
                                entry.name
                            )}
                            </td>

                            
                            <td className="border p-2 text-center">
                            {isEditing[index] ? (
                                <input
                                type="text"
                                className="p-1 border rounded text-center"
                                value={entry.types[0] || ""}
                                onChange={(e) => {
                                    const newEntries = [...editedInorganicEntries];
                                    newEntries[index].types[0] = e.target.value;
                                    setEditedInorganicEntries(newEntries);
                                }}
                                />
                            ) : (
                                entry.types[0] || ""
                            )}
                            </td>

                            
                            <td className="border p-2 text-center">
                            {isEditing[index] ? (
                                <input
                                type="text"
                                className="p-1 border rounded text-center"
                                value={entry.types[1] || ""}
                                onChange={(e) => {
                                    const newEntries = [...editedInorganicEntries];
                                    newEntries[index].types[1] = e.target.value;
                                    setEditedInorganicEntries(newEntries);
                                }}
                                />
                            ) : (
                                entry.types[1] || ""
                            )}
                            </td>

                            
                            <td className="border p-2 text-center">
                            {isEditing[index] ? (
                                <button
                                className="text-green"
                                onClick={() => handleSaveEdit(index)}
                                >
                                {t("button.SAVE")}
                                </button>
                            ) : (
                                <button
                                className="text-gray bg-none"
                                onClick={() => handleEditRow(index)}
                                >
                                <FeatherIcon icon="edit" size={14} />
                                </button>
                            )}
                            <button onClick={() => handleDeleteInorganicRow(index)} className="text-red bg-none">
                                <FeatherIcon icon="trash" size={14} />
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>

                <div className="sa-popup-content sa-popup-btn" style={{"justify-content":"flex-end" }}>
                    <button type="button" className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                        onClick={() => setEditInorganicModalOpen(false)}>
                        {t("button.CANCEL")}
                    </button>
                    <button type="submit" className="sa-popup-secondary-btn-style"
                        onClick={handleUpdateInorganic}>
                        {t("button.UPDATE")}
                    </button>
                </div>
            </form>
        </div>
    </div>
)}


{timeModalOpen && (
    <div className="sa-popup-bg">
        <div className="sa-popup">
            <form onSubmit={handleTimeSubmit} className="sa-modal-box-style">
            <div className="sa-popup-header">            
                <span className="sa-model-heading">{t("title.TIME_OF_APPLICATION")}</span>
                <div className="sa-popup-close-icon" onClick={() => setTimeModalOpen(false)}>
                    <FeatherIcon className="sa-modal-close-icon" icon="x" />
                </div>
            </div>

            <div className="sa-popup-content">
                {/* Dates Input */}
                <div className="col-md-6">
                <div className="form-group m-b-16">
                    <label>{t("title.DATES")}</label>
                    <input
                        type="text"
                        className="form-control"
                        name="dates"
                        placeholder="Enter the range of dates (e.g., 3-5)"
                        value={timeValues.dates || ""}
                        onChange={handleTimeChange}
                        onBlur={handleTimeBlur}
                    />
                    {timeErrors.dates && <p className="warning-input-msg">{timeErrors.dates}</p>}
                </div>
                </div>

                {/* Time Unit Dropdown */}
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.TIME_UNIT")}</label>
                    <select
                        className="form-control col-md-16"
                        name="unit"
                        value={timeValues.unit || ""}
                        onChange={handleTimeChange}
                        onBlur={handleTimeBlur}
                    >
                        <option value="">{t("title.SELECT_THE_TIME_UNIT")}</option>
                        <option value="DAP">{t("title.DAP")}</option>
                        <option value="WAP">{t("title.WAP")}</option>
                        <option value="DBP">{t("title.DBP")}</option>
                        <option value="WBP">{t("title.WBP")}</option>
                        <option value="MAP">{t("title.MONTHS_AP")}</option>
                        <option value="BP">{t("title.BP")}</option>
                        <option value="AP">{t("title.AP")}</option>
                        <option value= "Base Fertilizer">{t("title.BASE_FERTILIZER")}</option>
                        <option value= "Basal Fertilizer">{t("title.BASAL_FERTILIZER")}</option>
                        <option value="Top Dressing (at 4-5 weeks)">{t("title.TOP_DRESSING")}</option>
                        <option value="Months After, every 4 months">{t("title.MONTHS_AFTER")}</option>
                        <option value="Years After, every 4 months">{t("title.YEARS_AFTER")}</option>
                    </select>
                    {timeErrors.unit && <p className="warning-input-msg">{timeErrors.unit}</p>}
                </div>
                </div>
            </div>

            
            <div className="sa-popup-btn">
                <button id="btnCancel" type="button" className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style" onClick={() => setTimeModalOpen(false)}>{t("button.CANCEL")}</button>
                
                <button
                    className="sa-popup-secondary-btn-style"
                    type="submit"
                    
                >
                    {t("button.SAVE")}
                </button>

            </div>
            </form>
        </div>
    </div>
)}




      {modalOpen && (
        <div className="sa-popup-bg">
          <div className="sa-popup">
            <form
            
            onSubmit={handleSubmit}
              className="sa-modal-box-style"
            >
              <div className="sa-popup-header">
                <span className="sa-model-heading">{isEditMode ? t("title.EDIT_CROP_DETAILS") : t("title.CROP_DETAILS")}</span>
                <div
                  className="sa-popup-close-icon"
                  onClick={() => setModalOpen(false)}
                >
                  <FeatherIcon className="sa-modal-close-icon" icon="x" />
                </div>
              </div>
              <div className="sa-popup-content">
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.CROP_NAME")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="cropName"
                      placeholder={t("placeHolder.ENTER_CROP_NAME")}                    
                      value={values.cropName || ""}                    
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                    />
                    {errors.cropName && <p className="warning-input-msg">{errors.cropName}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.SCIENTIFIC_NAME")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="scientificName"
                      placeholder={t("placeHolder.ENTER_SCIENTIFIC_NAME")}
                      value={values.scientificName || ""}
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                    />
                    
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.ORGANIC_FERTILIZER_UNIT")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="organicUnit"
                      placeholder="Enter the organic unit"
                      value={values.organicUnit || ""}                   
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.INORGANIC_FERTILIZER_UNIT")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="inorganicUnit"
                      placeholder="Enter the inorganic unit"
                      value={values.inorganicUnit || ""}
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                    />
                  </div>
                </div>
                
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.PLANT_SPACING")}</label>
                    <input
                      type="text"
                      className="form-control"
                      name="plantSpacing"
                      placeholder="Enter the plant spacing"
                      value={values.plantSpacing || ""}
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                    />
                    {errors.plantSpacing && <p className="warning-input-msg">{errors.plantSpacing}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-b-16">
                    <label>{t("title.UNIT")}</label>
                    <select
                      className="form-control col-md-16"
                      name="spacingUnit"
                      value={values.spacingUnit || ""}                    
                      onChange={handleChange}
                      onBlur={handleOnBlur}
                    >
                    
                      <option value="">{t("title.SELECT_THE_SPACING_UNIT")}</option>
                      <option value="cm">cm</option>
                      <option value="m">m</option>
                    </select>
                    {errors.spacingUnit && <p className="warning-input-msg">{errors.spacingUnit}</p>}
                  </div>
                </div>
              </div>
              <div className="sa-popup-btn">
                <button
                  id="btnCancel"
                  type="button"
                  className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                  onClick={() => {
                    setModalOpen(false);
                    setIsEditMode(false);
                    {!isEditMode && resetForm();} 
                }}
                >
                  {t("button.CANCEL")}
                </button>
                <button type="submit" className="sa-popup-secondary-btn-style" onClick={handleCropSave}>
                {isEditMode ? t("button.UPDATE") : t("button.ADD")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CropRecommendation;