export const TOGGLE = {
  MAP: 'map',
  LIST: 'list'
};

export const VIEW_MODE = {
  TABLE: 'table',
  LIST: 'list'
};

export const VIEW = {
  FARM_LIST: 0,
  PLOT_LIST: 1,
  SENSOR_LIST: 2,
  XINDICATE_ALL: 3,
  DASHBOARD_LIST: 4,
  CORPORATE_LIST: 5,
  WATER_RESOURCE: 6,
  CORPORATE_USER: 7,
  NOTIFICATION_ALL: 8,
  TASKS_ALL: 9,
  FARM_VIEW: 10,
  SETTINGS: 11,
  SYSTEM_SETTINGS: 12,
  BUYER_PROFILE: 13,
  BUYER_DETAIL: 14,
  BUYER_USER: 15,
  CCR_PROFILE: 16,
  ACCOUNT_SETTING: 17,
  ACCOUNT_USER: 18,
  OTHER: 999
};

export const IRRIGATION_STATUS = {
  ON: 'ON',
  ON_REQUESTED: 'ON_REQUESTED',
  OFF: 'OFF',
  ERROR: 'ERROR',
  PENDING: 'PENDING',
  OFF_REQUESTED: 'OFF_REQUESTED'
};

export const DAYS = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY'
};

export const IRRIGATION_MODE = {
  MANUAL: 'MANUAL',
  SCHEDULE: 'SCHEDULE',
  DAILY: 'DAILY',
  AUTOMATION: 'AUTOMATION',
};
export const ACTUATOR_TYPE = {
  DISPENSER: 'DISPENSER',
  MOTOR: 'MOTOR',
  TANK: 'TANK',
  BRANCH: 'BRANCH',
  MIST: 'MIST',
};
export const CASH_FLOW_TYPE = {
  INCOME: 'INCOME',
  EXPENSE: 'EXPENSE',
};
export const UNITS = {
  Kg: 'Kg',
  g: 'g',
  L: 'L',
  Nuts: 'Nuts',
  None: 'None'
};
export const PERSON = {
  BUYER: 'BUYER',
  CORPORATE: 'CORPORATE'
};
export const CASH_STATUS = {
  CASH_PENDING: 'Pending',
  PAID: 'Paid',
  CASH_NOT_PAID: 'NotPaid',
  ADVANCE_PAID: 'AdvancePaid',
};
export const CASH_STATUS_INCOME = {
  CASH_RECEIVED: 'Received',
  CASH_RECEIVABLE: 'Receivable'
};

export const PROGRAM_CATEGORY = {
  CATEGORY_EDUCATION: 'EDUCATION',
  CATEGORY_AGRICULTURE: 'AGRICULTURE',
  CATEGORY_HEALTH: 'HEALTH'
};

export const FIELDS_TYPE = {
  TYPE_TEXT: 'TEXT',
  TYPE_INT: 'INT',
  TYPE_ENUM: 'ENUM',
  TYPE_DATE: 'DATE'
};

export const DELETE_REASON = {
  CATEGORY_DEATH: 'DEATH',
  CATEGORY_INCORRECT_DATA: 'INCORRECT DATA',
  CATEGORY_DUPLICATE_DATA: 'DUPLICATE DATA',
  CATEGORY_FRAUD: 'FRAUD',
  CATEGORY_MOVED: 'MOVED'
};

export const GENDER = {
  GENDER_MALE: 'MALE',
  GENDER_NOT_SPECIFIED: 'NOT_SPECIFIED',
  GENDER_FEMALE: 'FEMALE'
};

export const EXPENSE_CATEGORY = {
  LABOUR_CHARGE: 'LABOUR_CHARGE',
  FIXED_ASSET: 'FIXED_ASSET',
  REPAIR_MAINTENANCE: 'REPAIR_MAINTENANCE',
  CURRENT_ASSET: 'CURRENT_ASSET',
  OTHER: 'OTHER'
};

export const LABOUR_CHARGE_TYPE = {
  DAILY: 'DAILY',
  MONTHLY: 'MONTHLY',
};
export const VALVES_TYPE = {
  ALL: 'ALL',
  ASSIGNED: 'ASSIGNED',
  UN_ASSIGNED: 'UN_ASSIGNED',
};

export const YieldFilter = {
  ALL: 'ALL',
  AVAILABLE: 'AVAILABLE',
};

export const MODULE = {
  DASHBOARD: 'DASHBOARD',
  SMART_FARM: 'SMART_FARM',
  CASH_FLOW: 'CASH_FLOW',
  DAILY_UPDATE: 'DAILY_UPDATE',
  CORPORATE: 'CORPORATE',
  BUYER_PROFILE: 'BUYER_PROFILE',
  SETTINGS: 'SETTINGS',
  REPORT: 'REPORT',
  MAP_DATA: 'MAP_DATA',
  XINDICATE: 'XINDICATE',
  CCO: "CCO",
  BIRD_VIEW: 'BIRD_VIEW',
  FMS: 'FMS',
  CCR_PROFILE: 'CCR_PROFILE',
  ACCOUNT_SETTING: 'ACCOUNT_SETTING',
  CROP_AGRONOMY: 'CROP_AGRONOMY',
};

export const CASH_FLOW_REPORT_TYPE = {
  CASH_FLOW_REPORT_TYPE: 'CASH_FLOW_REPORT_TYPE',
  MONTHLY_INCOME_REPORT: 'MONTHLY_INCOME_REPORT',
  YIELD_MONTHLY_INCOME: 'YIELD_MONTHLY_INCOME',
  MONTHLY_EXPENSE_REPORT: 'MONTHLY_EXPENSE_REPORT',
  WASTAGE: 'WASTAGE',
  YIELD: 'YIELD'
};

export const MAP_TYPE = {
  SATELLITE: 'SATELLITE',
  NDVI: 'NDVI',
  SLOPE: 'SLOPE',
  HILL_SHADE: 'HILL_SHADE',
  Patch: 'Patch',
  CODE_PLOT: 'CODE_PLOT',
  AGE_PLOT: 'AGE_PLOT',
  PLANT_DENSITY: 'PLANT_DENSITY',
  DSM4: 'DSM4'
};


export const XINDICATE_REPORT_TYPE = {
  REPORT_GENERATED_BY_FARMER: 'REPORT_GENERATED_BY_FARMER',
  REPORT_GENERATED_BY_CROP: 'REPORT_GENERATED_BY_CROP',
  REPORT_GENERATED_BY_YIELD: 'REPORT_GENERATED_BY_YIELD',
  CROP_COUNT: 'CROP_COUNT',
  REPORT_GENERATED_BY_STOCK: 'REPORT_GENERATED_BY_STOCK',
  REPORT_GENERATED_BY_ALL_FARMERS: 'REPORT_GENERATED_BY_ALL_FARMERS',
  REPORT_GENERATED_FOR_SOIL: 'REPORT_GENERATED_FOR_SOIL',
};

export const ACCOUNT_REPORT_TYPE = {
  REPORT_GENERATED_FOR_SOIL: 'REPORT_GENERATED_FOR_SOIL',
};

export const CCO_REPORT_TYPE = {
  TRANSPORT_PAYMENT_SUMMARY: 'TRANSPORT_PAYMENT_SUMMARY',
  GRN_SUMMARY: 'GRN_SUMMARY',
  DAILY_PAYMENT_SUMMARY: 'DAILY_PAYMENT_SUMMARY',
  DC_RETURN_SUMMARY: 'DC_RETURN_SUMMARY'
};

export const WHITE_LABEL = {
  KEELLS: 'KEELLS',
  OXFARM: 'OXFARM'
};

export const USER_GUIDE_INFO = {
  SENZAGRO: 'SenzAgro is a global real-time agricultural ecosystem intelligence platform designed to help 500 million smallholder farmers enhance crop productivity while reducing costs.',
  DASHBOARD: 'Stay informed and in control—monitor your farm\'s progress with real-time insights!',
  SMART_FARM: 'Smart Farming made easy—monitor, manage and optimize your farm effortlessly!',
  SCOUTING: 'Stay ahead with smart scouting. Detect issues early and protect your crops.',
  CASH_FLOW: 'Track your finances with ease. Manage expences, revenue and profits effortlessly.',
  FMS: 'Track farmer details with ease—manage records, monitor activities, and streamline operations efficiently.',
  SENZAGRO_CONNECT: 'Bridge the gap between farmers and buyers—facilitate seamless communication, enhance market access, and boost profitability with direct connections.',
  CORPORATE_DROPDOWN: 'Switch between corporations effortlessly—filter and access corporate-specific details with ease.',
  LANGUAGE: 'Use to translate the app to your preferred language',
  SETTINGS: 'Use to personalize your app settings easily',
  NOTIFICATIONS: 'Click notifications to see the notifications',
  FMS_DASHBOARD: 'Get a comprehensive summary of farmer-related data, including key insights and analytics.',
  FMS_FARMERS: 'Access detailed profiles of farmers, including their activities and productivity.',
  FMS_GROUP: 'Manage and view farmer groups for better organization and streamlined operations.',
  FMS_HOUSEHOLDS: 'Monitor farm households, track ownership details, and manage family-based farming operations.',
  FMS_FIELD_OFFICER: 'View and manage field officers responsible for farm monitoring and support.',
  FMS_REPORTS: 'Generate and review reports on farmer performance, agricultural activities, and key metrics.',
  FMS_SETTINGS: 'Configure and customize settings related to farmer management and data handling.',
  SMART_FARM_ITEM: 'Select a farm to view its details, including plots, irrigation, and weather conditions.',
  SMART_FARM_ADD: 'Add a new farm to the system and start managing its operations efficiently.',
  SMART_FARM_PLOT_TAB: 'Visualize and manage plots within the farm, ensuring optimal land usage.',
  SMART_FARM_IRRIGATION: 'Monitor and control irrigation systems to ensure proper water distribution.',
  SMART_FARM_REPORTS: 'Access detailed irrigation summaries and analytics for better farm management.',
  SMART_FARM_SETTINGS: 'Configure irrigation settings and preferences based on farm-specific needs.',
  SMART_FARM_WEATHER: 'Get real-time weather updates to make informed farming decisions.',
  SCOUTING_FARM_ITEM: 'Select a farm to monitor—manage scouting and task details.',
  SCOUTING_TAB: 'Access scouting records and insights to track field conditions and potential issues.',
  SCOUTING_TASK: 'Manage scouting tasks efficiently—assign, track progress, and resolve field issues.',
  SCOUTING_SETTINGS: 'Customize scouting preferences and configure field monitoring settings.',
  CASH_FLOW_FARM_ITEM: 'Select a farm to track and manage its financial performance.',
  CASH_FLOW_CROPS: 'Monitor crop-related financial data, including investment and returns.',
  CASH_FLOW_YIELD: 'Track yield output and evaluate its financial impact on your farm.',
  CASH_FLOW_INCOME: 'Record and analyze income generated from crop sales and other sources.',
  CASH_FLOW_EXPENSE: 'Manage farm expenses, including operational costs, labor, and supplies.',
  CASH_FLOW_SUMMARY: 'View an overall financial summary with key insights on revenue and expenses.',
  CASH_FLOW_STOCK: 'Track inventory and stock levels to optimize resource management.',
  CASH_FLOW_REPORT: 'Generate detailed financial reports for better budgeting and planning.',
};

export const KEY_LOCATION_MAP = [
  { location: '/', key: 'NAVBAR' },
  { location: '/participant', key: 'FMS' },
  { location: '/home', key: 'SMART_FARM' },
  { location: '/daily-update/farms', key: 'SCOUTING' },
  { location: '/cash-flow/farms', key: 'CASH_FLOW' },
  { location: '/plot', key: 'FARM_VIEW' },
  { location: '/daily-update/scouting', key: 'SCOUTING_VIEW' },
  { location: '/daily-update/task', key: 'SCOUTING_VIEW' },
  { location: '/daily-update/setting', key: 'SCOUTING_VIEW' },
  { location: '/cash-flow/crop', key: 'CASH_FLOW_VIEW' },
  { location: '/cash-flow/yield', key: 'CASH_FLOW_VIEW' },
  { location: '/cash-flow/income', key: 'CASH_FLOW_VIEW' },
  { location: '/cash-flow/expense', key: 'CASH_FLOW_VIEW' },
  { location: '/cash-flow/summary', key: 'CASH_FLOW_VIEW' },
  { location: '/cash-flow/stock', key: 'CASH_FLOW_VIEW' },
  { location: '/cash-flow/report', key: 'CASH_FLOW_VIEW' },
];