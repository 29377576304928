import React, { useState } from "react";
import Switch from "react-switch";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { getUserId } from "../../shared/utils/SharedAuthentication";
import { formatDisplayEnumValue } from "../../utils/Utils";
import {toast} from "react-toastify";

export default function NotificationSetting({ referenceId }) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categories, setCategories] = useState([]); 
  
  const fetchSettingsAndCategories = async () => {
    try {
      const userId = getUserId();
      const reference = referenceId;
      const matrixResponse = await axios.get(
        `${process.env.REACT_APP_HOST}/user/${userId}/notification-matrix`
      );
      
      const matrixData = matrixResponse.data?.content || []; 
      
      const modeStatusMap = matrixData.reduce((acc, item) => {
        acc[item.notificationCategory] = item.modeStatus;
        return acc;
      }, {});
      
      const settingsResponse = await axios.get(
        `${process.env.REACT_APP_HOST}/user/${userId}/reference/${reference}/notification-settings`
      );

      const savedSettings = settingsResponse.data?.content || [];

      if (savedSettings.length > 0) {        
        const updatedCategories = savedSettings.map((setting) => ({
          notificationCategory: setting.notificationCategory,
          // enable: Object.values(setting.notificationTypes).some((v) => v),
          notificationTypes: {
            EMAIL: modeStatusMap[setting.notificationCategory]?.EMAIL ? setting.notificationTypes.EMAIL : false,
            SMS: modeStatusMap[setting.notificationCategory]?.SMS ? setting.notificationTypes.SMS : false,
            PUSH_NOTIFICATION: modeStatusMap[setting.notificationCategory]?.PUSH_NOTIFICATION ? setting.notificationTypes.PUSH_NOTIFICATION : false
          },
          notificationLimit: {
            EMAIL: setting.notificationLimit?.EMAIL ?? 10,  
            SMS: setting.notificationLimit?.SMS ?? 10,
            PUSH_NOTIFICATION: setting.notificationLimit?.PUSH_NOTIFICATION ?? 10
          },
          modeStatus: modeStatusMap[setting.notificationCategory] || {},
          
        }));
        setCategories(updatedCategories);
        console.log("updatedCategories", updatedCategories)
      } else {
        const categoryResponse = await axios.get(
          `${process.env.REACT_APP_HOST}/user/${userId}/notification-category`
        );
        const defaultCategories = categoryResponse.data.map((category) => ({
          notificationCategory: category,
          // enable: true,
          notificationLimit:{ EMAIL: 10, SMS: 10, PUSH_NOTIFICATION: 10 },
          notificationTypes: { EMAIL: false, SMS: false, PUSH_NOTIFICATION: false },
          modeStatus: modeStatusMap[category] || {},
        }));
        setCategories(defaultCategories);
      }
    } catch (error) {
      console.error("Error fetching notification settings or categories:", error);
    } 
  };

  const openModal = () => {
    setIsModalVisible(true);
    fetchSettingsAndCategories();
  };

  const handleCategoryToggle = (index, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index][field] = value;
    setCategories(updatedCategories);
  };

  const handleModeToggle = (index, mode, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index].notificationTypes[mode] = value;
    setCategories(updatedCategories);
  };

  const handleLimitChange = (index, mode, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index].notificationLimit[mode] = value;
    setCategories(updatedCategories);
    
  };
  
 
  const saveNotificationSettings = async () => {
    try {
      const reference = referenceId;
      const userId = getUserId();

      const transformedData = categories.map((category) => ({
        notificationCategory: category.notificationCategory,
        notificationTypes: category.notificationTypes,
        notificationLimit: {
          EMAIL: category.notificationLimit?.EMAIL ?? 10,  
          SMS: category.notificationLimit?.SMS ?? 10,
          PUSH_NOTIFICATION: category.notificationLimit?.PUSH_NOTIFICATION ?? 10
        },
      }));
      

      const response = await axios.put(
        `${process.env.REACT_APP_HOST}/user/${userId}/reference/${reference}/notification-settings`,
        transformedData
      );
      toast.success("Alert Update successfully");
      console.log("Notification settings updated successfully:", response.data);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error saving notification settings:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveNotificationSettings();
  };

  

  return (
    <div>
      <FeatherIcon onClick={openModal} icon={"bell"} id={"corporate-notification"}/>
      {isModalVisible && (
        <div className="sa-popup-bg" style={{ cursor: "auto", color:"#aaa"}}>
          <div className="sa-popup">
            <div className="sa-modal-box-style">
              <div className="sa-popup-header">
                <span className={"sa-model-heading"}>
                  {t("title.EDIT_NOTIFICATION_SETTINGS")}
                </span>
                
                <div
                  className="sa-popup-close-icon"
                  onClick={() => setIsModalVisible(false)}
                >
                  <FeatherIcon className={"sa-modal-close-icon"} icon={"x"} />
                </div>
              </div>
              <div className="container">
                <form onSubmit={handleSubmit}>
                  {categories.map((category, index) => (
                    <div key={index} style={{marginBottom: "32px"}}>
                      <div className="d-flex align-items-center mb-2" style={{color: "#aaa", fontSize: "16px", fontWeight: "bold"}}>
                        <p className={"mr-3 mb-0"}>{formatDisplayEnumValue(category.notificationCategory)}</p>                       
                      </div>                    

                      {(
                        <div className="row mb-3" style={{color: "#aaa", fontSize: "14px"}}>
                          <div className="col-md-4">
                            <div className="form-group m-b-16 notification-box"
                            style={{ cursor: !category.modeStatus.EMAIL ? "not-allowed" : "pointer",
                                     border: !category.modeStatus.EMAIL ? "1px solid #D3D3D3" : "1px solid #aaa"
                             }}>
                              {t("title.EMAIL")}
                              <div className={`ml-auto switch-wrapper ${!category.modeStatus.EMAIL ? 'is-disabled' : ''}`}
                                    title={!category.modeStatus.EMAIL ? "Disabled by admin" : ""}>
                                <Switch
                                  className="react-switch"
                                  disabled={!category.modeStatus.EMAIL}
                                  onChange={(checked) =>
                                    handleModeToggle(index, "EMAIL", checked)
                                  }
                                  checked={category.notificationTypes.EMAIL}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  offColor="#D3D3D3"
                                  onColor={"#23ad32"}
                                  height={16}
                                  width={30}
                                />
                              </div>
                            </div>
                            <div style={{ fontSize: "14px"}}>                        
                              <label>{t("title.EMAIL_LIMIT_PER_MONTH")}</label>
                              <input
                                  type="number"
                                  className="form-control text-center"
                                  disabled={!category.modeStatus.EMAIL}
                                  value={category.modeStatus.EMAIL?category.notificationLimit.EMAIL:0}
                                  min="0"
                                  onChange={(e) => handleLimitChange(index, "EMAIL", parseInt(e.target.value, 10))}
                                />
                                
                              </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-b-16 notification-box"
                            style={{ cursor: !category.modeStatus.SMS ? "not-allowed" : "pointer", 
                                     border: !category.modeStatus.SMS ? "1px solid #D3D3D3" : "1px solid #aaa"
                            }}>
                              {t("title.SMS")}
                              <div className={`ml-auto switch-wrapper ${!category.modeStatus.SMS ? 'is-disabled' : ''}`}
                                    title={!category.modeStatus.SMS ? "Disabled by admin" : ""}>
                                <Switch
                                  className="react-switch"
                                  disabled={!category.modeStatus.SMS}
                                  onChange={(checked) =>
                                    handleModeToggle(index, "SMS", checked)
                                  }
                                  checked={category.notificationTypes.SMS}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  offColor="#D3D3D3"
                                  onColor={"#23ad32"}
                                  height={16}
                                  width={30}
                                />
                                
                              </div>
                            </div>
                            <div style={{ fontSize: "14px"}}>                        
                                <label>{t("title.SMS_LIMIT_PER_MONTH")}</label>                               
                                <input
                                    type="number"
                                    className="form-control text-center"
                                    disabled={!category.modeStatus.SMS}                              
                                    value={category.modeStatus.SMS?category.notificationLimit.SMS:0}
                                    min="0"
                                    onChange={(e) => handleLimitChange(index, "SMS", parseInt(e.target.value, 10))}
                                  />
                                  
                                </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group m-b-16 notification-box"
                            style={{ cursor: !category.modeStatus.PUSH_NOTIFICATION ? "not-allowed" : "pointer",
                                      border: !category.modeStatus.PUSH_NOTIFICATION ? "1px solid #D3D3D3" : "1px solid #aaa"
                             }}>
                              {t("title.MOBILE_PUSH_NOTIFICATION")}
                              <div className={`ml-auto switch-wrapper ${!category.modeStatus.PUSH_NOTIFICATION ? 'is-disabled' : ''}`}
                                    title={!category.modeStatus.PUSH_NOTIFICATION ? "Disabled by admin" : ""}>
                                <Switch
                                  className="react-switch"
                                  disabled={!category.modeStatus.PUSH_NOTIFICATION}
                                  onChange={(checked) =>
                                    handleModeToggle(index, "PUSH_NOTIFICATION", checked)
                                  }
                                  checked={category.notificationTypes.PUSH_NOTIFICATION}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  offColor="#D3D3D3"
                                  onColor={"#23ad32"}
                                  height={16}
                                  width={30}
                                />
                              </div>
                            </div>
                            <div style={{ fontSize: "14px"}}>                        
                              <label >{t("title.PUSH_LIMIT_PER_MONTH")}</label>
                              <input
                                  type="number"
                                  className="form-control text-center"
                                  disabled={!category.modeStatus.PUSH_NOTIFICATION}
                                  value={category.modeStatus.PUSH_NOTIFICATION? category.notificationLimit.PUSH_NOTIFICATION : 0}
                                  min="0"
                                  onChange={(e) => handleLimitChange(index, "PUSH_NOTIFICATION", parseInt(e.target.value, 10))}
                                />
                                
                              </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="sa-popup-btn">
                    <button
                      type={"button"}
                      className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style update-size"
                      onClick={() => setIsModalVisible(false)}
                    >
                      {t("button.CANCEL")}
                    </button>
                    <button
                      className="sa-popup-secondary-btn-style update-size"
                      type="submit"
                    >
                      {t("button.UPDATE")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}      
    </div>
  );
}



