import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Container from "react-bootstrap/Container";
import IrrigationStatus from "../../irrigation-status-plot"
import {TOGGLE} from "../../../utils/Enum";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedPlot} from "../../../actions/plot";
import {toCapsFirst} from "../../../utils/Utils";
import FeatherIcon from 'feather-icons-react';
import PlotSensorsSummary from "../plot/plot-sensors-summary";

import {useTranslation} from "react-i18next";
import {Img} from "react-image";
import farmImg from "../../../images/farm.jpg";
import PlotAdd from "./plot-add";
import PlotStatus from "../../plot-status";

const PlotList = () => {
  const {t} = useTranslation();
  const {farmId} = useParams();
  let history = useHistory();
  const [mqttPlotIrrigation, setMqttPlotIrrigation] = useState(null);
  const [addPlot, setAddPlot] = useState(false);
  const plotList = useSelector(state => {
    return state.plotList.plotList
  });
  const dispatch = useDispatch();

  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  const selectedPlot = useSelector(state => {
    return state.selectedPlot.selectedPlot;
  });

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });


  function goToSensor(plot) {
    if (toggle !== TOGGLE.MAP || selectedPlot.id === plot.id) {
      if (!plot.kitId) {
        history.push('/' + farmId + '/plot/' + plot.id + '/schedule');
      } else {
        history.push('/' + farmId + '/plot/' + plot.id + '/sensor');
      }
    }
    dispatch(getSelectedPlot(plot));
  }

  useEffect(() => {
    if (selectedPlot.id) {
      dispatch(getSelectedPlot({}));
    }
  }, [])

  // const client = new Paho.Client("mqtt.senzmate.com", 8000, 'qwerty12345'+new Date().getTime());
  //
  // client.onConnectionLost = onConnectionLost;
  // client.onMessageArrived = onMessageArrived;
  //
  // useEffect(()=>{
  //   if(!client || !plotList || plotList.length===0){
  //     return
  //   }
  //   client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  // },[plotList]);
  //
  // function onConnect() {
  //   if (client.isConnected()) {
  //       plotList.forEach((plot)=>{
  //         client.subscribe(`S2F/SA/V1/plot/${plot.id}/#`);
  //       });
  //   }
  // }
  //
  //
  // function onConnectionLost(responseObject) {
  //   if (responseObject.errorCode !== 0) {
  //     client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  //   }
  // }
  //
  // function onMessageArrived(message) {
  //   if(message){
  //      setMqttPlotIrrigation(null);
  //     let payload=JSON.parse(message.payloadString);
  //     let topic=message.topic;
  //     setMqttPlotIrrigation({topic: topic,status:payload.status});
  //   }
  // }

  return (
    <div className={"half-list-container"}>
      <Container>
        <div id={"plotListContainer"} className={window.innerWidth > 620 ? "row" : "mo-plot-list"}>
          {plotList.map((plot, index) => (
            <div className={window.innerWidth > 620 ? "col-xl-4 col-lg-6" : "mo-plot-list-card"}
                 onClick={() => goToSensor(plot)} key={plot.id}>
              <div className="single-card sa-cursor">
                <div className={"single-card-header " + (plot.id === selectedPlot.id ? 'card-top-border' : '')}>
                  <div className="card-img-view">
                    <Img className="card-img" src={[plot.imageThumbnail ? plot.imageThumbnail : plot.image, farmImg]}
                         alt="plot"/>
                    {/*<img className="card-img" src={plot.imageThumbnail ? plot.imageThumbnail : plot.image} alt="plot"/>*/}
                  </div>
                  <div className="card-header-text limit-charater-farmname" data-toggle="tooltip">
                    <label title={toCapsFirst(plot.name)} className="heading">{toCapsFirst(plot.name)}</label>
                    <div className="sub-text"
                      title={plot.location || selectedFarm.location || "Sri Lanka"}>
                      <div className="location-container">
                        <FeatherIcon className="map-icon" icon="map-pin" />
                        {plot.location || selectedFarm.location
                          ? (plot.location || selectedFarm.location).split(",").length > 2
                          ? `${(plot.location || selectedFarm.location).split(",")[0]}, ${(plot.location || selectedFarm.location).split(",")[1]}, ...`
                          : plot.location || selectedFarm.location
                          : "Sri Lanka"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="single-card-content">
                  <div className="row p-3">
                    <div className="col-6">
                      <IrrigationStatus plotId={plot.id} mqttPlotStatus={mqttPlotIrrigation}/>
                    </div>
                    <div className="col-6">
                      <div className="text-center mr-lg-auto mx-lg-auto">
                        <div className="card-content-heading"> {t("title.PLOT_STATUS")}</div>
                        <PlotStatus kitId={plot.kitId}/>
                      </div>
                    </div>
                  </div>
                  <PlotSensorsSummary plotId={plot.id} plot={plot}/>
                  <div className="sensor-data-count">{index + 1} OF {plotList.length}</div>
                </div>
              </div>
            </div>

          ))}

          {/*<div className={"mo-list-view-last"}></div>*/}

          {plotList.length === 0 && (
            <div className={"empty-results"}>
              <FeatherIcon icon="info"/>
              <div className={"empty-results-text"}>{t("emptyMsg.THERE_ARE_NO_PLOTS_PLEASE_ADD")}</div>
            </div>)
          }
        </div>
      </Container>
      <div className={"text-right farm-add-btn"}>
        <FeatherIcon className={"icon-add-btn"} icon="plus-circle"
                     onClick={() => setAddPlot(true)}/>
      </div>

      {addPlot && <div className="sa-popup-bg">
        <div className="sa-popup">
          <div className={"sa-modal-box-style"}>
            <div className="sa-popup-header">
            <span
              className={'sa-model-heading'}>{t("title.ADD_PLOT")}</span>
              <div className="sa-popup-close-icon"><FeatherIcon
                onClick={() => setAddPlot(false)}
                className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>

            <PlotAdd onClose={() => setAddPlot(false)}/>

          </div>
        </div>
      </div>}

    </div>
  )
};


export default PlotList
