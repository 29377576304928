import React, {useEffect, useState, useCallback} from 'react'
import {Container} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import * as _ from "underscore";
import FilterHandler from '../shared/utils/FilterHandler';
import { changeView, toggleLoader} from '../shared/actions/setting';
import axios from 'axios';
import {getUserId, isAdminUser} from "../shared/utils/SharedAuthentication";
import * as dateFns from "date-fns";
import {DateRangePicker} from 'rsuite';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {formatDisplayEnumValue} from "../../src/utils/Utils";
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import { VIEW } from '../utils/Enum';
import {debounce} from 'lodash';

const NotificationPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [notificationsUpdate, setNotificationsUpdate] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loadNotifications, setLoadNotifications] = useState([]);
  const [notificationListAll, setNotificationListAll] = useState([]);
  const [farmName, setFarmName] = useState([])
  const [type, setType] = useState([])
  const [accountList, setAccountList] = useState([]);
  const [corporateList, setCorporateList] = useState([]);
  const [severityList, setSeverityList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [farmList, setFarmList] = useState([]);
  const [selectedCorporate, setSelectedCorporate] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedSeverity, setSelectedSeverity] = useState("");
  const [selectedFarm, setSelectedFarm] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const {t, i18n} = useTranslation();
  const [filteredFarmList, setFilteredFarmList] = useState([]);

  const corporateListFromStore = useSelector((state) => {
    return state.modules.corporateList;
  });

  function dateFormat(date) {
    return dateFns.format(new Date(date), "yyyy-MM-dd");
  }  

  function changeKey(arr) {
    return arr.map((s) => {
      if (s.hasOwnProperty("startTime")) {
        s.createdAt = s.startTime;
        s.seenStatus = false;
        delete s.startTime;
      }
      else if (s.hasOwnProperty("creationDate")) {
        s.createdAt = s.creationDate;
        s.seenStatus = false;
        delete s.creationDate;
      }
      return s;
    });
  }

  const fetchNotifications = (params) => {
    dispatch(toggleLoader(true));
    axios
    .get(`${process.env.REACT_APP_HOST}/user/${getUserId()}/notification/size/100/index/0`, {params})
    .then((res) => {
    const notifications = res.data.content?.map((item) => ({
    ...item,
          createdAt: item.startTime? item.startTime : item.creationDate,
        })) || [];
        setNotifications(notifications);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "Error fetching notifications.");
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  };
  const debouncedFetchNotifications = useCallback(
    debounce((params) => {
      fetchNotifications(params);
    }, 500), []);


  const onCheck = (e) => {
    if (e.length === 0) {
      setDateRange(null);
      return;
    }

    const startDate = dateFormat(e[0]);
    const endDate = dateFormat(e[1]);

    setDateRange({startDate, endDate});
  };

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.account) setSelectedAccount(params.account);
    if (params.corporate) setSelectedCorporate(params.corporate);
    if (params.severity) setSelectedSeverity(params.severity);
    if (params.type) setSelectedType(params.type);
    if (params.farm) setSelectedFarm(params.farm);
    if (params.startDate && params.endDate) {
      setDateRange({startDate: params.startDate, endDate: params.endDate});
    }
    console.log("params", params);
    console.log("query url useEffect")

    debouncedFetchNotifications(params);
  }, [location.search]);

  useEffect(() => {
    const params = {};
    if (selectedAccount) params.account = selectedAccount;
    if (selectedCorporate) params.corporate = selectedCorporate;
    if (selectedSeverity) params.severity = selectedSeverity;
    if (dateRange?.startDate) params.startDate = dateRange.startDate;
    if (dateRange?.endDate) params.endDate = dateRange.endDate;
    if (selectedFarm) params.farm = selectedFarm;
    if (selectedType) params.type = selectedType;

    const queryStringified = queryString.stringify(params);
    history.push(`?${queryStringified}`);
    console.log("params", params);
    console.log("filter useEffect")

    debouncedFetchNotifications(params);
  }, [selectedAccount, selectedCorporate, selectedSeverity, dateRange, selectedFarm, selectedType]);


  const onAccountChange = (e) => {
    let accountId = e.target.value;
    setSelectedAccount(accountId);
    setSelectedCorporate(null);
    setSelectedFarm("");
    let filteredCorporateList = accountId
        ? corporateListFromStore.filter(corporate => corporate.accountId === accountId)
        : corporateListFromStore;
    setCorporateList(filteredCorporateList);
    let corporateIds = filteredCorporateList.map(corp => corp.id);
    let filteredFarms = farmList.filter(farm => corporateIds.includes(farm.corporateId));
    setFilteredFarmList(filteredFarms);    

  };

  const onCorporateChange = (e) => {
    const corporateId = e.target.value;
    setSelectedCorporate(corporateId);
    setSelectedFarm("");
    if (corporateId) {
      const filteredFarms = farmList.filter((farm) => farm.corporateId === corporateId);
      setFilteredFarmList(filteredFarms);
    } else {
      setFilteredFarmList(farmList);
    }
  };


  const onFarmChange = (e) => {
    setSelectedFarm(e.target.value);
  };


  const onSeverityChange = (e) => {
    setSelectedSeverity(e.target.value);
  };

  const onTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  useEffect(() => {
    if (isAdminUser()) {
      dispatch(toggleLoader(true));
      axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/account')
        .then(res => {
          setAccountList(res.data.content);
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            toast.error(error.response.data.message);
          }
        }
      ).finally(() => {
        dispatch(toggleLoader(false))
      })
    }

  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios
      .get(`${process.env.REACT_APP_HOST}/user/${getUserId()}/farm`)
      .then((res) => {
        const farms = res.data.content;
        setFarmList(farms);
        setFilteredFarmList(farms);
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/notification-severity')
      .then(res => {
        setSeverityList(res.data);
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, []);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + '/user/' + getUserId() + '/notification-category')
      .then(res => {
        setTypeList(res.data);
      }).catch(error => {
        if (error.response && error.response.status === 422) {
          toast.error(error.response.data.message);
        }
      }
    ).finally(() => {
      dispatch(toggleLoader(false))
    })
  }, []);

  useEffect(() => {
    if (!selectedAccount) {
      setCorporateList(corporateListFromStore);
    }
    dispatch(changeView(VIEW.NOTIFICATION_ALL));
  }, [corporateListFromStore]);
  

  return (
    <div className={'list-view  full-list-view p-t-100'}>
      <Container className={"m-t-8"}>
        <div className={"single-card m-rl-m-8 p-a-16"}>
          <div className={"sa-table-flex"} style={{marginBottom: 8}}>
            <div className={'member-row gap-8 filter-wrapper'}>
            <span className='sa-mobile-view-m'>
            <DateRangePicker
              style={{zIndex: 9999}}
              disabledDate={(date) => date > new Date()}
              disabled={false}
              onChange={(e) => onCheck(e)}
              oneTap={false}
              onOk={(e) => onCheck(e)}
              showWeekNumbers={true}
              appearance={"default"}
              placeholder={t("title.TODAY")}
              ranges={[
                {
                  label: "Today",
                  value: [new Date(), new Date()],
                },
                {
                  label: "Yesterday",
                  value: [
                    dateFns.addDays(new Date(), -1),
                    dateFns.addDays(new Date(), -1),
                  ],
                },
                {
                  label: "Last 7 days",
                  value: [dateFns.subDays(new Date(), 6), new Date()],
                },
                {
                  label: "Last 30 days",
                  value: [dateFns.subDays(new Date(), 30), new Date()],
                },
              ]}
              value={dateRange ? [new Date(dateRange.startDate), new Date(dateRange.endDate)] : [new Date(), new Date()]}
            />
          </span>
              {isAdminUser() && (
                <span
                  className={"sa-table-float-left participant-filter"}
                  name={""}
                >
                  <select className="sa-filter sa-mobile-view-m"
                          onChange={(e) => onAccountChange(e)}
                          value={selectedAccount || ''}
                  >
                    <option value={""}>{"Account"}</option>
                    {accountList &&
                      accountList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </span>
              )}
              {isAdminUser() && (
                <span
                  className={"sa-table-float-left participant-filter"}
                  name={""}
                >
                  <select
                    className="sa-filter sa-mobile-view-m"
                    onChange={(e) => onCorporateChange(e)}
                    value={selectedCorporate || ''}
                  >
                    <option value={""}>{"Corporate"}</option>
                    {corporateList &&
                      corporateList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </span>
              )}
              <span className={"sa-table-float-left participant-filter"}>
                <select
                  className="sa-filter sa-mobile-view-m"
                  onChange={(e) => onFarmChange(e)}
                  value={selectedFarm || ''}
                >
                  <option value={""}>{"Farm"}</option>
                  {filteredFarmList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </span>

              <span
                className={"sa-table-float-left participant-filter"}
                name={""}
              >
                  <select className="sa-filter sa-mobile-view-m"
                          onChange={(e) => onSeverityChange(e)}
                          value={selectedSeverity || ''}
                  >
                    <option value={""}>{"Severity"}</option>
                    {severityList &&
                      severityList.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </span>
              <span
                className={"sa-table-float-left participant-filter"}
                name={""}
              >
                  <select className="sa-filter sa-mobile-view-m"
                          onChange={(e) => onTypeChange(e)}
                          value={selectedType || ''}
                  >
                    <option value={""}>{"Notification Type"}</option>
                    {typeList &&
                      typeList.map((item, index) => (
                        <option key={index} value={item}>
                          {formatDisplayEnumValue(item)}
                        </option>
                      ))}
                  </select>
                </span>
            </div>

          </div>
          <div className="sa-table-container notification-calc-height">
            <table className="table table-borderless">
              <thead>
              <tr>
                <th className={"sa-table-head-sticky"}>Notification</th>
                <th className={"sa-table-head-sticky"}>Type</th>
                <th className={"sa-table-head-sticky"}>Farm Name</th>
                <th className={"sa-table-head-sticky"}>Plot Name</th>
                <th className={"sa-table-head-sticky"}>Date</th>
              </tr>
              </thead>
              <tbody>
              {notifications.length > 0 &&
                notifications.sort(function (a, b) {
                  return new Date(b.createdAt) - new Date(a.createdAt);
                }) &&
                notifications.map((item, index) => (
                  <tr key={index}>
                    <td className={'sa-table-data'}>{item.message}</td>
                    <td className={'sa-table-data'}>{item.type ? formatDisplayEnumValue(item.type) : "NA"}</td>
                    <td className={'sa-table-data'}>{item.farmName ? item.farmName : "NA"}</td>
                    <td className={'sa-table-data'}>{item.plotName ? item.plotName : "NA"}</td>
                    {item.type === 'DEVICE_OFFLINE' ? (
                      <td className={'sa-table-data'}>
                        {item.createdAt} - <br/>
                        {item.endTime ? item.endTime : "NA"}
                      </td>
                    ) : item.type === 'MISSED_IRRIGATION_NOTIFICATION' ? (
                      <td className={'sa-table-data'}>{item.createdAt}</td>
                    ) : (
                      <td className={'sa-table-data'}>{item.createdAt?.slice(0, 10)}</td>
                    )}

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NotificationPage
