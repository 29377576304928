const INIT_STATE = {
  isLoaderCount: 0,
  selectedService: {},
  modules: [],
  selectedCorporate: null,
  selectedNotification: [],
  joyRideKey: [],
  joyRideSteps: [],
};


export default (state = INIT_STATE, action) => {

  switch (action.type) {

    case "TOGGLE_STATE": {
      return {
        ...state,
        toggle: action.payload
      };
    }
    case "EXPORT_OBJECT": {
      return {
        ...state,
        exportObject: action.payload
      };
    }
    case "CHANGE_VIEW": {
      return {
        ...state,
        view: action.payload
      };
    }
    case "CHANGE_PASSWORD": {
      return {
        ...state,
        isChangePasswordVisible: action.payload
      };
    }
    case "CONFIRMATION_DIALOG": {
      return {
        ...state,
        confirmationDialog: action.payload
      };
    }
    case "TIME_PICKER": {
      return {
        ...state,
        timePicker: action.payload
      };
    }
    case "TOGGLE_LOADER": {
      var counter = state.isLoaderCount;
      if (action.payload) {
        counter++;
      } else {
        counter--;
      }
      if (counter <= 0) {
        return {
          ...state,
          isLoaderCount: 0,
          isLoading: false
        };
      } else {
        return {
          ...state,
          isLoaderCount: counter,
          isLoading: true
        };
      }
      // return {
      //   ...state,
      //   isLoading: action.payload
      // };
    }

    case "TOGGLE_LOADER_CLEAR": {
      console.log("clear")
      return {
        ...state,
        isLoaderCount: 0,
        isLoading: false
      };
    }
    case "SERVICE_LIST": {
      return {
        ...state,
        modules: action.payload
      };
    }

    case "SELECTED_SERVICE": {
      return {
        ...state,
        selectedService: action.payload
      };
    }
    case "SELECTED_CORPORATE": {
      return {
        ...state,
        selectedCorporate: action.payload
      };
    }
    case "TOGGLE_LOADER_MULTI": {
      var count = state.isLoadingCount;
      if (action.payload) {
        count++;
      } else {
        count--;
      }
      if (count <= 0) {
        return {
          ...state,
          isLoadingCount: 0,
          isLoading: false
        };
      } else {
        return {
          ...state,
          isLoadingCount: count,
          isLoading: true
        };
      }

    }
    case "SELECTED_NOTIFICATION": {
      return {
        ...state,
        selectedNotification: action.payload
      };
    }

    case "JOYRIDE_KEY": {
      const currentKeys = Array.isArray(state.joyRideKey) ? state.joyRideKey : [];
      if (currentKeys.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        joyRideKey: [...currentKeys, action.payload]
      };
    }

    case "JOYRIDE_STEPS": {
      const currentSteps = Array.isArray(state.joyRideSteps) ? state.joyRideSteps : [];
      const newSteps = Array.isArray(action.payload) ? action.payload : [action.payload];
      const mergedSteps = [...currentSteps, ...newSteps];

      const uniqueSteps = mergedSteps.filter((step, index, self) =>
        index === self.findIndex(t => t.title === step.title)
      );

      return {
        ...state,
        joyRideSteps: uniqueSteps,
      };
    }

    case "UPDATE_JOYRIDE_KEY_BY_LOCATION": {
      return {
        ...state,
        joyRideKey: action.payload,
      };
    }

    default :
      return state;

  }
}

