import React, {useEffect, useState} from "react";
import FarmItem from "./farm-item";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {useDispatch, useSelector} from "react-redux";
import {getFarmList, getFilterFarmList, getSelectedFarm, getSourceList} from "../../../actions/farm";
import {MODULE, TOGGLE, VIEW} from "../../../utils/Enum";
import {changeView, setJoyRideKey, setJoyRideSteps, toggleLoader} from "../../../shared/actions/setting";
import Col from "react-bootstrap/Col";
import {getPlotList, getSelectedPlot} from "../../../actions/plot";
import axios from "axios";
import {goToHomePage} from "../../../utils/Utils";
import {useTranslation} from "react-i18next";
import FeatherIcon from "feather-icons-react";
import FarmAddMap from "./farm-add-map";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId, getRole} from "../../../shared/utils/SharedAuthentication";
import {hasPermission} from "../../../utils/Authentication";
import * as _ from "underscore";
import {ExportToCsv} from "export-to-csv";

const FarmList = (props) => {
  const [showFadeEffect, setShowFadeEffect] = useState(false);
  const dispatch = useDispatch();
  const [farms, setFarms] = useState([]);
  const [sources, setSources] = useState([]);
  const [addFarm, setAddFarm] = useState(false);
  const [runTour, setRunTour] = useState(false);
  const [summaryList, setSummaryList] = useState([]);
  const [actuatorSummaryList, setActuatorSummaryList] = useState([]);

  const {t, i18n} = useTranslation();
  const toggle = useSelector(state => {
    return state.setting.toggle
  });

  const farmModules = useSelector(state => {
    return state.modules.farmModules;
  });
  const selectedCorporate = useSelector((state) => {
    return state.modules.selectedCorporate;
  });
  const exportObject = useSelector((state) => {
    return state.setting.exportObject;
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
      const farmNavItems = [
        { title: 'SMART_FARM_ITEM', key: 'SMART_FARM' },
        ...(hasPermission("SMART_FARM", "ADD") ? [
          { title: 'SMART_FARM_ADD', key: 'SMART_FARM' },
        ] : []),
      ];
    
      dispatch(setJoyRideSteps(farmNavItems))
      dispatch(setJoyRideKey('SMART_FARM'))
    }, []);

  function handleScroll() {
    setShowFadeEffect(window.pageYOffset >= 5);
  }

  function exportData() {
    const data = [];

    farms.forEach(participant => {
      data.push(
         {"Farm Name": participant.name,"coordinates": coordinatesToString(participant.coordinates),"id":participant.id}
      );
    });

    const opt = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Farmers',
      useBom: true,
      noDownload: false,
      headers: ["Name","coordinates","id"],
      filename: "Farms",
      nullToEmptyString: true,
    };

    const csvExporter = new ExportToCsv(opt);
    csvExporter.generateCsv(data);
  }

  function coordinatesToString(cords) {
    let output = "";
    cords.forEach(e => {
      if (output !== "") {
        output += ",";
      }

      output += "[" + e.lat + "," + e.lng + "]";
    });

    return output;
  }

  useEffect(() => {
    // if (![500].includes(getRole())) {
    //   goToHomePage(props.history, true);
    // }

    dispatch(changeView(VIEW.FARM_LIST));
    dispatch(getSelectedFarm({}));
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm` + (selectedCorporate ? `?corporate=` + selectedCorporate : ""))
      .then(res => {
        dispatch(getFarmList([...res.data.content]));
        dispatch(getFilterFarmList([...res.data.content]));
        dispatch(getSelectedPlot({}));
        dispatch(getPlotList([]));
        dispatch(toggleLoader(false));
      }) .finally(() => {
      dispatch(toggleLoader(false))
    });
  }, [selectedCorporate]);

  useEffect(() => {
    console.log(exportObject);
    if (exportObject !== "FARMS" || farms==null) {
      return;
    }

    exportData();
  }, [exportObject]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/source` + (selectedCorporate ? `?corporate=` + selectedCorporate : ""))
      .then(res => {
        dispatch(getSourceList([...res.data.content]));
        setSources([...res.data.content]);
      }) .finally(() => {
      dispatch(toggleLoader(false))
    });
  }, [selectedCorporate]);

  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm-summary` + (selectedCorporate ? `?corporate=` + selectedCorporate : ""))
      .then(res => {
          setSummaryList([...res.data.content]);
      }) .finally(() => {
      dispatch(toggleLoader(false))
    });
  }, [selectedCorporate]);


  useEffect(() => {
    dispatch(toggleLoader(true));
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm-actuator-summary` + (selectedCorporate ? `?corporate=` + selectedCorporate : ""))
      .then(res => {
          setActuatorSummaryList([...res.data.content]);
      }) .finally(() => {
      dispatch(toggleLoader(false))
    });
  }, [selectedCorporate]);

  // const farmList = useSelector(state => {
  //   return state.farmList.farmList
  // });


  // const client = new Paho.Client("mqtt.senzmate.com", 8000, 'qwerty12345'+new Date().getTime());
  //
  // client.onConnectionLost = onConnectionLost;
  // client.onMessageArrived = onMessageArrived;
  //
  // useEffect(()=>{
  //   if(!client || !farmList || farmList.length===0){
  //     return
  //   }
  //   client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  // },[farmList]);
  //
  // function onConnect() {
  //   if (client.isConnected()) {
  //     farmList.forEach(farm=>{
  //       client.subscribe(`S2F/SA/V1/farm/${farm.id}/#`)
  //     });
  //     client.unsubscribe(`S2F/SA/V1/plot/#`);
  //   }
  // }
  //
  // function onConnectionLost(responseObject) {
  //   if (responseObject.errorCode !== 0) {
  //     client.connect({onSuccess:onConnect,mqttVersionExplicit:true,mqttVersion:3});
  //   }
  // }
  //
  // function onMessageArrived(message) {
  //   if(message){
  //     let data=JSON.parse(message.payloadString);
  //     dispatch(getFarmMqtt(data));
  //   }
  // }

  function moduleFilter(farm) {
    if (isEmpty(farmModules) || !farmModules[farm.id]) {
      return false
    }
    return farmModules[farm.id][MODULE.SMART_FARM];
  }

  function findFarmSource(farmId) {
    return sources.filter(source => source.farmId === farmId)[0]
  }


  function findFarmSensorDetails(farmId) {
    return summaryList.filter(summary => summary.farmId === farmId)[0]
  }

  function findFarmMotorDetails(farmId) {
    return actuatorSummaryList.filter(actuatorSummary => actuatorSummary.farmId === farmId)[0]
  }


  const filterFarmList = useSelector(state => {
    return state.farmList.filterFarmList
  });


  useEffect(() => {

    setFarms(filterFarmList)

  }, [filterFarmList])

  return (
    <div className="smart-farm">
      {window.innerWidth > 620 ?
        <div id="listView" className={'list-view ' + (toggle === TOGGLE.LIST ? 'full-list-view' : 'half-list-view')}>
          <div className={toggle === TOGGLE.LIST ? "" : showFadeEffect ? "empty-scroll-list bg-img-none" : ""}/>
          <div className={toggle === TOGGLE.LIST ? "half-list-container" : "half-list-container map-space"}>
            <Container className={toggle === TOGGLE.LIST ? "" : "m-t-8"}>
              <Row id={"m-rl-m-16-2"}>
                {farms.filter(farm => moduleFilter(farm)).map((item, index) => (
                  <Col xl={4} lg={6} key={"col" + index} className={'m-b-4'} id={'nav-item-SMART_FARM_ITEM'}>
                    <FarmItem farm={item} key={index} source={findFarmSource(item.id)} motor={findFarmMotorDetails(item.id)} sensor={findFarmSensorDetails(item.id)}/>
                  </Col>
                ))}
                {farms && farms.filter(farm => moduleFilter(farm)).length === 0 && (
                  <div className={"empty-results"}>
                    <FeatherIcon icon="info"/>
                    <div className={"empty-results-text"}>There are no farms available, please add</div>
                  </div>)
                }
                {hasPermission("SMART_FARM", "ADD") && (<div className="container">
                  <div className={"pull-right farm-add-btn"}>
                    <FeatherIcon className={"icon-add-btn"} icon="plus-circle" id={'nav-item-SMART_FARM_ADD'}
                                 onClick={() => setAddFarm(true)}/>
                  </div>
                </div>)}
              </Row>
            </Container>
          </div>
        </div> :
        <div id="listView"
             className={(toggle === TOGGLE.LIST ? 'list-view full-list-view ma-res-lis' : 'mo-list-view')}>
          {farms.filter(farm => moduleFilter(farm)).map((item, index) => (
            <FarmItem farm={item} farmKey={index} source={findFarmSource(item.id)} motor={findFarmMotorDetails(item.id)} sensor={findFarmSensorDetails(item.id)}/>
          ))}
          <div className={"mo-list-view-last"}/>
        </div>}


      {/*  Add Farm*/}
      {addFarm && <div className="sa-popup-bg">
        <div className="sa-popup">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header">
            <span
              className={'sa-model-heading'}>{t("title.ADD_FARM")}</span>
              <div className="sa-popup-close-icon"><FeatherIcon
                onClick={() => setAddFarm(false)}
                className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            {/*<div className="sa-modal-header">*/}
            {/*  Add Farm*/}
            {/*  <div className="sa-modal-close" onClick={() => setAddFarm(false)}>*/}
            {/*    <FeatherIcon*/}
            {/*        className={"icon-in-close"} icon={"x"}/>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <FarmAddMap onClose={() => setAddFarm(false)}/>

          </div>
        </div>
      </div>}
    </div>
  )
};


export default FarmList
