import React, {useEffect, useState} from "react";
import {hasPermission,} from "../../../utils/Authentication";
import FormHandler from "../../../shared/utils/FormHandler";
import {validateIrrigationMode} from "../../../utils/FormValidationRules";
import axios from "axios";
import {getAllFarmSources, getFarmSource, getFarmSourceIndex} from "../../../actions/farm";
import {useDispatch, useSelector} from "react-redux";
import FeatherIcon from "feather-icons-react";
import {toCapsFirst} from "../../../utils/Utils";
import {toast} from "react-toastify";
import {toggleLoader} from "../../../shared/actions/setting";
import * as _ from "underscore";
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../../shared/utils/utils";
import {getUserId, isSuper, isSuperLevelUsers} from "../../../shared/utils/SharedAuthentication";
import Switch from "react-switch";
import {useParams} from "react-router-dom";
import ValveMap from "./valve-map";
import Container from "react-bootstrap/Container";
import Popup from "../../popup";
import Actuator from "./actuator";
import PlotIrrigationDebuggerView from "../plot-irrigation/plot-irrigation-debugger-view";

const FarmIrrigationModeChange = (props) => {
  const [isModelVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModeUpdated, setIsModeUpdated] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [irrigationSwitch, setIrrigationSwitch] = useState(false);
  const [overlappingSwitch, setOverlappingSwitch] = useState(false);
  const {farmId} = useParams();
  const [timeoutValue, setTimeoutValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isApiCallProgress, setApiCallProgress] = useState(false);
  const [isDebuggerView, setIsDebuggerView] = useState(false);  

  const farmSource = useSelector(state => {
    return state.farmList.farmSource;
  });

  const farmSourceIndex = useSelector(state => {
    return state.farmList.farmSourceIndex;
  });

  const sources = useSelector(state => {
    return state.farmList.farmSourceAll;
  });
  const {
    values,
    errors,
    form,
    handleChange,
    handleSubmit,
  } = FormHandler(changeMode, validateIrrigationMode);

  const toggleDebuggerView = () => {
    setIsDebuggerView(!isDebuggerView);
  }

  const saveTimeout = () => {
    dispatch(toggleLoader(true));
    axios.put(
      process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + `/set-timeout?timeOut=` + timeoutValue,
    )
      .then(function (response) {
        toast.success("Timeout value saved successfully");
        setIsEditing(false);
      })
      .catch(function (error) {
        console.error("Error saving timeout value:", error);
      })
      .finally(() => {
        dispatch(toggleLoader(false))
      });
  };

  useEffect(() => {
    if (!isModeUpdated && sources.length > 0 || !props.valveCode && !isEmpty(farmSource) && !isModeUpdated || isApiCallProgress) {
      return;
    }
  
    setApiCallProgress(true);
  
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + `/source`)
      .then(function (response) {
        if (response.data.content.length > 0) {
          dispatch(getFarmSource(response.data.content[farmSourceIndex]));
        }
        dispatch(getAllFarmSources(response.data.content));
        setIsModeUpdated(false);
      })
      .catch(function (error) {
        if (error.response && error.response.status === 422) {
        }
      })
      .finally(() => {
        setApiCallProgress(false);
      });
  }, [farmSource, isModeUpdated, sources]);

  // useEffect(() => {
  //
  //   if (sources.length > 0 || !props.valveCode) {
  //     return;
  //   }
  //
  //   axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + `/source`)
  //     .then(function (response) {
  //     })
  //     .catch(function (error) {
  //       if (error.response && error.response.status === 422) {
  //       }
  //     })
  //     .finally(() => {
  //     });
  //
  // }, [sources]);


  function changeMode() {
    setIsLoading(true);
  }

  useEffect(() => {
    if (!props || !props.changeIrrigation || isEmpty(farmSource)) {
      setIsModeUpdated(false);
      return
    }
    setIsModeUpdated(true);

  }, [props.changeIrrigation]);


  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + props.farmId + '/source/' + farmSource.code + '/irrigation/mode', values)
      .then(function (response) {
        setIsModalVisible(false);
        setIsModeUpdated(true);
        props.onChange();
        toast.success("Irrigation mode changed successfully");
        setError(null);
        dispatch(toggleLoader(false));
      })
      .catch(function (error) {
        dispatch(toggleLoader(false));
        if (error.response && error.response.status === 422) {
          setError(error.response.data.message);
        } else {
          toast.error(t("errorMsg.SOMETHING_WENT_WRONG"));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoading]);


  useEffect(() => {
    if (sources.length === 0 || !props.valveCode) {
      return;
    }

    var index = _.findIndex(sources, {code: props.valveCode.charAt(0)});

    dispatch(getFarmSourceIndex(index));
    dispatch(getFarmSource(sources[index]));

  }, [props.valveCode, sources]);

  const onCloseModal = () => {
    values.mode = farmSource.irrigationMode;
    setIsModalVisible(false)
    setError(null);
  };

  const showModal = () => {
    if (farmSource) {
      values.mode = farmSource.irrigationMode;
    }
    setIsModalVisible(true);
  };

  const selectedFarm = useSelector(state => {
    return state.farmList.selectedFarm;
  });

  useEffect(() => {
    if (isEmpty(selectedFarm)) {
      return
    }
    setIrrigationSwitch(selectedFarm.autoIrrigationUsingWeatherEnabled)
    setOverlappingSwitch(selectedFarm.overlapping)
  }, [selectedFarm]);

  useEffect(() => {
    setTimeoutValue(selectedFarm.timeOut)
  }, [selectedFarm]);


  function irrigationSwitchChange(event) {

    setIrrigationSwitch(event)
    dispatch(toggleLoader(true))

    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/irrigation-using-weather/' + event)
      .then(function (response) {
        selectedFarm.autoIrrigationUsingWeatherEnabled = event
      })
      .catch(function (error) {
        console.log("response error", error)
        setIrrigationSwitch(!event)
      })
      .finally(() => {
        dispatch(toggleLoader(false))

      })
  }

  function toggleOverlapping(event) {

    setOverlappingSwitch(event)
    dispatch(toggleLoader(true))

    axios.put(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/toggle-overlapping?status=' + event)
      .then(function (response) {
        selectedFarm.overlapping = event
      })
      .catch(function (error) {
        console.log("response error", error)
        setOverlappingSwitch(!event)
      })
      .finally(() => {
        dispatch(toggleLoader(false))

      })

  }

  const handleUpdateClick = () => {
    setIsEditing(true);
  };

  function toggleDebugging(event) {

    dispatch(toggleLoader(true))
    axios.get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + '/assign-batch')
      .then(function (response) {
        toast.success("Enabled irrigation debugging");
      })
      .catch(function (error) {
        console.error("response error", error)
      })
      .finally(() => {
        dispatch(toggleLoader(false))
      })
  }


  return (
    <div hidden={isEmpty(farmSource)}>
      {!props.visibleWidget && (
        <div className="single-card-item-heading">{t("title.MODE")}</div>
      )}
      <div className="scheduling-mode-box">
        {farmSource && toCapsFirst(farmSource.irrigationMode)}
        {hasPermission("WRITE") &&
          <div hidden={props.hideButton} id={'btnChangeMode'} className="clickable-link" onClick={
            showModal}> {t("title.CHANGE")}
          </div>
        }
      </div>
      {isSuperLevelUsers() &&
        <Container className={'pb-3'}>
          <Popup title={t("title.VALVE_MAP")} heading={t("title.VALVE_MAP")}>
            <ValveMap farmId={farmId}/>
          </Popup>
          <Popup title={t("title.ACTUATOR")} heading={t("title.ACTUATOR")}>
            <Actuator/>
          </Popup>
          <div onClick={toggleDebuggerView} className="mb-3">
            {<button className="btn btn-sa-primary ml-5">Debugger</button>}
          </div>
        </Container>
      }
      {isDebuggerView && <PlotIrrigationDebuggerView mode={farmSource.irrigationMode} onClose={toggleDebuggerView}/>}
      <div className={"sa-popup-bg " + (!isModelVisible && 'hide')}>
        <div className="sa-popup">
          <form className={'sa-modal-box-style'} onSubmit={(e) => {
            handleSubmit();
            setIsLoading(true);
            e.preventDefault()
          }} noValidate>
            <div className="sa-popup-header">
              <span className={'sa-model-heading'}>{t("title.CHANGE_IRRIGATION_MODE")}</span>
              <div className="sa-popup-close-icon" onClick={onCloseModal}><FeatherIcon className={"sa-modal-close-icon"}
                                                                                       icon={"x"}/>
              </div>
            </div>
            <div className="sa-popup-content">
              <div className="col-md-6">
                <div className="m-b-16">
                  <label htmlFor="exampleFormControlSelect1">{t("title.IRRIGATION_MODE")}</label>
                  <select className="form-control" onChange={handleChange} value={values.mode}
                          id="mode" name="mode">
                    <option hidden value="">{t("title.SELECT_IRRIGATION_MODE")}</option>
                    <option value="MANUAL">{t("title.MANUAL")}</option>
                    <option value="SCHEDULE">{t("title.SCHEDULE")}</option>
                    <option value="DAILY">{t("title.DAILY")}</option>
                    <option value="AUTOMATION">{t("title.AUTOMATION")}</option>
                  </select>
                  {form.mode && form.mode.dirty && errors.mode && (
                    <p className="warning-input-msg">{errors.mode}</p>)}
                </div>
              </div>
            </div>
            {error && (<div className={"login-warning-msg"}>{error}</div>)}
            <div className="sa-popup-btn">
              <button type="button" className="sa-popup-secondary-btn-style sa-popup-cancel-btn-style"
                      onClick={onCloseModal}> {t("button.CANCEL")}
              </button>
              <button type="submit" className="sa-popup-secondary-btn-style">
                {t("button.CHANGE")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {props.plotId === "FARM" &&
        <div className={"vertical-switch-container d-flex justify-content-between flex-wrap pis-pull-right"}>
          <div className={"d-flex justify-content-between flex-wrap pis-pull-right"}>
            <div className={"d-flex"}>
              <div className={"irrigation-mode-heading"}>Skip irrigation , when it rains</div>
              <div className={"ml-3"}>
                <Switch
                  checked={irrigationSwitch}
                  onChange={irrigationSwitchChange}
                  onColor="#22C674"
                  offColor="#D3D3D3"
                  onHandleColor="#fff"
                  handleDiameter={20}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={25}
                  width={46}
                  className="react-switch ml-auto"
                  id="material-switch"
                />
              </div>
            </div>
          </div>
          {isSuper() &&
            <>
              <div className={"d-flex justify-content-between flex-wrap pis-pull-right"}>
                <div className={"d-flex"}>
                  <div className={"irrigation-mode-heading"}>Enable irrigation overlapping</div>
                  <div className={"ml-3"}>
                    <Switch
                      checked={overlappingSwitch}
                      onChange={toggleOverlapping}
                      onColor="#22C674"
                      offColor="#D3D3D3"
                      onHandleColor="#fff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={25}
                      width={46}
                      className="react-switch ml-auto"
                      id="material-switch"
                    />
                  </div>
                </div>
              </div>

              <div className={"d-flex justify-content-between flex-wrap pis-pull-right"}>
                <div className={"d-flex"}>
                  <div className={"irrigation-mode-heading"}>Enable irrigation debugging</div>
                  <div className={"ml-3"}>
                    <Switch
                      checked={false}
                      onChange={toggleDebugging}
                      onColor="#22C674"
                      offColor="#D3D3D3"
                      onHandleColor="#fff"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={25}
                      width={46}
                      className="react-switch ml-auto"
                      id="material-switch"
                    />
                  </div>
                </div>
              </div>

              <div className={"d-flex justify-content-between flex-wrap pis-pull-right"}>
                <div className={"d-flex align-center"}>
                  <div className={"irrigation-mode-heading align-center align-items-left mr-3"}>Timeout</div>
                  <div className={"irrigation-mode-input mt-1" + (isEditing ? ' active-border' : '')}>
                    <input
                      type="number"
                      value={timeoutValue}
                      onChange={(e) => setTimeoutValue(e.target.value)}
                      className={"form-control h-25"}
                      readOnly={!isEditing}
                    />
                  </div>
                </div>
              </div>
              <div className={"schedule-display float-r m-0 my-2"}>
                <button className="btn btn-sa-primary schedule-btn w-140p" onClick={saveTimeout}>
                  {t("button.SAVE")}
                </button>
                <button className="btn edit-btn-color btn-sa-secondary w-140p ml-3" onClick={handleUpdateClick}>
                  {t("button.EDIT")}
                </button>
              </div>
            </>
          }
        </div>
      }
    </div>
  );

};

export default FarmIrrigationModeChange;
