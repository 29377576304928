import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import {hasPermission} from "../../utils/Authentication";
import FormHandler from "../../shared/utils/FormHandler";
import {farmAlertvalidate} from "../../utils/FormValidationRules";
import * as _ from "underscore";
import {toggleLoader} from "../../shared/actions/setting";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {getUserId} from "../../shared/utils/SharedAuthentication";
import {getSelectedFarm} from "../../actions/farm";

function FarmAlertLevelSetting(props) {
  const {t, i18n} = useTranslation();

  const {farmId} = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [alertLevel, setAlertLevel] = useState([]);

  const {values, handleChange, handleSubmit, setValue, handleOnBlur} =
    FormHandler(farmAlertLevel, farmAlertvalidate);

  function farmAlertLevel() {
    setIsLoading(true);
  }

  function levelArray(x) {
    let level = [];
    for (var i = 1; i <= x; i++) {
      level.push(i);
    }
    return level;
  }


  // let maintain = levelArray(props.levels.maintainLevel);

  useEffect(() => {
    if (!props.levels) return 

    setAlertLevel(levelArray(props.levels.alertLevel))
    setValue({
      maxNoOfAlerts: props.levels.maxNoOfAlerts,
      offlineCount: props.levels.offlineCount,
      dataInterval: props.levels.dataInterval,
      1000: _.result(props.levels.userAlertLevel, 1000),
      2000: _.result(props.levels.userAlertLevel, 2000),
      3000: _.result(props.levels.userAlertLevel, 3000),
      4000: _.result(props.levels.userAlertLevel, 4000),
      5000: _.result(props.levels.userAlertLevel, 5000),
      6000: _.result(props.levels.userAlertLevel, 6000),
      10000: _.result(props.levels.userAlertLevel, 10000),
    });
  }, [props.levels]);

  useEffect(() => {
    if (!isLoading) {
      return;
    }
    dispatch(toggleLoader(true));
    axios
      .put(
        process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId,
        data
      )
      .then((res) => {
        toast.success("Alert Setting Update successfully");
        dispatch(getSelectedFarm(res.data.content));
        setIsLoading(false);
      })
      .finally(() => {
        dispatch(toggleLoader(false));
      });
  }, [isLoading]);

  const users = {
    userAlertLevel: _.pick(
      values,
      "1000",
      "2000",
      "3000",
      "4000",
      "5000",
      "6000",
      "10000"
    ),
  };
  const advanced = _.omit(
    values,
    "1000",
    "2000",
    "3000",
    "4000",
    "5000",
    "6000",
    "10000"
  );
  const data = _.extend(users, advanced);

  return (
    <form onSubmit={handleSubmit}>
      <div className={"alert-box"}>
        <div className="form-group row p-3 mb-0">
          <label className="col-sm-6 col-form-label">
            {t("title.FARM_USER")}
          </label>
          <div className="col-sm-6">
            <div className="form-group m-b-16 m-0">
              <select
                onBlur={handleOnBlur}
                onChange={handleChange}
                value={values["1000"] || ""}
                className={`form-control `}
                name="1000"
              >
                <option value={""} hidden>
                  {t("title.SELECT_ALERT_LEVEL")}
                </option>
                <option value={"0"}>{t("title.NONE")}</option>
                {alertLevel.map((level, index) => (
                  <option value={level} key={index}>
                    {t("title.ALERT_LEVEL") + level}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <hr className={"m-0"}/>

        <div className="form-group row p-3 mb-0">
          <label className="col-sm-6 col-form-label">
            {" "}
            {t("title.AGRO_INSPECTOR")}
          </label>
          <div className="col-sm-6">
            <div className="form-group m-b-16 m-0">
              <select
                onBlur={handleOnBlur}
                onChange={handleChange}
                value={values["2000"] || ""}
                className={`form-control `}
                name="2000"
              >
                <option value={""} hidden>
                  {t("title.SELECT_ALERT_LEVEL")}
                </option>
                <option value={"0"}>{t("title.NONE")}</option>
                {alertLevel.map((level, index) => (
                  <option value={level} key={index}>
                    {t("title.ALERT_LEVEL") + level}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/*{maintain.map((level, index) => (*/}
        {/*  <div key={index}>*/}
        {/*    <hr className={"m-0"}/>*/}
        {/*    <div className="form-group row p-3 mb-0">*/}
        {/*      <label className="col-sm-6 col-form-label">{"Maintenance Level " + level}</label>*/}
        {/*      <div className="col-sm-6">*/}
        {/*        <div className="form-group m-b-16 m-0">*/}
        {/*          <select onBlur={handleOnBlur} onChange={handleChange}*/}
        {/*                  value={values[((index + 2) * 1000)] || ''}*/}
        {/*                  className={`form-control`}*/}
        {/*                  name={((index + 2) * 1000)}>*/}
        {/*            <option value={""} hidden>Select Alert Level</option>*/}
        {/*            <option value={'0'}>None</option>*/}
        {/*            {alertLevel.map((level, index) => (*/}
        {/*                <option value={level} key={index}>{"Alert Level " + level}</option>*/}
        {/*              )*/}
        {/*            )}*/}
        {/*          </select>*/}

        {/*        </div>*/}
        {/*      </div>*/}

        {/*    </div>*/}
        {/*  </div>))}*/}
        <hr className={"m-0"}/>
        <div className="form-group row p-3 mb-0">
          <label className="col-sm-6 col-form-label">
            {" "}
            {t("title.PLOT_USER")}
          </label>
          <div className="col-sm-6">
            <div className="form-group m-b-16 m-0">
              <select
                onBlur={handleOnBlur}
                onChange={handleChange}
                value={values["10000"] || ""}
                className={`form-control `}
                name="10000"
              >
                <option value={""} hidden>
                  {t("title.SELECT_ALERT_LEVEL")}
                </option>
                <option value={"0"}>{t("title.NONE")}</option>
                {alertLevel.map((level, index) => (
                  <option value={level} key={index}>
                    {t("title.ALERT_LEVEL") + level}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      {hasPermission("FARM_ADVANCED_SETTING") && (
        <div className={"alert-box mt-5"}>
          <div className="d-flex alert-level-box mb-0 bg-ededed-grey  border-bottom m-0">
            <h5 className="col-md-6 text-dark farm-alert-heading plot-alert-heading">
              {t("title.ADVANCED")} <span className={""}>{t("title.SETTING")}</span>
            </h5>
          </div>
          <div className="form-group row p-3 mb-0">
            <label className="col-sm-6 col-form-label">{t("title.DATA_INTERVAL")}</label>
            <div className="col-sm-6">
              <div className="form-group m-b-16 m-0">
                <input
                  onBlur={handleOnBlur}
                  onChange={handleChange}
                  value={values.dataInterval || ""}
                  type="text"
                  className={"form-control"}
                  name="dataInterval"
                  placeholder={t("placeHolder.ENTER_MINS")}
                />
              </div>
            </div>
          </div>
          <hr className={"m-0"}/>
          <div className="form-group row p-3 mb-0">
            <label className="col-sm-6 col-form-label">
              {t("title.OFFLINE_ALERT_AFTER")}:
            </label>
            <div className="col-sm-6">
              <div className="form-group m-b-16 m-0">
                <input
                  onBlur={handleOnBlur}
                  onChange={handleChange}
                  value={values.offlineCount || ""}
                  type="text"
                  className={`form-control `}
                  name="offlineCount"
                  placeholder={t("placeHolder.ENTER_LEVEL")}
                />
              </div>
            </div>
          </div>
          <hr className={"m-0"}/>
          {/*<div className="form-group row p-3 mb-0">*/}
          {/*    <label className="col-sm-6 col-form-label">Max Continues Alert</label>*/}
          {/*    <div className="col-sm-6">*/}
          {/*        <div className="form-group m-b-16 m-0">*/}
          {/*            <input onBlur={handleOnBlur} onChange={handleChange}*/}
          {/*                value={values.maxNoOfAlerts || ''} type="text"*/}
          {/*                className={`form-control ${errors.maxNoOfAlerts && "warning-input"}`}*/}
          {/*                name="maxNoOfAlerts"*/}
          {/*                placeholder="Enter Times" />*/}
          {/*            {errors.maxNoOfAlerts && (<p className="warning-input-msg ">{errors.maxNoOfAlerts}</p>)}*/}
          {/*        </div>*/}
          {/*    </div>*/}

          {/*</div>*/}
        </div>
      )}

      <div className="text-right">
        <button className="btn btn-sa-primary w-140p mt-5" type="submit">
          {t("button.SAVE")}
        </button>
      </div>
    </form>
  );
}

export default FarmAlertLevelSetting;
