import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import 'c3/c3.css';
import {DateRangePicker} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'
import * as dateFns from "date-fns";
import 'rsuite/lib/styles/index.less';
import {toast} from "react-toastify";
import {css} from "@emotion/core";
import {getUserId} from "../../../shared/utils/SharedAuthentication";
import Graph from "../plot/graph";
import {useTranslation} from "react-i18next";
import {dateFormat, toBrowserTimeZone} from "../../../shared/utils/utils";
import {BeatLoader} from "react-spinners";
import InputRange from 'react-input-range';
import {useParams} from "react-router-dom";

function PlotIrrigationDebuggerView(props) {
  const {farmId} = useParams();
  const {t, i18n} = useTranslation();
  const [sensorDataSet, setSensorDataSet] = useState([]);
  const [filteredSensorDataSet, setFilteredSensorDataSet] = useState([]);
  const [actuatorDataSet, setActuatorDataSet] = useState([]);
  const [filteredActuatorDataSet, setFilteredActuatorDataSet] = useState([]);
  const [dateRange, setDataRange] = useState(null);
  const [valveDetails, setValveDetails] = useState([]);
  const [sensorDetails, setSensorDetails] = useState([]);
  const [sensorVisibleToggleIndex, setSensorVisibleToggleIndex] = useState(0);
  const [actuatorVisibleToggleIndex, setActuatorVisibleToggleIndex] = useState(0);
  const [isSensorDataLoading, setIsSensorDataLoading] = useState(false);
  const [isActuatorDataLoading, setIsActuatorDataLoading] = useState(false);
  const [toggleStates, setToggleStates] = useState({});
  const [codeKitMap, setCodeKitMap] = useState({});
  const [currentrange, setCurrentRange] = useState({min: 0, max: 24});

  const handleToggleChange = (key) => {
    setToggleStates((prevStates) => ({
      ...prevStates,
      [key]: !prevStates[key]
    }));
  };

  const selectedPlot = useSelector((state) => {
    return state.plotList.selectedPlot;
  });

  const handleSensorToggleChange = (index) => {
    setSensorVisibleToggleIndex(sensorVisibleToggleIndex === index ? null : index);
  };

  const handleActuatorToggleChange = (index) => {
    setActuatorVisibleToggleIndex(actuatorVisibleToggleIndex === index ? null : index);
  };

  const addToSensorDataSet = (dataArray) => {
    setSensorDataSet(dataArray);
    setFilteredSensorDataSet(dataArray);
  };

  const addToActuatorDataSet = (dataArray) => {
    setActuatorDataSet(dataArray);
    setFilteredActuatorDataSet(dataArray);
  };

  const handleRangeChange = (value) => {
    setCurrentRange(value);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    padding-bottom: 210px;
    border-color: red;
    width: fit-content;
  `;

  const onCheck = (e) => {
    const dates = {};
    dates.fromDate = dateFormat(e[0]);
    dates.toDate = dateFormat(e[1]);
    setDataRange(dates);
  }

  const getSensorData = (sensorDetails) => {
    setIsSensorDataLoading(true);
    let dataArray = [];
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());

    sensorDetails = sensorDetails.filter(s => !["CT", "SS"].includes(s.code));
    const promises = sensorDetails.map((sensor, i) =>
      axios
        .get(process.env.REACT_APP_HOST + `/core/kit/` + sensor.kitId + `/graph-kit-history/` + sensor.number,
          {
            params: {
              from: fromDate,
              to: toDate
            }
          })
        .then((response) => {
          let data = response.data.content;
          let object0 = {};
          if (data.valueX.length != 0 && data.valueY.length != 0) {
            object0 = {
              valueX: data.valueX,
              valueY: data.valueY,
              displayName: sensor.displayName + " [" + sensor.kitId + "]",
            };
            dataArray.push(object0);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            toast.error(error.response.data.message);
          }
        })
    );

    Promise.all(promises)
      .then(() => {
        addToSensorDataSet(dataArray);
        setIsSensorDataLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sensor data:", error);
        setIsSensorDataLoading(false);
      });
  };

  const getActuatorData = (valveDetails) => {
    setIsActuatorDataLoading(true);
    let dataArray = [];
    let fromDate = dateRange ? dateRange.fromDate : dateFormat(new Date());
    let toDate = dateRange ? dateRange.toDate : dateFormat(new Date());
    const promises = valveDetails.map((valve, i) =>
      axios
        .get(process.env.REACT_APP_HOST + `/core/kit/` + valve.kitId + `/graph-kit-action-history/` + valve.number,
          {
            params: {
              from: fromDate,
              to: toDate
            }
          }
        )
        .then((response) => {
          let data = response.data.content;
          let object0 = {};
          if (data.valueX.length !== 0 && data.valueY.length !== 0) {
            object0 = {
              valueX: data.valueX,
              valueY: data.valueY,
              displayName: valve.displayName + " [" + valve.code + "][" + valve.kitId + "][" + valve.number + "]",
            };
            dataArray.push(object0);
          }

        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            toast.error(error.response.data.message);
          }
        })
    );

    Promise.all(promises)
      .then(() => {
        addToActuatorDataSet(dataArray);
        setIsActuatorDataLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching actuator data:", error);
        setIsActuatorDataLoading(false);
      });
  };

  const getKitDetails = (kitId, isValve) => {
    setIsSensorDataLoading(true);
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/kit/` + kitId)
      .then((response) => {
        let sensorDetails = response.data.content.properties;
        setSensorDetails((prevDataSet) => [...prevDataSet, ...sensorDetails]);

        if (isValve) {
          sensorDetails = response.data.content.actions;
          sensorDetails = sensorDetails.map(e => {
            e.kitId = kitId
            e.code = codeKitMap[e.kitId+e.number]
            return e;
          });
          setValveDetails((prevDataSet) => [...prevDataSet, ...sensorDetails]);
        }
      })
      .catch((error) => {
      })
      .finally(() => {
        setIsSensorDataLoading(false);
      });
  };

  useEffect(() => {
    setIsActuatorDataLoading(true);
    axios
      .get(process.env.REACT_APP_HOST + `/user/` + getUserId() + `/farm/` + farmId + `/valve`)
      .then((response) => {
        let valves = response.data.content;

        for (const valve of valves) {
          let map = codeKitMap;
          map[valve.kitId + valve.actuatorNumber] = valve.code;
          setCodeKitMap(map)
        }

        const uniqueValves = Array.from(new Map(valves.map(item => [item.kitId, item])).values());

        for (const valve of uniqueValves) {
          getKitDetails(valve.kitId, valve.code);
        }

      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsActuatorDataLoading(false);
      });
  }, []);


  useEffect(() => {
    if (!selectedPlot || props.mode !== "Automation") {
      return;
    }
    getKitDetails(selectedPlot.kitId);
  }, [selectedPlot]);

  useEffect(() => {
    setSensorDataSet((prevDataSet) => []);
    setActuatorDataSet((prevDataSet) => []);

    getSensorData(sensorDetails);
    getActuatorData(valveDetails);
  }, [dateRange]);

  const loadFilteredData = () => {
    let filteredSensorData = sensorDataSet.map((sensorData) => {
      return {
        ...sensorData,
        valueX: sensorData.valueX.filter((date) => new Date(date).getHours() >= currentrange.min && new Date(date).getHours() < currentrange.max),
        valueY: sensorData.valueY.filter((value, index) => new Date(sensorData.valueX[index]).getHours() >= currentrange.min && new Date(sensorData.valueX[index]).getHours() < currentrange.max)
      };
    });

    let filteredActuatorData = actuatorDataSet.map((actuatorData) => {
      return {
        ...actuatorData,
        valueX: actuatorData.valueX.filter((date) => new Date(date).getHours() >= currentrange.min && new Date(date).getHours() < currentrange.max),
        valueY: actuatorData.valueY.filter((value, index) => new Date(actuatorData.valueX[index]).getHours() >= currentrange.min && new Date(actuatorData.valueX[index]).getHours() < currentrange.max)
      };
    });

    setFilteredSensorDataSet(filteredSensorData);
    setFilteredActuatorDataSet(filteredActuatorData);
  };

  const ToggleLayout = ({
                          title,
                          toggleIndex,
                          visibleToggleIndex,
                          onVisibleToggleIndexChange,
                          isToggle,
                          setIsToggle,
                          children
                        }) => {


    return (
      <div className="single-card m-rl-m-8">
        <div onClick={() => setIsToggle(!isToggle)}
             className={"row sa-cursor align-items-center px-3 " + (isToggle ? 'toggle-border-bottom' : '')}
             style={{height: '40px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <div className={"col-auto"}>
            <div className={'toggle-title ' + (isToggle ? 'toggle-text-green' : 'text-dark-gray')}>{title}</div>
          </div>
          <div className={"ml-auto col-auto"} style={{display: 'flex', alignItems: 'center'}}>
            <div className={'toggle-btn-container'}>
              <i className={"fa " + (isToggle ? "fa-angle-up" : "fa-angle-down")}
                 color={'#91949E'}/>
            </div>
          </div>
        </div>
        {isToggle && <div className={'p-a-16'}>
          {children}
        </div>}
      </div>
    );
  };


  return (
    <>
      <div className={"sa-popup-bg "}>
        <div className="sa-popup">
          <div className="sa-modal-box-style">
            <div className="sa-popup-header sticky-sa-popup-header">
              <span className={"sa-model-heading"}>Debugger</span>
              <div
                className="sa-popup-close-icon"
                onClick={() => props.onClose()}
              >
                <FeatherIcon className={"sa-modal-close-icon"} icon={"x"}/>
              </div>
            </div>
            <div className="">
              <div style={{margin: "0 16px", display: "flex", flexDirection: "row", alignItems: "center"}}>
                <DateRangePicker
                  style={{zIndex: 9999}}
                  disabledDate={(date) => date > new Date()}
                  disabled={false}
                  onChange={(e) => onCheck(e)}
                  oneTap={false}
                  onOk={(e) => onCheck(e)}
                  showWeekNumbers={true}
                  appearance={"default"}
                  placeholder={t("title.TODAY")}
                  ranges={[
                    {
                      label: "Today",
                      value: [new Date(), new Date()],
                    },
                    {
                      label: "Yesterday",
                      value: [
                        dateFns.addDays(new Date(), -1),
                        dateFns.addDays(new Date(), -1),
                      ],
                    },
                    {
                      label: "Last 7 days",
                      value: [dateFns.subDays(new Date(), 6), new Date()],
                    },
                    {
                      label: "Last 30 days",
                      value: [dateFns.subDays(new Date(), 30), new Date()],
                    },
                  ]}
                />
                {(actuatorDataSet.length > 0 && sensorDataSet.length > 0) && (<div className="time-range-filter">
                  <div className="">Filter by Time</div>
                  <InputRange
                    maxValue={24}
                    minValue={0}
                    step={1}
                    formatLabel={(value) => value.toFixed(1)}
                    onChange={(value) => handleRangeChange(value)}
                    value={currentrange}
                  />
                  <button className="sa-popup-secondary-btn-style"
                          style={{fontSize: '12px', width: '60px', height: '30px'}}
                          onClick={loadFilteredData}
                  >load
                  </button>
                </div>)}
              </div>
              {isActuatorDataLoading || isSensorDataLoading ? (
                <div className="sweet-loading">
                  <BeatLoader
                    css={override}
                    size={18}
                    color={"#11bc4d"}
                    loading={true}
                  />
                </div>
              ) : (
                <div className="graph-box">
                  {sensorDataSet.length === 0 ? (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>
                        {t("emptyMsg.THERE_ARE_NO_SENSOR_DATA_FOUND")}
                      </div>
                    </div>
                  ) : (
                    filteredSensorDataSet.map((data, index) => (
                      <ToggleLayout
                        key={`${data.displayName}-${index}`}
                        title={data.displayName}
                        toggleIndex={index}
                        visibleToggleIndex={sensorVisibleToggleIndex}
                        onVisibleToggleIndexChange={handleSensorToggleChange}
                        isToggle={toggleStates[`${data.displayName}-${index}`] || false}
                        setIsToggle={() => handleToggleChange(`${data.displayName}-${index}`)}
                      >
                        <Graph
                          Xvalues={data.valueX.map(toBrowserTimeZone)}
                          Yvalues={data.valueY}
                          displayName={data.displayName}
                          subchart={false}
                          height={200}
                        />
                      </ToggleLayout>
                    ))
                  )}

                  {actuatorDataSet.length === 0 ? (
                    <div className={"empty-results"}>
                      <FeatherIcon icon="info"/>
                      <div className={"empty-results-text"}>
                        {t("emptyMsg.THERE_ARE_NO_ACTUATOR_DATA_FOUND")}
                      </div>
                    </div>
                  ) : (
                    filteredActuatorDataSet.map((data, index) => {
                      return (
                        <ToggleLayout
                          key={`${data.displayName}-${index}`}
                          title={data.displayName}
                          toggleIndex={index}
                          visibleToggleIndex={actuatorVisibleToggleIndex}
                          onVisibleToggleIndexChange={
                            handleActuatorToggleChange
                          }
                          isToggle={toggleStates[`${data.displayName}-${index}`] || false}
                          setIsToggle={() => handleToggleChange(`${data.displayName}-${index}`)}
                        >
                          <Graph
                            Xvalues={data.valueX.map(toBrowserTimeZone)}
                            Yvalues={data.valueY}
                            displayName={data.displayName}
                            subchart={false}
                            height={200}
                          />
                        </ToggleLayout>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlotIrrigationDebuggerView;
